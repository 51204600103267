<template>
  <div>
    <v-row justify="center">
        <v-dialog
            :value="true"
            @click:outside="$emit('close')"
            persistent
            max-width="600px"
        >
            <v-card>
            <v-card-title>
                <span class="text-subtitle bold pa-4">Assign Users</span>
                <v-spacer></v-spacer>
                <v-icon
                    class="mr-2"
                    @click="$emit('close')"
                >
                    mdi-close
                </v-icon>
            </v-card-title>
            <v-divider class="ml-8" width="90%"></v-divider>
            <v-card-text>
                <br>
                <v-form>
                    <v-text-field
                        v-model="search"
                        class="search-input mx-5"
                        label="Search database"
                        prepend-inner-icon="mdi-magnify"
                        clearable
                        outlined
                        dense
                    ></v-text-field>
                </v-form>        
                <v-container>
                    <v-col>
                        <v-row class="px-2">
                            <v-col
                                cols="12"
                            >
                                <v-data-table
                                    v-model="usersTable"
                                    v-if="!loading"
                                    :headers="headers"
                                    :items="users"
                                    :item-key="id"
                                    :search="search"
                                >
                                <template 
                                    v-slot:[`item.viewer`]="{ item }"
                                >
                                    <v-sheet id="switch">
                                    <v-switch
                                        v-model="item.use"
                                        class="pl-6"
                                        color="tvf_color"
                                        value="viewer"
                                        :disabled='item.manager === "manager"'
                                    ></v-switch>
                                    </v-sheet>
                                </template>
                                <template 
                                    v-slot:[`item.manager`]="{ item }"
                                >
                                    <v-sheet id="switch">
                                    <v-switch
                                        v-model="item.use"
                                        class="pl-6"
                                        color="tvf_color"
                                        value="manager"
                                        :disabled='item.viewer === "viewer"'
                                    ></v-switch>
                                    </v-sheet>
                                </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-col>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        type="submit"
                        class="addButton pa-6 mb-6 ml-6 white--text"
                        color="#BDBDBD"
                        @click="$emit('close')"
                    >
                    <v-icon
                        dark
                        left
                    >
                        mdi-arrow-left
                    </v-icon>
                    Back
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        type="submit"
                        class="addButton pa-6 mb-6 mr-6 white--text"
                        color="tvf_color"
                        @click="saveUser"
                    >
                    Save
                    </v-btn>
                </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
import ApiService from "@/services/api.service";

export default {
    name: "addUser",

    components: {
    },

    data() {
        return {
            search: "",
            loading: false,
            isFormValid: false,
            viewer: false,
            manager: false,
            users: this.$store.state.users,
            headers: [
            {
                text: "NAME",
                align: "start",
                value: "full_name",
                sorteable: false,          
            },
            {
                text: "VIEWER",
                align: "center",
                value: "viewer",
            },
            { 
                text: "MANAGER", 
                value: "manager", 
                align: "center",
            }],
        }
    },

    mounted() {
        this.$store.commit('getUsers')
    },
}
</script>

<style scoped>
.text-subtitle {
  font-size: 1.25rem !important;
}
</style>

