<template>
  <v-row justify="center">
    <v-dialog
      :value="true"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="tvf_color"
        >
          <v-btn
            icon
            dark
            @click="$emit('close')"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>New Platform</v-toolbar-title>
        </v-toolbar>
        <v-tabs
          fixed-tabs
          v-model="tabs"          
        >
          <v-tabs-slider color="amber darken-3"></v-tabs-slider>
          <v-tab
            v-for="(item, index) in items"
            :class="{active: currentTab === index}"
            @click="currentTab = index"
            :key="item"
          >
            {{ item }}
          </v-tab>
          <v-tabs-items v-model="tabs" class="tabs-items">
            <div
              v-show="currentTab === 0"
              class="pt-10"
            >
              <v-form 
                @submit.prevent="addCompany"
                v-model="isFormValid"          
              >
                <v-card-text>
                  <v-container>
                    <v-col> 
                      <v-row class="px-8">
                        <v-col
                          cols="12"
                          sm="12"
                          md="12"
                          xs="12"
                        >
                          <h4 class="pb-4">PLATFORM</h4>
                          <label>Name</label>
                          <v-text-field
                            v-model="name"
                            :rules="nameRules"
                            class="body-2"
                            background-color="#F8F9FB"
                            dense
                            solo
                            flat
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="6"
                          xs="6"
                        >
                          <label>Cost x1000</label>
                          <v-text-field
                            v-model="cost"
                            :rules="costRules"
                            type="number"
                            step="0.01"
                            class="body-2"
                            background-color="#F8F9FB"
                            dense
                            solo
                            flat
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="6"
                          xs="6"
                        >
                          <label>Start Date</label>
                          <v-menu
                            v-model="start_date"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="date"
                                append-outer-icon="mdi-calendar"
                                class="body-2"
                                background-color="#F8F9FB"
                                dense
                                solo
                                flat
                                v-bind="attrs"
                                v-on="on"
                                :rules="dateRules"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="date"
                              color="tvf_color"
                              @input="start_date = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="12"
                          md="12"
                          xs="12"
                        >
                          <label>Fiscal name</label>
                          <v-text-field
                            v-model="fiscal_name"
                            :rules="fiscalNameRules"
                            class="body-2"
                            background-color="#F8F9FB"
                            dense
                            solo
                            flat
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="12"
                          md="12"
                          xs="12"
                        >
                          <label>Address</label>
                          <v-text-field
                            v-model="address"
                            :rules="addressRules"
                            class="body-2"
                            background-color="#F8F9FB"
                            dense
                            solo
                            flat
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="12"
                          md="12"
                          xs="12"
                        >
                          <label>Email</label>
                          <v-text-field
                            v-model="email"
                            :rules="emailRules"
                            type="email"
                            class="body-2"
                            background-color="#F8F9FB"
                            dense
                            solo
                            flat
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="6"
                          xs="6"
                        >
                          <label>Cif</label>
                          <v-text-field
                            v-model="cif"
                            :rules="cifRules"
                            class="body-2"
                            background-color="#F8F9FB"
                            dense
                            solo
                            flat
                          ></v-text-field>
                        </v-col>                            
                        <v-col
                          cols="12"
                          sm="6"
                          md="6"
                          xs="6"
                        >
                          <label right>Phone</label>
                          <v-text-field
                            v-model="phone"
                            :rules="phoneRules"
                            prefix="+"
                            type="number"
                            class="body-2"
                            background-color="#F8F9FB"
                            dense
                            solo
                            flat
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>                
                  </v-container>
                </v-card-text>
                <v-card-actions>                      
                  <v-spacer></v-spacer>
                  <v-btn
                    class="addButton pa-6 mb-6 mr-12 white--text"
                    color="tvf_color"
                    :disabled='!isFormValid'
                    @click="nextTab"
                  >
                    Next
                  </v-btn>
                </v-card-actions>
              </v-form>
            </div>       
            <div 
              v-show="currentTab === 1"
              class="pt-10"
            >
              <v-form 
                v-model="isFormValid"                  
              >
                <v-card-text>
                  <v-container>
                    <v-col>
                      <v-row class="flex-row-reverse justify-space-between pr-2 pt-4">                            
                        <v-btn
                          class="tvf_color--text mt-4"
                          color="#ffdde7"
                          depressed
                          small
                          center
                          @click="addFields()"
                        >
                          Add Fields
                        </v-btn>
                        <h4 class="py-4 pl-8">FIELDS</h4>
                      </v-row>
                      <div v-for="(input, index) in fields" :key="`fieldInput-${index}`">
                        <v-row class="pl-6">
                          <v-col
                          cols="12"
                          sm="5"
                          md="5"
                          >
                          <label>Field Name</label>
                          <v-text-field
                              v-model="input.field_name"
                              class="body-2"
                              background-color="#F8F9FB"
                              dense
                              solo
                              flat
                          ></v-text-field>
                          </v-col>
                          <v-col
                          cols="12"
                          sm="5"
                          md="5"
                          >
                          <label>Display Name</label>
                          <v-text-field
                            v-model="input.display_name"
                            required
                            class="body-2"
                            background-color="#F8F9FB"
                            dense
                            solo
                            flat
                          ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="1"
                            md="1"
                          >
                            <v-spacer class="pa-3"></v-spacer>
                            <v-btn
                                type="submit"
                                elevation="1"
                                icon
                                tile
                                class="addButton ml-2 white--text rounded"
                                color="tvf_color"
                                @click="removeField(index, fields)"
                            >
                              <v-icon>
                                mdi-delete-empty
                              </v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </div>
                    </v-col>                
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    class="addButton pa-6 mb-8 ml-8 white--text"
                    color="#BDBDBD"
                    @click="backTab"
                  >
                    <v-icon
                      dark
                      left
                    >
                      mdi-arrow-left
                    </v-icon>
                    Back
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="addButton pa-6 mb-6 mr-12 white--text"
                    color="tvf_color"
                    :disabled='!isFormValid'
                    @click="nextTab"
                  >
                    Next
                  </v-btn>
                </v-card-actions>
              </v-form>
            </div>
            <div 
              v-show="currentTab === 2"
              class="pt-10"
            >
              <v-form 
                v-model="isFormValid"                  
              >
                <v-card-text>
                  <v-container>
                    <v-col>
                      <v-row class="flex-row-reverse justify-space-between pr-2 pt-4">                            
                        <v-btn
                          class="tvf_color--text mt-4"
                          color="#ffdde7"
                          depressed
                          small
                          center
                          @click="addCustoms(customs)"

                        >
                          Add Customs
                        </v-btn>
                        <h4 class="py-4 pl-8">CUSTOMS</h4>
                      </v-row>
                      <div v-for="(input, index) in customs" :key="`customInput-${index}`">
                        <v-row class="pl-6">
                          <v-col
                            cols="12"
                            sm="3"
                            md="3"
                          >
                            <label>Custom Name</label>
                            <v-text-field
                              v-model="input.custom_name"
                              class="body-2"
                              background-color="#F8F9FB"
                              dense
                              solo
                              flat
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="4"
                            md="4"
                          >
                            <label>Custom Prefill</label>
                            <v-text-field
                              v-model="input.custom_prefill"
                              class="body-2"
                              background-color="#F8F9FB"
                              dense
                              solo
                              flat
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="3"
                            md="3"
                          >
                            <label>TVF Prefill</label>
                            <v-select
                              v-model="input.prefill"
                              :items="tvf_prefills"
                              item-value="id"
                              item-text="name"
                              required
                              class="body-2"
                              background-color="#F8F9FB"
                              dense
                              solo
                              flat
                            >
                              <template v-slot:prepend-item>
                                <v-list-item class="pointer" @click="newPrefills = true">
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      Add New TVF Prefill
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-divider class="mt-2"></v-divider>
                              </template>
                            </v-select>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="1"
                            md="1"
                          >
                            <v-spacer class="pa-3"></v-spacer>
                            <v-btn
                              type="submit"
                              elevation="1"
                              icon
                              tile
                              class="addButton white--text rounded"
                              color="tvf_color"
                              @click="removeCustom(index, customs)"
                            >
                              <v-icon>
                                mdi-delete-empty
                              </v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </div>
                    </v-col>                
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    class="addButton pa-6 mb-8 ml-8 white--text"
                    color="#BDBDBD"
                    @click="backTab"
                  >
                    <v-icon
                      dark
                      left
                    >
                      mdi-arrow-left
                    </v-icon>
                    Back
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="addButton pa-6 mb-6 mr-12 white--text"
                    color="tvf_color"
                    :disabled='!isFormValid'
                    @click="addPlatform"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-form>
            </div>
          </v-tabs-items>
        </v-tabs>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="newPrefills"
      persistent
      @click:outside="$emit('close')"
      max-width="550px"
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle bold pa-4">New Prefill</span>
          <v-spacer></v-spacer>
          <v-icon
            class="mr-2"
            @click="$emit('close')"
          >
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider
          class="ml-8"
          width="90%"
        ></v-divider>

        <v-form @submit.prevent="addPrefill">
          <v-card-text>
            <v-container>
              <v-col>
                <v-row class="px-2">
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <label>Name</label>
                    <v-text-field
                      v-model="prefill_name"
                      class="body-2"
                      background-color="#F8F9FB"
                      dense
                      solo
                      flat
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <label class="ml-2">Prefill</label>
                    <v-text-field
                      v-model="prefill"
                      class="body-2"
                      background-color="#F8F9FB"
                      dense
                      solo
                      flat
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              type="submit"
              class="addButton pa-6 mb-6 mr-6 white--text"
              color="tvf_color"
              :disabled='!isComplete'
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>


<script>
import ApiService from "@/services/api.service";

export default {
  data () {
    return {
      name: '',
      cost: '',
      start_date: false,
      date: '',
      fiscal_name: '',
      address: '',
      cif: '',
      email: '',
      phone: '',
      customs: '',
      display_name: '',
      platformID: '',
      fields: [{
        field_name: '',
        display_name: ''
      }],
      customs: [{
        custom_name: '',
        custom_prefill: '',
        prefill: ''
      }],
      tvf_prefills: [],
      prefill_name: '',
      prefill: '',
      contacts: [],
      contact: {
        name: "",
        type: "",
        email: "",
        language: "",
        skype: "",
        phone: "",
      },
      errors: [],
      currentTab: 0,
      tabs: null,
      isFormValid: false,
      newPrefills: false,
      menu2: false,
      items: ['Platform info', 'Fields', 'Custom'],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Invalid Email Address'
      ],
      phoneRules: [
        (v) => !!v || "Phone is required",
        (v) => /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(v) || 'Invalid Phone Number'
      ],
      nameRules: [(v) => !!v || "Name is required"],
      fiscalNameRules: [(v) => !!v || "Fiscal Name is required"],
      addressRules: [(v) => !!v || "Address is required"],
      cifRules: [(v) => !!v || "Cif is required"],
      costRules: [(v) => !!v || "Cost is required"],
      typeRules: [(v) => !!v || "Type is required"],
      skypeRules: [(v) => !!v || "Skype is required"],
      languageRules: [(v) => !!v || "Language is required"],
      dateRules: [(v) => !!v || "Start date is required"],
    }
  },

  computed: {
    isComplete() {
      return (
        this.prefill_name &&
        this.prefill
      );
    },

  },

  mounted() {
    this.getPrefills()
  },

  methods: {
    nextTab(){
      this.currentTab = this.currentTab +1
    },

    backTab(){
      this.currentTab = this.currentTab -1
    },

    addFields(){
      this.fields.push({})
    },

    addCustoms(){
      this.customs.push({})
    },

    removeField(index, fields) {
      this.fields.splice(index, 1)
    },

    removeCustom(index, customs) {
      this.customs.splice(index, 1)
    },

    async getPrefills() {
      this.loading = true; //the loading begin

      await ApiService.get("tvfprefills")
      .then((response) => {
        this.loading = false; //the loading stop when the response given from server
        const prefills = response.data.data;  
        this.tvf_prefills = prefills.sort(function (a, b) {
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          // a must be equal to b
          return 0;
        })
        this.tvf_prefills.push()
      })
      .catch((error) => {
        this.loading = false;
        console.log("Ups! Something is wrong");
      })
    },

    async addPlatform() {

      await ApiService.post("platforms", {
        name: this.name,
        fiscal_name: this.fiscal_name,
        address: this.address,
        cif: this.cif,
        email: this.email,
        phone: '+' + this.phone,
        fields: JSON.stringify(this.fields),
      })
      .then((response) => {
        this.platformID = response.data.data.id;
        this.$emit('close');
        this.$emit('updateList');
        this.$store.dispatch('setSnackbar', {
          color: 'success',
          text: 'Platform "' + this.name + '" has been created!',
          timeout: 3000
        })
      })
      .catch((error) => {
        if (typeof error.response.data === 'object') {
          Object.values(error.response.data.errors).flat().forEach( error => {
            this.$store.dispatch('setSnackbar', {
              color: 'tvf_secondary',
              text: error,
              timeout: 3000
            })       
          })
        }
      });

      ApiService.post("platformcosts", {
        platform_id: this.platformID,
        cost: this.cost,
        date_since: this.date,
      })
      .then((response) => {
        this.$emit('close');
        this.$emit('updateList');
        this.$store.dispatch('setSnackbar', {
          color: 'success',
          text: 'Cost added to "' + this.name + '"',
          timeout: 3000
        })
      })
      .catch((error) => {
        if (typeof error.response.data === 'object') {
          Object.values(error.response.data.errors).flat().forEach( error => {
            this.$store.dispatch('setSnackbar', {
              color: 'tvf_secondary',
              text: error,
              timeout: 3000
            })       
          })
        }
      });

      this.customs.forEach(element => {

        ApiService.post("customprefills", {
          platform_id: this.platformID,
          name: element.custom_name,
          prefill: element.custom_prefill,
          tvf_prefill_id: element.prefill,
        })
        .then((response) => {
          this.$store.dispatch('setSnackbar', {
            color: 'success',
            text: 'Custom prefills added to "' + this.name + '"',
            timeout: 3000
          })
        })
        .catch((error) => {
          if (typeof error.response.data === 'object') {
            Object.values(error.response.data.errors).flat().forEach( error => {
              this.$store.dispatch('setSnackbar', {
                color: 'tvf_secondary',
                text: error,
                timeout: 3000
              })       
            })
          }
        });
        this.$emit('close');
        this.$emit('updateList');
      });

    },

    async addPrefill(event) {
      await ApiService.post("tvfprefills", {
        name: this.prefill_name,
        prefill: this.prefill
      })
      .then((response) => {
        this.newPrefills = false
        event.target.reset()
        this.$store.dispatch('setSnackbar', {
          color: 'success',
          text: 'New prefill has been created!',
          timeout: 3000
        })
        this.getPrefills()
      })
      .catch((error) => {
        if (typeof error.response.data === 'object') {
          Object.values(error.response.data.errors).flat().forEach( error => {
            this.$store.dispatch('setSnackbar', {
              color: 'tvf_secondary',
              text: error,
              timeout: 3000
            })       
          })
        }
      });
    }
  }
}
</script>

<style scoped>

.theme--light.v-tabs-items {
  width: 60%;
  margin: 0 auto;
  margin-top: 20px;
}
.pointer:hover {
  cursor: pointer;
}
.theme--light.v-tabs > .v-tabs-bar {
  background-color: none;
}
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
  color: #666666 !important;
}

.theme--light.v-tabs > .v-tabs-bar .v-tab--active {
  color: #F85182 !important;
}
.tabs-items label, h4 {
  color: #666666;
}

@media (min-width: 1600px) {
  .theme--light.v-tabs-items {
    width: 45%;
  }
}

</style>