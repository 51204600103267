<template>
  <div>
    <v-card class="pb-10">
      <v-container fluid class="align-start px-0 d-flex flex-row">
        <LoadingSpinner v-if="loading" />        
        <!-- LEFT MENU WITH FILTERS, DATATABLE WITH DATABASES AND SEARCH INPUT ----------------------------------------
        ---------------------------------------------------------------------------------
        ---------------------------------------------------------------------------------
        ----------------------------------------------------------------------------------
        ----------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------->
        <v-navigation-drawer
          class="ml-1"
          permanent
        >
          <v-list>
            <v-list-item class="justify-space-between pb-4">
                <v-switch
                  class="pl-4"
                  v-model="active"
                  inset
                  color="tvf_color"
                  label="ACTIVES"
                  @click="actives()"
                  :disabled="inactive === true"
                ></v-switch>
                <v-switch
                  class="pl-8"
                  v-model="inactive"
                  inset
                  color="tvf_color"
                  label="INACTIVES"
                  @click="inactives()"
                  :disabled="active === true"
                ></v-switch>
            </v-list-item>

            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              class="body-2 px-5"
              background-color="#F8F9FB"
              dense
              solo
              flat
              small
            ></v-text-field>         
            <v-list-item class="px-4 selects">
              <v-select 
                v-model="country_filter"
                class="select-input caption px-1"
                :items="countries"
                label="Country"
                item-value="id"
                item-text="name"
                background-color="#F8F9FB"
                dense
                flat
                outlined
                @change="filterDB()"
              >
                <option :value="''" disabled selected>Country</option>
              </v-select>
              <v-select 
                v-model="platform_filter"
                class="select-input caption"
                label="Platform"
                :items="platforms"
                item-value="id"
                item-text="name"
                background-color="#F8F9FB"
                dense
                flat
                outlined
                @change="filterDB()"
              >
              </v-select>
              <v-select 
                v-model="account_filter"
                class="select-input caption px-1"
                :items="users"
                label="Account"
                item-value="id"
                item-text="first_name"
                background-color="#F8F9FB"
                dense
                flat
                outlined
                @change="filterDB()"
              >
              </v-select>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-data-table
            :headers="headers"
            :items="sortedArray"
            :search="search"
            class="database-list caption pl-4 py-6"
            height="480px"
            :items-per-page="-1"
            hide-default-header
            hide-default-footer
            :loading="loading"
            :item-class="itemRowColor"
          >
            <v-progress-linear slot="progress" color="tvf_color" indeterminate></v-progress-linear>
            <template v-slot:[`item.action`]="{ item }">
              <v-btn 
                class="ml-1"
                icon
                x-small
              >
                <v-icon
                  v-model="item.action"
                  color="black"
                  @click="clickItem(item)"
                >
                  mdi-eye
                </v-icon>
              </v-btn>  
              <v-btn                
                class="ml-1"
                icon
                x-small
                :disabled="item.deleted_at !== null"
              >
                <v-icon
                  v-model="item.action"
                  color="black"
                  @click="updateDatabase(item)"
                >
                  mdi-pencil
                </v-icon>
              </v-btn>            
            </template>
          </v-data-table>
        </v-navigation-drawer>   
        <!-- FINAL LEFT MENU---------------------------------------------------------------------->
        <!-- BUTTON NEW AND SAVE DATABASE-------------------------------------------------------------------------------
        ---------------------------------------------------------------------------------
        ----------------------------------------------------------------------------------
        ----------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------->     
        <v-card flat class="grow" width="70%">
          <v-row class="d-flex flex-row-reverse px-3 py-3">      
            <v-btn 
              v-if="!newDatabase"
              class="addButton mx-10 mt-10 white--text caption"
              color="tvf_color"  
              @click="addDatabase()"
            >
              Add new
            </v-btn>                        
          </v-row>
          <!-- FINAL BUTTON---------------------------------------------------------------------->
          <!-- FORM ADD NEW DATABASE-->
          <new-database 
            v-show="newDatabase"
            @close="newDatabase = false"
          ></new-database>
          <show-info
            v-show="showItem"
            @close="showItem = false"
            :itemInfo="itemInfo"
          ></show-info>
          <edit-database
            v-show="editDatabase"
            @close="editDatabase = false"            
            :editItem="editItem"
            @showItem="showItem = false"
          ></edit-database>
        </v-card>        
      </v-container>
    </v-card>      
  </div>      
</template>

<script>
import ApiService from "@/services/api.service";
import LoadingSpinner from "../components/core/LoadingSpinner";
import NewDatabase from "../components/database/NewDatabase";
import ShowInfo from "../components/database/ShowInfo.vue";
import EditDatabase from "../components/database/EditDatabase.vue";
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: 'Databases',
  components: {
    LoadingSpinner, 
    NewDatabase,
    ShowInfo,
    EditDatabase
  },

  data() {
    return {
      search: '',
      loading: false,
      active: false,
      inactive: false,
      status: '',
      newDatabase: false,
      editDatabase: false,
      showItem: false,
      select_country: '',
      account_filter: '',
      country_filter: '',
      platform_filter: '',
      filters:{},
      country: {
        name: '',
        acronym: ''
      },
      platform: {
        name: '',
      },
      user: {
        first_name: '',
        full_name: '',
        last_name:''
      },
      itemInfo: {},      
      editItem: {},
      countries: [],
      platforms: [],
      users: [],
      accounts: [],
      headers: [
        {
          align: "start",
          value: "id",
          sortable: true
        },
        {
          align: "start",
          value: "name",
          sortable: true,
        },
        {
          align: "center",
          value: "country.acronym",
          sortable: false,
        },
        {
          align: "center",
          value: "action",
          sortable: false,
        }
      ],      

    }
  },

  mounted() {
    this.$store.dispatch("getDatabases");
    this.getCountries()  
    this.getPlatforms()
    this.getUsers()
  },

  computed: {
    sortedArray: function() {
      function orderDate(a, b) {
          if (a.deleted_at !== null) return 1;
          if (a.deleted_at === null) {
            return -1;
          } 
          return 0;
      }
      return [...this.databases].sort(orderDate); // shallow clone + sort
    },

    ...mapState([
      "databases"
    ]),
  },

  methods: {
    ...mapActions([
      'getDatabases',
      'getActiveDatabases',
      'getInactiveDatabases',
      'filterDatabases'
    ]),

    ...mapMutations([
      'setFilter',
    ]),

    clickItem(item){            
      this.itemInfo = {
        id: item.id,
        name_title: item.name,
        name: item.name,
        platform: item.platform.name,
        platform_id: item.platform.id,
        country: item.country.name,
        country_id: item.country.id,
        company_id: 1,
        from_name: item.from_name,
        from_email: item.from_email,
        reply_name: item.reply_name,
        reply_email: item.reply_email,
        ddbb_name: item.name_bdd,
        managers: item.users,
        domains: item.domains.sort(function (a, b) {
          if (a.priority < b.priority) {
            return -1;
          }
          if (a.priority > b.priority) {
            return 1;
          }
          // a must be equal to b
          return 0;
        }),
        fields: JSON.parse(item.platform_fields),
        field_title: 'Platform fields',
        owners: item.owners,
        switch_status: item.switch_status,
        head_foot: item.headers_footers,
        deleted_at: item.deleted_at,
        status: item.status
      }
      this.showItem = true;
      this.editDatabase = false;
      this.newDatabase = false;
    },

    updateDatabase(item) {
      this.itemInfo = {
        id: item.id,
        name_title: item.name,
        name: item.name,
        platform: item.platform.name,
        platform_id: item.platform.id,
        country: item.country.name,
        country_id: item.country.id,
        company_id: 1,
        ddbb_name: item.name_bdd,
        from_name: item.from_name,
        from_email: item.from_email,
        reply_name: item.reply_name,
        reply_email: item.reply_email,
        managers: item.users,
        domains: item.domains.sort(function (a, b) {
          if (a.priority < b.priority) {
            return -1;
          }
          if (a.priority > b.priority) {
            return 1;
          }
          // a must be equal to b
          return 0;
        }),
        fields: JSON.parse(item.platform_fields),
        field_title: 'Platform fields',
        owners: item.owners,
        switch_status: item.switch_status,
        head_foot: item.headers_footers,
        deleted_at: item.deleted_at
      }
      this.editItem = {
        id: item.id,
        name_title: item.name,
        name: item.name,
        platform: item.platform.name,
        platform_id: item.platform.id,
        country: item.country.name,
        country_id: item.country.id,
        company_id: 1,
        ddbb_name: item.name_bdd,
        from_name: item.from_name,
        from_email: item.from_email,
        reply_name: item.reply_name,
        reply_email: item.reply_email,
        managers: item.users,
        domains: item.domains.sort(function (a, b) {
          if (a.priority < b.priority) {
            return -1;
          }
          if (a.priority > b.priority) {
            return 1;
          }
          // a must be equal to b
          return 0;
        }),
        fields: JSON.parse(item.platform_fields),
        field_title: 'Platform fields',
        owners: item.owners,
        switch_status: item.switch_status,
        head_foot: item.headers_footers,
        deleted_at: item.deleted_at
      }
      this.showItem = false;
      this.newDatabase = false;
      this.editDatabase = true;
    },

    itemRowColor(item) {
      return item.deleted_at !== null ? 'disabled_item--text' : 'primary--text'
    },

    filterOnlyCapsText (value, search, item) {
      return value != null &&
        search != null &&
        typeof value === 'string' &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
    },

    onChange(platform) {
      this.platforms.forEach(element => {
        if(platform === element.id) {
          this.fields = JSON.parse(element.fields)
        }
      })
    },

    getUsers() {

      ApiService.get("users/list")
        .then((response) => {
          const users = response.data.data 
          this.users = users.sort(function (a, b) {
            if (a.first_name.toLowerCase() > b.first_name.toLowerCase()) {
              return 1;
            }
            if (a.first_name.toLowerCase() < b.first_name.toLowerCase()) {
              return -1;
            }
            // a must be equal to b
            return 0;
          });  
          this.users.unshift(this.user)   
        })
        .catch((error) => {
          console.log("Ups! Something is wrong");
        });
    },

    getPlatforms() {
      ApiService.get("platforms/list")
      .then((response) => {
        const platforms = response.data.data 
        this.platforms = platforms.sort(function (a, b) {
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });    
        this.platforms.unshift(this.platform)
      })
      .catch((error) => {
        console.log("Ups! Something is wrong");
      });
    },

    getCountries() {
      ApiService.get("countries/list")
      .then((response) => {
        const countries = response.data.data 
        this.countries = countries.sort(function (a, b) {
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });    
        this.countries.unshift(this.country)
      })
      .catch((error) => {
        console.log("Ups! Something is wrong");
      });
    }, 
    
    filterDB(){
      this.setFilter({ key: 'country_id', value: this.country_filter });
      this.setFilter({ key: 'platform_id', value: this.platform_filter });
      this.setFilter({ key: 'user_id', value: this.account_filter });
      this.setFilter({ key: 'status', value: (this.active ? 'active' : this.inactive ? 'inactive' : '') });
      this.filterDatabases();
    },

    actives(){
      if(this.active) {
        this.getActiveDatabases();
      } else {
        this.getDatabases();
      }
    },

    inactives(){
      if(this.inactive) {
        this.getInactiveDatabases();
      } else {
        this.getDatabases();
      }
    },

    addDatabase() {
      this.showItem = false;
      this.newDatabase = true;
    }

  }
};
</script>

<style scoped>

.text-btn{
  font-family: Poppins, sans-serif;
  font-size: 14px;
  line-height: 24px !important;
  text-transform: capitalize !important;
}
span, label, .text{
  font-size: 0.8rem !important;
  font-weight: 400;
}

.v-navigation-drawer {
  width: 350px !important;
}

.text-subtitle {
  font-size: 1.25rem !important;
}

.select-input  {
  width: 60px;
}

</style>