<template>
    <div>
      <v-container class="screen">        
        <v-row>
          <v-col
            cols="12"
            md="4"
            sm="4"
          >
            <v-sheet
              class="card-table"
              rounded="lg"
            >
              <LoadingSpinner v-if="loading" />
              <h4 class="d-flex justify-center pb-6 pt-10">Currencies</h4>
              <v-data-table
                class="tagsList px-10 pb-6"
                v-if="!loading"
                :headers="currencyHeaders"
                :items="currencies"
                :search="search"
                :page.sync="page1"
                :items-per-page.sync="itemsPerPage"
                @page-count="pageCount1 = $event"
              >
              </v-data-table>
            </v-sheet>
            <div class="pt-4">
              <v-pagination
                v-model="page1"
                :length="pageCount1"
                color="tvf_color"
                :total-visible="5"
              ></v-pagination>
            </div>
          </v-col>     
          <v-col
            class="tag-card2"
            cols="12"
            md="7"
            sm="7"
          >
            <v-sheet
              class="card-table"
              rounded="lg"
            >
              <LoadingSpinner v-if="loading" />
              <h4 class="d-flex justify-center pb-6 pt-10">Currencies historical</h4>
              <v-data-table
                class="tagsList px-10 pb-6"
                v-if="!loading"
                :headers="historicalHeaders"
                :items="historical"
                :search="search"
                :page.sync="page1"
                :items-per-page.sync="itemsPerPage"
                @page-count="pageCount1 = $event"
              >
              </v-data-table>
            </v-sheet>
            <div class="pt-4">
              <v-pagination
                v-model="page1"
                :length="pageCount1"
                color="tvf_color"
                :total-visible="5"
              ></v-pagination>
            </div>
          </v-col>         
        </v-row>
      </v-container>
      <v-dialog
        v-model="confirmation"
        :value="true"
        persistent
        @click:outside="confirmation = false"
        max-width="400px"
      >
        <v-card>
          <v-container>
            <v-col>
              <v-row class="pa-4">
                <v-col
                  class="align-center"
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <h4>{{ name }}</h4>
                  <p class="body-2 mt-4">Are you sure you want to disable this currency?</p>
                </v-col>
              </v-row>
              <v-row class="px-4 pb-4">
                <v-col
                  class="align-center"
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-btn
                    class="addButton pa-6 mr-8 white--text"
                    block
                    color="tvf_color"
                    @click="disabledItem()"
                  >
                  Disable
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-container>
        </v-card>
      </v-dialog>
      <new-currency
        v-if="addCurrency"
        @close="closeCreateModal"
        @updateList="addCurrencies"
      />
    </div>
  </template>
  
  <script>
  import ApiService from "../services/api.service";
  import LoadingSpinner from "../components/core/LoadingSpinner";
  import NewCurrency from "../components/currency/NewCurrency";
  
  export default {
    props: ['search'],
    name: 'Tags',
    components: {
      LoadingSpinner,
      NewCurrency,
    },
    data() {
      return {
        page1: 1,
        page2: 1,
        pageCount1: 0,
        pageCount2: 0,
        itemsPerPage: 10,
        loading: false,
        editedCurrency: false,
        addCurrency: false,
        confirmation: false,
        editCurrency: {},
        currencies: [],
        errors: [],
        historical: [],
        name: '',
        itemId: '',
        currencyHeaders: [
          {
            text: "Name",
            align: "start",
            value: "name",
            sortable: true,          
          },
          {
            text: "Code",
            align: "start",
            value: "cod",
            sortable: true,          
          },
          {
            text: "Symbol",
            align: "start",
            value: "symbol",
            sortable: true,          
          },
        ],
        historicalHeaders: [
/*           {
            text: "Name",
            align: "start",
            value: "currency.name",
            sortable: true,          
          }, */
          {
            text: "Code",
            align: "start",
            value: "currency.cod",
            sortable: true,          
          },
          {
            text: "Symbol",
            align: "start",
            value: "currency.symbol",
            sortable: true,          
          },
          {
            text: "Value",
            align: "start",
            value: "value",
            sortable: true,          
          },
          {
            text: "Date",
            align: "start",
            value: "last_date",
            sortable: true,          
          },
        ],
      }
    },
  
    mounted() {
      this.addCurrencies()
      this.getHistorical()
    },
  
    methods: {
      openCreateCurrency() {
        this.addCurrency = true
      },
  
      openEditCurrency(item) {
        this.editCurrency = {
          id: item.id,
          name: item.name,
        },
        this.editedCurrency = true;
      },
  
      closeCreateModal() {
        this.errors = []
        this.addCurrency = false
        this.editedCurrency = false
      },

      openConfirmation(item){
        if(item.status === false) {
          this.confirmation = true
          this.name = item.name
          this.itemId = item.id
        }
        else {
          ApiService.patch("currencies/status/" + item.id)
          .then((response) => {
            this.addCurrencies();
            this.$store.dispatch('setSnackbar', {
              color: 'success',
              text: item.name + ' is active!',
              timeout: 3000
            })
          })
        }
      },

      disabledItem() {
        ApiService.patch("currencies/status/" + this.itemId)
          .then((response) => {
            this.confirmation = false
            this.addCurrencies();
            this.$store.dispatch('setSnackbar', {
              color: 'success',
              text: this.name + ' has been disabled',
              timeout: 3000
          })
          .catch((error) => {
            this.$store.dispatch("setSnackbar", {
              color: "tvf_secondary",
              text: error.message,
              timeout: 3000,
            });
          });
        })
      },
  
      addCurrencies() {
        this.loading = true //the loading begin
  
        ApiService.get("currencies/list")
          .then((response) => {
            this.loading = false //the loading stop when the response given from server
            const currencies = response.data.data;  
            this.currencies = currencies.sort(function (a, b) {
              if (a.name > b.name) {
                return 1;
              }
              if (a.name < b.name) {
                return -1;
              }
              // a must be equal to b
              return 0;
            });   
          })
          .catch((error) => {
            this.loading = false
            this.$store.dispatch("setSnackbar", {
              color: "tvf_secondary",
              text: error.message,
              timeout: 3000,
            });
          });
      },

      getHistorical(){
        ApiService.get("historicalCurrencyRates/list")
        .then((response) => {          
          this.loading = false //the loading stop when the response given from server
          const historical = response.data.data;  
          this.historical = historical.sort(function (a, b) {
            if (a.last_date > b.last_date) {
              return -1;
            }
            if (a.last_date < b.last_date) {
              return 1;
            }
            // a must be equal to b
            return 0;
          });        
        })
        .catch((error) => {
          this.loading = false
          this.$store.dispatch("setSnackbar", {
            color: "tvf_secondary",
            text: error.message,
            timeout: 3000,
          });
          });
      }
    }
  
  };
  </script>
  
  <style scoped>
  p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: bold;
  }
  .options {
    font-family: Poppins;
    font-size: 14px;
    font-weight: bold;
  }
  
  .tag-card2{
    margin: auto;
  }

  .screen{
    margin-top: 25px !important;
  }
  
  .v-card__title {
    flex-wrap: nowrap;
  }
  
  @media (max-width: 600px) {
    .v-application .pl-2{
      padding-left: 0 !important;
    }
  
    .tagsList {
      padding-bottom: 20px;
    }
  }
  
  @media (max-width: 1300px) {
    .container{
      max-width: 1000px !important;
    }
    .card-table {
      min-height: 700px;
    }
  
    .v-data-footer__select {
      margin-right: 0px !important;
    }
  }
  
  </style>