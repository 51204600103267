<template>
  <div>
    <v-card>
      <LoadingSpinner v-if="loading" />    
      <v-btn 
          class="addButton ml-8 my-10 white--text"
          color="tvf_color"  
          @click="newDomain()"
        >
          Add new
        </v-btn>
      <v-data-table
        class="domains px-10"
        v-if="!loading"
        :headers="headers"
        :items="sortedArray"
        :search="search"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        @page-count="pageCount = $event"
        :item-class="itemRowColor"
      >
        <template v-slot:[`item.expire_at`]="{ item }">
          {{ item.expire_at.slice(0,10) }}
        </template>
        <template v-slot:[`item.blacklist`]="{ item }">
          <v-btn
            v-if="item.blacklist.blacklisted === true"
            class="tvf_color--text"
            color="#ffdde7"
            depressed
            small
            @click="showBlacklist(item)"
          >
            BLACKLIST
          </v-btn>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            icon  
            small
            :disabled="item.deleted_at !== null"        
          >
            <v-icon
              v-model="item.actions"
              @click="opendEditModal(item)"
            > 
              mdi-pencil-outline
            </v-icon>
          </v-btn>         
        </template>
        <template v-slot:[`item.status`]="{ item }">      
          <toggle-button 
            v-model="item.status"
            :color="{unchecked: '#E5E5E5', checked: '#F85182'}"
            @change="openConfirmation(item)"
            :width='23'
            :height='13'
          />
        </template>
      </v-data-table>
      <v-dialog
        v-model="blacklist_info"
        persistent
        max-width="390"
      >
        <v-card>
          <v-card-title>
            <span class="text-subtitle bold py-4 pl-4 pr-16">Blacklist</span>
            <v-spacer></v-spacer>
            <v-icon
              @click="blacklist_info = false"
            >
              mdi-close
            </v-icon>
          </v-card-title>
          <v-divider
            class="ml-8"
            width="85%"
          ></v-divider>
          <v-list class="pb-8">
            <v-toolbar-title class="subtitle-2 font-weight-bold pl-10 pt-6 pb-2">Blacklist Name</v-toolbar-title>
            <v-list-item
              v-for="name, index in blacklist.name_blacklist"
              :key="index"
            >
              <v-list-item-icon>
                <v-icon
                  class="pl-6"
                  small
                >
                  mdi-circle-small
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="subtitle-2">{{ name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>     
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="confirmation"
        :value="true"
        persistent
        @click:outside="confirmation = false"
        max-width="400px"
      >
        <v-card>
          <v-container>
            <v-col>
              <v-row class="pa-4">
                <v-col
                  class="align-center"
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <h4>{{ name }}</h4>
                  <p class="body-2 mt-4">Are you sure you want to disable this domain?</p>
                </v-col>
              </v-row>
              <v-row class="px-4 pb-4">
                <v-col
                  class="align-center"
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-btn
                    class="addButton pa-6 mr-8 white--text"
                    block
                    color="tvf_color"
                    @click="disabledItem()"
                  >
                  Disable
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-container>
        </v-card>
      </v-dialog>
      <new-domain
        v-if="addDomain"
        @close="addDomain = false"
        @updateList="addDomains"
      />
      <edit-domain
        v-if="editDomain"
        @close="editDomain = false"
        :editedItem="editedItem"
        @updateList="addDomains"
      />
    </v-card>
    <div class="pt-4 pl-4">
      <v-pagination
        v-model="page"
        :length="pageCount"
        color="#f5f5f5"
        class="primary--text"
      ></v-pagination>
    </div>
  </div>
</template>

<script>

import ApiService from "../services/api.service";
import LoadingSpinner from "../components/core/LoadingSpinner"
import NewDomain from '../components/domain/NewDomain.vue';
import EditDomain from '../components/domain/EditDomain.vue';

export default {
  props: ['search'],
  name: 'Domains',
  components: {
    LoadingSpinner,
    NewDomain,
    EditDomain
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      addDomain: false,
      editDomain: false,
      loading: false,
      blacklist_info: false,
      confirmation: false,
      blacklist: [],
      domains: [],
      editedItem: {},
      name: '',
      itemId: '',
      headers: [
        {
          text: "ASSIGNED DATABASE",
          align: "start",
          value: "database.name",
          sortable: true,          
        },
        {
          text: "DOMAIN",
          align: "start",
          value: "domain",
          sortable: true,          
        },
        {
          text: "IMAGE URL",
          align: "start",
          value: "domain_images",
          sortable: true,          
        },
        {
          text: "LINKS URL",
          align: "start",
          value: "domain_links",
          sortable: true,          
        },
        {
          text: "EXPIRE DATE",
          align: "start",
          value: "expire_at",
          sortable: true,          
        },
        {
          text: "INFORMATION",
          align: "CENTER",
          value: "blacklist",
          sortable: false,          
        },
        { 
          text: "ACTIONS", 
          align: "center",
          value: "actions", 
          sortable: false,          
        },
        { 
          text: "STATUS", 
          align: "center",
          value: "status", 
          sortable: false,          
        },
      ],
    };
  },

  mounted() {
    this.addDomains()  
  },

  computed:{
    sortedArray: function() {
      function orderDate(a, b) {
          if (a.deleted_at !== null) return 1;
          if (a.deleted_at === null) return -1;          
          return 0;

      }
      // return this.users.sort(surnameName); // sorts in-place
      return [...this.domains].sort(orderDate); // shallow clone + sort
    }
  },

  methods: {
    filterOnlyCapsText (value, search) {
      return value != null &&
        search != null &&
        typeof value === 'string' &&
        value.toString().toLocaleLowerCase().indexOf(search) !== -1
    },

    itemRowColor(item) {
      return item.blacklist.blacklisted === true ? 'red--text font-weight-bold' : 'primary--text'
    },

    newDomain() {
      this.addDomain = true
    },

    opendEditModal(item) {
      this.editedItem = {
        id: item.id,
        assigned_database: item.assigned_database,
        name: item.domain,
        domain_links: item.domain_links,
        domain_images: item.domain_images,
        expire_at: item.expire_at,
        blacklist: item.blacklist
      },
      this.editDomain = true
    },

    showBlacklist(item) {
      this.blacklist = []
      this.blacklist = item.blacklist;
      this.blacklist_info = true;
    },

    addDomains () {
      this.loading = true //the loading begin

      ApiService.get("domains/list")
        .then((response) => {
          this.loading = false //the loading stop when the response given from server
          const domains = response.data.data;  
          this.domains = domains.sort(function (a, b) {
            if (a.expire_at < b.expire_at) return 1;
            if (a.expire_at > b.expire_at) return -1;
           // a must be equal to b
            return 0;
          });
          this.domains.forEach((element, index) => {
            if(element.deleted_at !== null) {
              this.domains[index].status = false
            } else {
              this.domains[index].status  = true
            }
          }); 
          this.domains.forEach( (element, index) => {
            this.domains[index].blacklist = JSON.parse(element.blacklist)
          })
        })
        .catch((error) => {
          this.loading = false
          console.log("Ups! Something is wrong");
        });
    },

    openConfirmation(item){
      if(item.status === false) {
        this.confirmation = true
        this.name = item.domain
        this.itemId = item.id
      }
      else {
        ApiService.patch("domains/status/" + item.id)
        .then((response) => {
          this.addDomains();
          this.$store.dispatch('setSnackbar', {
            color: 'success',
            text: 'Domain is active!',
            timeout: 3000
          })
        })
      }
    },

    disabledItem() {
      ApiService.patch("domains/status/" + this.itemId)
        .then((response) => {
          this.confirmation = false
          this.addDomains();
          this.$store.dispatch('setSnackbar', {
            color: 'success',
            text: 'Domain has been disabled',
            timeout: 3000
        })
        .catch((error) => {
          console.log("Ups! Something is wrong");
        });
      })
    }
  },
};
</script>

<style scoped>

.switch {
  width: 20px !important;
}

.v-list-item__icon {
  margin: 4px 0; 
}

.v-list-item__content {
  padding: 0;
}

.v-list-item {
  min-height: 0;
}

.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 0;
}

</style>