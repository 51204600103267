<template>
  <div>
    <v-row justify="center">
      <v-dialog
        :value="true"
        persistent
        @click:outside="$emit('close')"
        max-width="400px"
      >
        <v-card>
          <v-card-title>
            <span class="text-subtitle bold pa-4">Edit Tag</span>
            <v-spacer></v-spacer>
            <v-icon
              class="mr-2"
              @click="$emit('close')"
            >
              mdi-close
            </v-icon>
          </v-card-title>
          <v-divider
            class="ml-8"
            width="90%"
          ></v-divider>
          <div class="pa-4">            
            <v-text-field
              class="px-6"
              v-model="editTag.name"
              placeholder="Enter tag"
              background-color="#F8F9FB"
              dense
              solo
              flat
            ></v-text-field>
          </div>      
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              type="submit"
              class="addButton pa-6 mb-6 mr-6 white--text"
              color="tvf_color"
              @click="editedTag"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import ApiService from "@/services/api.service";

export default {
  props: ['editTag'],
  data() {
    return {
      errors: [],
    };
  },

  methods: {

    async editedTag() {
      this.errors = [];

      await ApiService.put("tags/" + this.editTag.id, {
        name: this.editTag.name,
      })
        .then((response) => {
          this.$emit('close');
          this.$emit('updateTags');
          this.$store.dispatch('setSnackbar', {
            color: 'success',
            text: 'Tag has been updated succesfully!',
            timeout: 3000
          })
        })
        .catch((error) => {
          if (typeof error.response.data === 'object') {
            this.$store.dispatch('setSnackbar', {
            color: 'tvf_secondary',
            text: error.response.data.errors.name[0]
            })
          }
        });
    },
  }
};
</script>

<style scoped>
.col-sm-4,
.col-md-4,
.col-12,
.col-sm-8,
.col-md-8 {
  padding: 0px;
}

</style>