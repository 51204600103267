<template>
  <div>
    <v-row justify="center">
      <v-dialog
        :value="true"
        persistent
        @click:outside="$emit('close')"
        max-width="400px"
      >
        <v-card>
          <v-card-title>
            <span class="text-subtitle bold pa-4">New Tag</span>
            <v-spacer></v-spacer>
            <v-icon
              class="mr-2"
              @click="$emit('close')"
            >
              mdi-close
            </v-icon>
          </v-card-title>
          <v-divider
            class="ml-8"
            width="90%"
          ></v-divider>
          <div class="pa-4">            
            <v-text-field
              class="px-6"
              v-model="tag"
              placeholder="Enter one or more tags"
              background-color="#F8F9FB"
              dense
              solo
              flat
              @keydown="addTag"
            ></v-text-field>
            <div class="px-5">
              <v-chip
                close
                close-icon="mdi-close-outline"
                filter
                label
                outlined
                color="tvf_color"
                v-for="tag in tags"
                :key="tag"
                class="tag-input__tag ml-1 mb-1"
                @click:close="removeTag()"
              >
              {{ tag }}
              </v-chip>
            </div>
          </div>      
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              type="submit"
              class="addButton pa-6 mb-6 mr-6 white--text"
              color="tvf_color"
              :disabled="isEmpty"
              @click="saveTags"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import ApiService from "@/services/api.service";

export default {
  data() {
    return {
      tags: [],
      errors: [],
      tag: '',
    };
  },

  computed: {
    isEmpty() {
      return (
        this.tag == '' &&
        this.tags.length == 0
      );
    },
  },

  methods: {
    addTag(event){
      if (event.code == "Comma" || event.code == 'Enter') {
        event.preventDefault()
        var val = this.tag

        if (val.length > 0) {
          this.tags.push(val)
          this.tag = ''
        }
      }
    },

    removeTag (index) {
      this.tags.splice(index, 1)
    },

    saveTags() {
      this.errors = [];
      if (this.tag !== '') {
        this.tags.push(this.tag)
      }      

      this.tags.forEach(element => {
        this.tag = element
        ApiService.post("tags", {
          name: this.tag.charAt(0).toUpperCase() + this.tag.slice(1).toLowerCase()
        }).then((response) => {
          this.$emit('close');
          this.$emit('updateList');
          this.$store.dispatch('setSnackbar', {
            color: 'success',
            text: 'Tag "'+ this.tag.charAt(0).toUpperCase() + this.tag.slice(1).toLowerCase() +'" has been created!',
            timeout: 3000
          })
        }).catch((error) => {
            this.tags = []
            if (typeof error.response.data === 'object') {
              this.$store.dispatch('setSnackbar', {
              color: 'tvf_secondary',
              text: error.response.data.errors.name[0] + '": "'+ this.tag.charAt(0).toUpperCase() + this.tag.slice(1).toLowerCase()
              })
            }
          });
        });
    },
  }
};
</script>

<style scoped>
.col-sm-4,
.col-md-4,
.col-12,
.col-sm-8,
.col-md-8 {
  padding: 0px;
}


</style>