<template>
  <div>
    <v-card>
      <LoadingSpinner v-if="loading" />    
      <v-btn 
          class="addButton ml-8 my-10 white--text"
          color="tvf_color"  
          @click="newCountry()"
        >
          Add new
        </v-btn>
      <v-data-table
        class="px-10"
        v-if="!loading"
        :headers="headers"
        :items="countries"
        :search="search"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        @page-count="pageCount = $event"
      >
        <template 
          v-slot:[`item.customs`]="{ item }"
        >
          <v-select
            v-model="item.customs"
            class="customs mt-6"
            :items="item.customs"
            v-if="item.customs.lenght !== 0"
            name="custom"
            dense
            outlined
          >
          </v-select>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            icon  
            small
            :disabled="item.deleted_at !== null"        
          >
            <v-icon
              v-model="item.actions"
              @click="openEditModal(item)"
            > 
              mdi-pencil-outline
            </v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item.status`]="{ item }">      
          <toggle-button 
            v-model="item.status"
            :color="{unchecked: '#E5E5E5', checked: '#F85182'}"
            @change="openConfirmation(item)"
            :width='23'
            :height='13'
          />
        </template>
      </v-data-table>
      <v-dialog
        v-model="confirmation"
        :value="true"
        persistent
        @click:outside="confirmation = false"
        max-width="400px"
      >
        <v-card>
          <v-container>
            <v-col>
              <v-row class="pa-4">
                <v-col
                  class="align-center"
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <h4>{{ name }}</h4>
                  <p class="body-2 mt-4">Are you sure you want to disable this country?</p>
                </v-col>
              </v-row>
              <v-row class="px-4 pb-4">
                <v-col
                  class="align-center"
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-btn
                    class="addButton pa-6 mr-8 white--text"
                    block
                    color="tvf_color"
                    @click="disabledItem()"
                  >
                  Disable
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-container>
        </v-card>
      </v-dialog>
      <new-country
        v-if="addCountry"
        @close="addCountry = false"
        @updateList="addCountries"
      />
      <edit-country
        v-if="editedCountry"
        @close="editedCountry = false"
        :editCountry="editCountry"
        @updateList="addCountries"
      />
    </v-card>
    <div class="pt-4 pl-4">
      <v-pagination
        v-model="page"
        :length="pageCount"
        color="#f5f5f5"
        class="primary--text"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import ApiService from "../services/api.service";
import LoadingSpinner from "../components/core/LoadingSpinner"
import NewCountry from '../components/country/NewCountry.vue';
import EditCountry from '../components/country/EditCountry.vue';

export default {
  props: ['search'],
  name: 'Countries',
  components: {
    LoadingSpinner,
    NewCountry,
    EditCountry
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      addCountry: false,
      editedCountry: false,
      confirmation: false,
      editCountry: {},
      loading: false,
      countries: [],
      name: '',
      itemId: '',
      headers: [
        {
          text: "NAME",
          align: "start",
          value: "name",
          sortable: true,     
          width: '20%'     
        },
        { 
          text: "ACRONYM", 
          value: "acronym", 
          sortable: true,
        },
        { 
          text: "ACTIONS", 
          align: 'end',
          value: "actions", 
          sortable: false,          
        },
        { 
          text: "STATUS", 
          align: "center",
          value: "status", 
          sortable: false,  
        },
      ],
    };
  },
  
  mounted() {
    this.addCountries()
  },

  methods: {
    filterOnlyCapsText (value, search) {
      return value != null &&
        search != null &&
        typeof value === 'string' &&
        value.toString().toLocaleLowerCase().indexOf(search) !== -1
    },

    newCountry () {
      this.addCountry = true
    },

    openEditModal(item) {
      this.editCountry = {
        id: item.id,
        name: item.name,
        acronym: item.acronym
      }
      this.editedCountry = true
    },

    addCountries() {
      this.loading = true //the loading begin

      ApiService.get("countries/list")
      .then((response) => {
        this.loading = false //the loading stop when the response given from server
        const countries = response.data.data;  
        this.countries = countries.sort(function (a, b) {
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
        this.countries.forEach((element, index) => {
          if(element.deleted_at !== null) {
            this.countries[index].status = false
          } else {
            this.countries[index].status  = true
          }
        }); 
      })
      .catch((error) => {
        this.loading = false
        console.log("Ups! Something is wrong");
      });
    },

    openConfirmation(item){
      if(item.status === false) {
        this.confirmation = true
        this.name = item.name
        this.itemId = item.id
      }
      else {
        ApiService.patch("countries/status/" + item.id)
        .then((response) => {
          this.addCountries();
          this.$store.dispatch('setSnackbar', {
            color: 'success',
            text: 'Country is active!',
            timeout: 3000
          })
        })
      }
    },
      
    disabledItem(item) {
      ApiService.patch("countries/status/" + this.itemId)
        .then((response) => {
          this.confirmation = false
          this.addCountries();
          this.$store.dispatch('setSnackbar', {
            color: 'success',
            text: 'Country has been disabled',
            timeout: 3000
        })
        .catch((error) => {
          console.log("Ups! Something is wrong");
        });
      })
    }
  },
};
</script>

<style scoped>

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  padding: 5px 10px;
}

.customs {
    width: 50%;
}
</style>