<template>
  <div>
    <v-row justify="center">
      <v-dialog
        :value="true"
        persistent
        @click:outside="$emit('close')"
        max-width="600px"
      >
        <v-card>
          <v-card-title>
            <span class="text-subtitle bold pa-4">Edit Country</span>
            <v-spacer></v-spacer>
            <v-icon
              class="mr-2"
              @click="$emit('close')"
            >
              mdi-close
            </v-icon>
          </v-card-title>
          <v-divider
            class="ml-8"
            width="90%"
          ></v-divider>
          <v-form @submit.prevent="editedCountry">
            <v-card-text>
              <v-container>
                <v-col>
                  <v-row class="px-8 py-4">
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <label>Name</label>
                      <v-text-field
                        v-model="editCountry.name"
                        class="mr-2 body-2"
                        background-color="#F8F9FB"
                        dense
                        solo
                        flat
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <label class="ml-2">Acronym</label>
                      <v-text-field
                        v-model="editCountry.acronym"
                        class="ml-2 body-2"
                        background-color="#F8F9FB"
                        dense
                        solo
                        flat
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                type="submit"
                class="addButton pa-6 mb-6 mr-6 white--text caption"
                color="tvf_color"
                :disabled='!isComplete'
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import ApiService from "@/services/api.service";

export default {
  props: ['editCountry'],
  data() {
    return {
      errors: [],
    };
  },

  computed: {
    isComplete() {
      return (
        this.editCountry.name &&
        this.editCountry.acronym
      );
    },
  },

  methods: {

    editedCountry() {
      this.errors = [];

      ApiService.put("countries/" + this.editCountry.id, {
        name: this.editCountry.name,
        acronym: this.editCountry.acronym.toUpperCase(),
      }).then((response) => {
        console.log("Submit Success");
        this.$emit('close');
        this.$emit('updateList');
        this.$store.dispatch('setSnackbar', {
          color: 'success',
          text: 'New country has been created!',
          timeout: 3000
        })
      }).catch((error) => {
        if (typeof error.response.data === 'object') {
          this.$store.dispatch('setSnackbar', {
          color: 'tvf_secondary',
          text: error.response.data.message
          })
        }
      });
    },
  }
};
</script>

<style scoped>
.col-sm-4,
.col-md-4,
.col-12,
.col-sm-8,
.col-md-8 {
  padding: 0px;
}


</style>