<template>
  <div>
    <v-row justify="center">
      <v-dialog
        :value="true"
        persistent
        @click:outside="$emit('close')"
        max-width="500px"
      >
        <v-card>
          <v-card-title>
            <span class="text-subtitle bold pa-4">Edit Domain</span>
            <v-spacer></v-spacer>
            <v-icon
              class="mr-2"
              @click="$emit('close')"
            >
              mdi-close
            </v-icon>
          </v-card-title>
          <v-divider
            class="ml-8"
            width="90%"
          ></v-divider>
          <v-form @submit.prevent="updateDomain">
            <v-card-text>
              <v-container>
                <v-col>
                  <v-row class="pa-4">
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <label>Domain</label>
                      <v-text-field
                        v-model="editedItem.name"
                        class="body-2"
                        background-color="#F8F9FB"
                        dense
                        solo
                        flat
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <label>Assigned Database</label>
                      <v-select
                        v-model="editedItem.assigned_database"
                        :items="this.$store.state.databases"
                        item-text="name"
                        required
                        class="body-2"
                        background-color="#F8F9FB"
                        dense
                        solo
                        flat
                      ></v-select>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <label>Images Domain</label>
                      <v-text-field
                        v-model="editedItem.domain_images"
                        class="body-2"
                        background-color="#F8F9FB"
                        dense
                        solo
                        flat
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <label right>Links Domain</label>
                      <v-text-field
                        v-model="editedItem.domain_links"
                        class="body-2"
                        background-color="#F8F9FB"
                        dense
                        solo
                        flat
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">

                        <label>Expire Date</label>
                        <v-text-field
                          v-model="editedItem.expire_at"
                          prepend-icon="mdi-calendar"
                          readonly
                          class="body-2"
                          background-color="#F8F9FB"
                          dense
                          solo
                          flat
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editedItem.expire_at"
                        color="tvf_color"
                        @input="menu2 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  </v-row>
                </v-col>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                type="submit"
                class="addButton pa-6 mb-6 mr-6 white--text"
                color="tvf_color"
                :disabled='!isComplete'
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import ApiService from "@/services/api.service";

export default {
  props: ['editedItem'],
  data() {
    return {
      errors: [],
      menu2: false
    };
  },

  computed: {
    isComplete() {
      return (
        this.editedItem.name &&
        this.editedItem.assigned_database &&
        this.editedItem.domain_images &&
        this.editedItem.domain_links &&
        this.editedItem.expire_at
      );
    },
  },

  mounted() {
    this.$store.commit('getDatabases')
  },

  methods: {  

    async updateDomain() {
      await ApiService.put("domains/" + this.editedItem.id, {
        domain: this.editedItem.name,
        assigned_database: this.editedItem.assigned_database,
        domain_images: this.editedItem.domain_images,
        domain_links: this.editedItem.domain_links,
        expire_at: this.editedItem.expire_at,
      })
      .then((response) => {
        this.$emit('close');
        this.$emit('updateList');
        this.$store.dispatch('setSnackbar', {
          color: 'success',
          text: 'Domain has been updated succesfully!',
          timeout: 3000
        })
      })
      .catch((error) => {
        if (typeof error.response.data === 'object') {
          Object.values(error.response.data.errors).flat().forEach( error => {
            this.$store.dispatch('setSnackbar', {
              color: 'tvf_secondary',
              text: error,
              timeout: 3000
            })       
          })
        }
      }); 
    },
  }
};
</script>

<style scoped>
.col-sm-4,
.col-md-4,
.col-12,
.col-sm-8,
.col-md-8 {
  padding: 0px;
}


</style>