<template>
  <div>
    <v-container>        
      <v-row>
        <v-col
          cols="12"
          md="6"
          sm="6"
          class="prefills-card pl-2"
        >
          <v-sheet
            class="card-table"
            rounded="lg"
          >
            <LoadingSpinner v-if="loading" />
            <v-btn 
              class="addButton ml-8 my-10 white--text"
              color="tvf_color"  
              @click="openCreatePrefill()"
            >
              Add new
            </v-btn>
            <h4 class="d-flex justify-center pb-8">TVF PREFILLS</h4>
            <v-data-table
              class="paymodelsList px-10"
              v-if="!loading"
              :headers="prefillsHeaders"
              :items="prefills"
              :search="search"
              :page.sync="page"
              :items-per-page="itemsPerPage"
              @page-count="pageCount = $event"
            >
              <template v-slot:[`item.actions`]="{ item }">
                <v-btn
                  icon          
                >
                  <v-icon
                    v-model="item.actions"
                    @click="openEditPrefill(item)"
                  > 
                    mdi-pencil-outline
                  </v-icon>
                </v-btn>
              </template>
            </v-data-table>
            <div class="pagination py-8 pl-10">
              <v-pagination
                v-model="page"
                :length="pageCount"
                color="tvf_color"   
                :total-visible="9"             
              ></v-pagination>
            </div>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
    <new-prefill
      v-if="addPrefill"
      @close="closeCreateModal"
      @updateList="addPrefills"
    />
    <edit-prefill
      v-if="editedPrefill"
      @close="closeCreateModal"
      :editPrefill="editPrefill"
      @updateList="addPrefills"
    />
  </div>
</template>

<script>
import ApiService from "../services/api.service";
import LoadingSpinner from "../components/core/LoadingSpinner";
import NewPrefill from "../components/tvf_prefills/NewPrefill";
import EditPrefill from "../components/tvf_prefills/EditPrefill";

export default {
  props: ['search'],
  name: 'Tvf_prefills',
  components: {
    LoadingSpinner,
    EditPrefill,
    NewPrefill
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      loading: false,
      addPrefill: false,
      editedPrefill: false,
      editPrefill: {},
      search: "",
      prefills: [],
      errors: [],
      prefillsHeaders: [
        {
          text: "NAME",
          align: "start",
          value: "name",
          sortable: true,          
        },
        {
          text: "PREFILL",
          align: "start",
          value: "prefill",
          sortable: true,          
        },
        {
          text: "ACTIONS",
          align: "center",
          value: "actions",
          sortable: false,
        },
      ]
    }
  },

  mounted() {
    this.addPrefills()
  },

  methods: {

    openCreatePrefill() {
      this.addPrefill = true
    },

    openEditPrefill(item) {
      this.editPrefill = {
        id: item.id,
        prefill_name: item.name,
        prefill: item.prefill
      },
      this.editedPrefill = true;
    },

    closeCreateModal() {
      this.errors = []
      this.addPrefill = false
      this.editedPrefill = false
    },

    addPrefills() {
      this.loading = true //the loading begin

      ApiService.get("tvfprefills")
        .then((response) => {
          this.loading = false //the loading stop when the response given from server
          const prefills = response.data.data;  
          this.prefills = prefills.sort(function (a, b) {
            if (a.name > b.name) {
              return 1;
            }
            if (a.name < b.name) {
              return -1;
            }
            // a must be equal to b
            return 0;
          });                   
        })
        .catch((error) => {
          this.loading = false
          console.log("Ups! Something is wrong");
        });
    }
  }

};
</script>

<style scoped>
p {
  font-family: Poppins;
  font-size: 16px;
  font-weight: bold;
}

.prefills-card{
  margin: auto;
}
.options {
  font-family: Poppins;
  font-size: 14px;
  font-weight: bold;
}

.v-card__title {
  flex-wrap: nowrap;
}

@media (max-width: 1300px) {

  .card-table {
    min-height: 700px;
  }

  .v-data-footer__select {
    margin-right: 0px !important;
  }
}

</style>