<template>
  <div>
    <v-row justify="center">
      <v-dialog
        :value="true"
        persistent
        max-width="550px"
        @click:outside="$emit('close')"
      >
        <v-card>
          <v-card-title>
            <span class="text-subtitle bold pa-4">Edit User</span>
            <v-spacer></v-spacer>
            <v-icon
              class="mr-2"
              @click="$emit('close')"
            >
              mdi-close
            </v-icon>
          </v-card-title>
          <v-divider
            class="ml-8"
            width="90%"
          ></v-divider>
          <v-form 
            @submit.prevent="updateUser"
            v-model="isFormValid"
          >
            <v-card-text>
              <v-container>
                <h4 class="pl-4">PROFILE</h4>
                <v-col>
                  <v-row class="pa-4">
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <label>Username</label>
                      <v-text-field
                        v-model="editedItem.username"
                        class="body-2"
                        :rules="usernameRules"
                        background-color="#F8F9FB"
                        dense
                        solo
                        flat
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <label>Role</label>
                      <v-select
                        v-model="editedItem.role"
                        class="body-2"
                        :items="roles"
                        item-text="name"
                        required
                        background-color="#F8F9FB"
                        dense
                        solo
                        flat
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-col>
                <h4 class="pl-4">USER DATA</h4>
                <v-col>
                  <v-row class="pa-4">
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <label>First Name</label>
                      <v-text-field
                        v-model="editedItem.first_name"
                        class="body-2"
                        :rules="firstnameRules"
                        background-color="#F8F9FB"
                        dense
                        solo
                        flat
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <label>Last Name</label>
                      <v-text-field
                        v-model="editedItem.last_name"
                        class="body-2"
                        :rules="lastnameRules"
                        background-color="#F8F9FB"
                        dense
                        solo
                        flat
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <label>Email</label>
                      <v-text-field
                        type="email"
                        class="body-2"
                        v-model="editedItem.email"
                        :rules="emailRules"
                        required
                        background-color="#F8F9FB"
                        dense
                        solo
                        flat
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <label>Company</label>
                      <v-select
                        v-model="editedItem.company_id"
                        class="body-2"
                        :items="companies"
                        item-value="id"
                        item-text="name"
                        required
                        background-color="#F8F9FB"
                        dense
                        solo
                        flat
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-col>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                v-if="editedItem.role === 'affiliation' || editedItem.role === 'traffic'"
                class="addButton pa-6 mb-6 mr-6 white--text body-2"
                color="btn_secondary"
                @click="addDatabases"
                :disabled="!isFormValid"
              >
                Add databases
              </v-btn>
              <v-btn
                v-else
                type="submit"
                class="addButton pa-6 mb-6 mr-6 white--text body-2"
                color="tvf_color"
                :disabled="!isFormValid"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dbDialog"
        :value="true"
        persistent
        max-width="600px"
      >
        <v-card>
          <v-card-title>
            <span class="text-subtitle bold pa-4">Assign Databases</span>
            <v-spacer></v-spacer>
            <v-icon
              class="mr-2"
              @click="$emit('close')"
            >
              mdi-close
            </v-icon>
          </v-card-title>
          <v-divider class="ml-8" width="90%"></v-divider>
          <v-card-text>
            <br>
            <v-form>
              <v-text-field
                v-model="search"
                class="search-input mx-5 body-2"
                label="Search database"
                prepend-inner-icon="mdi-magnify"
                clearable
                background-color="#F8F9FB"
                dense
                solo
                flat
              ></v-text-field>
            </v-form>        
            <v-container>
              <v-col>
                <v-row class="px-8">
                  <v-col
                    cols="12"
                  >
                    <v-data-table
                      class=""
                      v-if="!loading"
                      :headers="headers"
                      :items="databases"
                      item-value="databases.id"
                      :search="search"
                    >
                      <LoadingSpinner v-if="!loading" />
                      <template 
                        v-slot:[`item.viewer`]="{ item }"
                      >
                        <v-sheet id="switch">
                          <v-switch
                            v-model="item.use"
                            color="tvf_color"
                            value="viewer"
                            :disabled='item.use === "manager"'
                          ></v-switch>
                        </v-sheet>
                      </template>
                      <template 
                          v-slot:[`item.manager`]="{ item }"
                      >
                        <v-sheet id="switch">
                          <v-switch
                            v-model="item.use"
                            color="tvf_color"
                            value="manager"
                            :disabled='item.use === "viewer"'
                          ></v-switch>
                        </v-sheet>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-col>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn
              type="submit"
              class="addButton pa-6 mb-6 ml-6 white--text"
              color="#BDBDBD"
              @click="dbDialog = false"
            >
              <v-icon
                dark
                left
              >
                mdi-arrow-left
              </v-icon>
              Back
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              class="addButton pa-6 mb-6 mr-6 white--text"
              color="tvf_color"
              @click="updateUser"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import ApiService from "@/services/api.service";

export default {
  props: ['editedItem'],
  data() {
    return {
      roles: [],
      companies: [],
      errors: [],
      database: [],
      databases: [],
      databasesTable: [],
      search: "",
      loading: false,
      isFormValid: false,
      switch2: false,
      dbDialog: false,
      headers: [
      {
        text: "NAME",
        align: "start",
        value: "name",
        sorteable: false,          
      },
      {
        text: "VIEWER",
        align: "center",
        value: "viewer",
      },
      { 
        text: "MANAGER", 
        value: "manager", 
        align: "center",
      }],
      usernameRules: [
        v => !!v || 'Username is required',
      ],
      roleRules: [
        v => !!v || 'Role is required',
      ],
      firstnameRules: [
        v => !!v || 'First name is required',
      ],
      lastnameRules: [
        v => !!v || 'Last name is required',
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Invalid Email Address'
      ],
      companyRules: [
        v => !!v || 'Company is required'
      ],
    };
  },

  mounted() {    
    ApiService.get("databases")        
      .then((response) => {
          this.loading = false //the loading stop when the response given from server
          this.databases = response.data.data;
      })
      .catch((error) => {
          this.loading = false
          console.log("Ups! Something is wrong");
      });

    ApiService.get("roles/list")
      .then((response) => {
        this.roles = response.data.data;
      })
      .catch((error) => {
        console.log("Ups! Something is wrong");
      });

    ApiService.get("companies")
      .then((response) => {
        this.companies = response.data.data;
      })
      .catch((error) => {
        console.log("Ups! Something is wrong");
      });
  },

  methods: {

    addDatabases(item) {
      this.dbDialog = true 
      this.databases.forEach((value) => {
        this.editedItem.databases.forEach((value2) => {
          if(value.id === value2.id && value2.use === 'viewer') {
            this.databases[value.id - 1].use = 'viewer'
          }
          if(value.id === value2.id && value2.use === 'manager') {
            this.databases[value.id - 1].use = 'manager'
          }
        })
      })
    },

    async updateUser() {
      this.database = this.databases.filter(item => item.use)
      this.database.forEach((value) => {
        this.databasesTable.push({database_id: value.id, use: value.use})
        this.editedItem.databases = this.databasesTable
      })
            
      await ApiService.put("users/" + this.editedItem.id, {
        username: this.editedItem.username,
        first_name: this.editedItem.first_name,
        last_name: this.editedItem.last_name,
        email: this.editedItem.email,
        company_id: this.editedItem.company_id,
        role: this.editedItem.role,
        databases: JSON.stringify(this.databasesTable)
      })
        .then((response) => {
          this.$emit('close');
          this.dbDialog = false
          this.$emit('updateList');
          this.$store.dispatch('setSnackbar', {
            color: 'success',
            text: 'User has been updated succesfully!',
            timeout: 3000
          })
        })
        .catch((error) => {
          if (typeof error.response.data === 'object') {
            Object.values(error.response.data.errors).flat().forEach( error => {
              console.log(error)              
              this.$store.dispatch('setSnackbar', {
                color: 'tvf_secondary',
                text: error,
                timeout: 3000
              })       
            })
          }
        });
    },
  },
};
</script>

<style scoped>
.col-sm-4,
.col-md-4,
.col-12,
.col-sm-8,
.col-md-8 {
  padding: 0px;
}

.col-sm-4, .col-md-4, .col-12, .col-sm-8, .col-md-8 {
  padding: 0px;
}

.search-input {
    margin: auto;
}

#switch {
    margin: auto;
    width: 55px;
}

.theme--light.v-sheet #switch{
  background-color: rgba(255, 255, 255, 0) !important;
}

</style>