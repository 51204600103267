<template>
  <div>
    <v-row justify="center">
      <v-dialog
        :value="true"
        persistent
        @click:outside="$emit('close')"
        max-width="500px"
      >
        <v-card>
          <v-card-title>
            <span class="text-subtitle bold pa-4">Edit Owner
            </span>
            <v-spacer></v-spacer>
            <v-icon
              class="mr-2"
              @click="$emit('close')"
            >
              mdi-close
            </v-icon>
          </v-card-title>
          <v-divider
            class="ml-8"
            width="90%"
          ></v-divider>
          <v-form 
            @submit.prevent="updateOwner()"
            class="px-4"
          >
            <v-card-text>
              <v-container>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-row class="py-4 pl-4">
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <label>Name</label>
                      <v-text-field
                        v-model="editedItem.name"
                        required
                        class="body-2"
                        background-color="#F8F9FB"
                        dense
                        solo
                        flat
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <label>Fiscal Name</label>
                      <v-text-field
                        v-model="editedItem.fiscal_name"
                        required
                        class="body-2"
                        background-color="#F8F9FB"
                        dense
                        solo
                        flat
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <label>Address</label>
                      <v-text-field
                        v-model="editedItem.address"
                        required
                        class="body-2"
                        background-color="#F8F9FB"
                        dense
                        solo
                        flat
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <label right>CIF</label>
                      <v-text-field
                        v-model="editedItem.cif"
                        required
                        class="body-2"
                        background-color="#F8F9FB"
                        dense
                        solo
                        flat
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <label>Email</label>
                      <v-text-field
                        type="email"
                        v-model="editedItem.email"
                        :rules="emailRules"
                        required
                        class="body-2"
                        background-color="#F8F9FB"
                        dense
                        solo
                        flat
                      ></v-text-field>
                    </v-col>
                    <v-col
                      class="inputNumber"
                      cols="6"
                      sm="6"
                      md="6"
                    >
                      <label>Phone</label>
                      <v-text-field
                        v-model="editedItem.phone"
                        type="phone"
                        :rules="phoneRules"
                        class="body-2"
                        background-color="#F8F9FB"
                        dense
                        solo
                        flat
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="6"
                      sm="6"
                      md="6"
                    >
                      <label>Percentage Revenue</label>
                      <v-text-field
                        v-model="editedItem.percentage_revenue"
                        type="number"
                        class="body-2"
                        background-color="#F8F9FB"
                        dense
                        solo
                        flat
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                type="submit"
                class="addButton pa-6 mb-6 mr-6 white--text caption"
                color="tvf_color"
                :disabled='!isComplete'
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-form>            
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import ApiService from "@/services/api.service";

export default {
  props: ['editedItem'],
  data() {
    return {
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Invalid Email Address',
      ],
      phoneRules: [
        (v) => !!v || "Phone is required",
        (v) => /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(v) || 'Invalid Phone Number'
      ],
      nameRules: [(v) => !!v || "Name is required"],
      fiscalNameRules: [(v) => !!v || "Fiscal Name is required"],
      addressRules: [(v) => !!v || "Address is required"],
      cifRules: [(v) => !!v || "Cif is required"],
      typeRules: [(v) => !!v || "Type is required"],
      skypeRules: [(v) => !!v || "Skype is required"],
      languageRules: [(v) => !!v || "Language is required"],
    };
  },

  computed: {
    isComplete() {
      return (
        this.editedItem.name &&
        this.editedItem.fiscal_name &&
        this.editedItem.address &&
        this.editedItem.cif &&
        this.editedItem.email &&
        this.editedItem.phone
      );
    },
  },

  mounted() {
  },

  methods: {
    async updateOwner() {
      this.errors = [];

      await ApiService.put("owners/" + this.editedItem.id, {
        name: this.editedItem.name,
        fiscal_name: this.editedItem.fiscal_name,
        address: this.editedItem.address,
        cif: this.editedItem.cif,
        email: this.editedItem.email,
        phone: this.editedItem.phone,
        percentage_revenue: this.editedItem.percentage_revenue
      })
        .then((response) => {
          this.$emit('close');
          this.$emit('updateList');
          this.$store.dispatch('setSnackbar', {
            color: 'success',
            text: 'Agency has been updated succesfully!',
            timeout: 3000
          })
        })
        .catch((error) => {
          if (typeof error.response.data === 'object') {
            Object.values(error.response.data.errors).flat().forEach( error => {
              this.$store.dispatch('setSnackbar', {
                color: 'tvf_secondary',
                text: error,
                timeout: 3000
              })       
            })
          }
        });
    },
  },
};
</script>

<style scoped>
.col-sm-4,
.col-md-4,
.col-12,
.col-sm-8, .col-md-8{
  padding: 0px;
}

.col-sm-6,
.col-md-6 {
  padding: 1px;
}

</style>