<template>
  <div>
    <v-card>
      <LoadingSpinner v-if="loading" />    
      <v-card-title>      
        <v-btn 
          class="addButton ml-8 mt-4 white--text"
          color="tvf_color"  
          @click="openCreateRole"
        >
          Add new role
      </v-btn> 
        <v-spacer class="spacer" />
        <v-form>
          <v-text-field
            v-model="searchQuery"
            class="caption pr-6 mt-4"
            prepend-inner-icon="mdi-magnify"
            background-color="#F8F9FB"
            dense
            solo
            flat
          ></v-text-field>
        </v-form>
      </v-card-title>
      <v-simple-table 
          class="px-12 pb-14 pt-4"
          fixed-header
          height="500px"
      >
        <template v-slot:default>
          <thead>
            <tr>
                <th class="text-left font-weight-black text-uppercase">
                    PERMISSIONS
                </th>
                <th 
                    class="text-left font-weight-black text-uppercase"
                    v-for="role in roles"
                    :key="role.id"
                >
                    {{ role.name }}
                </th>
            </tr>
          </thead>
          <tbody v-if="permissions.length > 0">
            <tr
                v-for="permission in resultQuery"
                :key="permission.id"
            >
              <td>{{ permission.name }}</td>
              <td
                  v-for="(role) in roles"
                  :key="role.name"
              >
<!--                 {{roles[index].permissions}}
 -->                <v-checkbox
                  class="ml-2"
                  color="tvf_color"
                  :input-value="_roles[role.id].permissions.find(perm=>perm.name===permission.name)"
                  @change="add_permission($event,role.name,permission.name)"
                ></v-checkbox>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td :colspan="roles.length + 1" style="text-align: center; color: #cfcfcf">No data available</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-spacer></v-spacer>
      <new-role
        v-if="newRole"
        @close="newRole = false"
        @updateList="getRoles"
      />
      <new-permission
        v-if="newPermission"
        @close="newPermission = false"
        @updateList="getPermissions"
      />
    </v-card>
  </div>
</template>

<script>
import ApiService from "@/services/api.service";
import LoadingSpinner from "../components/core/LoadingSpinner"
import NewRole from "../components/roles/NewRole.vue";
import NewPermission from "../components/roles/NewPermission.vue";

export default {
  name: 'roles_permission',
  components: {
    LoadingSpinner,
    NewRole,
    NewPermission
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      searchQuery: null,
      enabled: null,
      loading: false,
      checkbox: true,
      newRole: false,
      newPermission: false,
      check_permission: false,
      roles: [
        {
        text: 'roles.name',
        align: 'start',
        sortable: false,
        value: 'roles.name',
        },
      ],
      permissions: [],
      slots: [
        'no-data',
        'no-results',
      ],
    }
  },

  computed: {
    resultQuery(){
      if(this.searchQuery){
      return this.permissions.filter((item)=>{
        return this.searchQuery.toLowerCase().split(' ').every(v => item.name.toLowerCase().includes(v))
      })
      }else{
        return this.permissions;
      }
    },

    _permissions() {
      return this.permissions.reduce((acc, curr) => {
        const id = curr.id;
        acc[id] = curr;
        return acc;
      }, {});
    },

    _roles() {
      return this.roles.reduce((acc, curr) => {
        const id = curr.id;
        acc[id] = curr;
        return acc;
      }, {});
    }
  },

  mounted() {
    this.getRoles()
    this.getPermissions()
  },

  watch: {
    enabled (slot) {
      if (slot === 'no-data') {
        this.items = []
      } else if (slot === 'no-results') {
        this.searchQuery = '...'
      } else {
        this.searchQuery = null
        this.items = permissions
      }
    },
  },

  methods: {
    isEnabled (slot) {
      return this.enabled === slot
    },

    add_permission(e, role, permission) {

      const index = this.roles.findIndex((r) => r.name === role);
      const found = this.roles[index].permissions.find(perm=> perm.name === permission)

      if (found) { 
        ApiService.delete("permissions/revoke?role="+ role + "&permission=" + permission)
        .then((response) => {
          this.$store.dispatch('setSnackbar', {
            color: 'success',
            text: "'" + permission + "' permission has been deleted to '" + role + "' role",
            timeout: 3500
          })
          this.getRoles();
          this.getPermissions();
          })
        .catch((error) => {
          if (typeof error.response.data === 'object') {
            Object.values(error.response.data.error).flat().forEach( error => {
              this.$store.dispatch('setSnackbar', {
                color: 'tvf_secondary',
                text: error,
                timeout: 3500
              })       
            })
          }
        });

      } else {
        ApiService.post("permissions/give", {
          role: role, 
          permission: permission
        })
        .then((response) => {
          this.$store.dispatch('setSnackbar', {
            color: 'success',
            text: "'" + permission + "' permission has been added to '" + role + "' role!",
            timeout: 3500
          })
          this.getRoles();
          this.getPermissions();
        })
        .catch((error) => {
          if (typeof error.response.data === 'object') {
            Object.values(error.response.data.errors).flat().forEach( error => {
              console.log(error)              
              this.$store.dispatch('setSnackbar', {
                color: 'tvf_secondary',
                text: error,
                timeout: 3500
              })       
            })
          }
        }); 
      }
    },

    openCreateRole() {
      this.newRole = true
    },

    getRoles() {
      this.loading = true //the loading begin

      ApiService.get("roles/permissions")
      .then((response) => {
        this.loading = false 
        const roles = response.data.data;
        this.roles = roles.sort(function (a, b) {
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
      })
      .catch((error) => {
        this.loading = false 
        if (typeof error.response.data === 'object') {
          Object.values(error.response.data.errors).flat().forEach( error => {
            this.$store.dispatch('setSnackbar', {
              color: 'tvf_secondary',
              text: error,
              timeout: 3500
            })       
          })
        }
      }); 
    },

    getPermissions() {
      ApiService.get("permissions/list")
      .then((response) => {
        this.permissions = response.data.data;
      })
      .catch((error) => {
        console.log("Ups! Something is wrong");
      });
    }
  }
};
</script>

<style scoped>

.text-btn {
  font-family: Poppins, sans-serif;
  font-size: 14px;
  line-height: 24px !important;
  text-transform: capitalize !important;
}

.v-navigation-drawer {
  width: 350px !important;
}
</style>