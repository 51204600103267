<template>
    <div>
      <v-container>        
        <v-row>
          <v-col
            class="seed-card"
            cols="12"
            md="6"
            sm="6"
          >
            <v-sheet
              class="card-table"
              rounded="lg"
            >
              <LoadingSpinner v-if="loading" />
              <div class="d-flex">
                <v-btn 
                    class="addButton ml-8 my-10 white--text"
                    color="tvf_color"  
                    @click="openCreateSeed()"
                >
                    Add new
                </v-btn>
                <v-select
                    class="caption mt-10 mx-6"
                    v-model="country"
                    :items="countries"
                    item-value="id"
                    item-text="name"
                    placeholder="Filter by country"
                    background-color="#F8F9FB"
                    dense
                    solo
                    flat
                    @change="showSeeds(country)"
                    >
                </v-select>
                <v-spacer class="spacer2" />
              </div>
              <h4 class="d-flex justify-center pb-10">SEEDS</h4>
              <v-data-table
                class="seedsList px-10"
                v-if="!loading"
                :headers="headers"
                :items="seeds"
                :search="search"
                :page.sync="page1"
                :items-per-page.sync="itemsPerPage"
                @page-count="pageCount1 = $event"
              >
              <template v-slot:[`item.actions`]="{ item }">
                <v-btn icon class="mr-2">
                    <v-icon                        
                        v-model="item.actions"
                        @click="showSeedList(item)"
                        color="black"
                    >
                    mdi-pencil-outline
                    </v-icon>
                </v-btn>
                <toggle-button 
                    :value="item.status"
                    v-model="item.status"
                    switchColor='#FFF'
                    :color="{unchecked:'#E5E5E5', checked: '#F85182'}"
                    @change="openConfirmation(item)"
                    :width='23'
                    :height='13'
                    small
                />
                </template>
              </v-data-table>
              <div class="py-8 pl-10">
                <v-pagination
                  v-model="page1"
                  :length="pageCount1"
                  color="tvf_color"
                  :total-visible="5"
                ></v-pagination>
              </div>
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
      <new-seed
        v-if="addSeed"
        @close="closeCreateModal"
      />
      <edit-seed
        v-if="editSeed"
        :list_name="list_name"
        :country="countriesList"
        :emails="emails"
        :editMode="editMode"
        @close="closeEditModal"
      />
    </div>
  </template>
  
  <script>
  import ApiService from "../services/api.service";
  import LoadingSpinner from "../components/core/LoadingSpinner";
  import NewSeed from "../components/seed/addSeed";
  import EditSeed from "../components/seed/editSeed";
  import { mapState } from 'vuex';
  
  export default {
    props: ['search'],
    name: 'Seeds',
    components: {
      LoadingSpinner,
      NewSeed,
      EditSeed
    },
    data() {
      return {
        page1: 1,
        page2: 1,
        pageCount1: 0,
        pageCount2: 0,
        itemsPerPage: 10,
        loading: false,
        addSeed: false,
        editSeed: false,
        editMode: false,
        emailsModal: false,
        errors: [],
        emails: [],
        country: '',
        headers: [
          {
            text: "LIST NAME",
            align: "start",
            value: "name",
            sortable: true,          
          },
          {
            text: "SIZE",
            align: "start",
            value: 'emailCount',
            sortable: true,          
          },
          {
            text: "ACTIONS",
            align: "start",
            value: 'actions',
            sortable: true,          
          },          
        ],
        emailsheaders: [
            {
                text: "LIST NAME",
                align: "start",
                value: "email",
                sortable: true,          
            },
            {
                text: "ACTIONS",
                align: "center",
                value: 'actions',
                sortable: true,          
            },
        ],
      }
    },

    computed: {
        ...mapState([
            'seeds', // esto mapeará this.seeds a this.$store.state.seeds
            'countries'
        ]),
    },
  
    mounted() {
        this.$store.dispatch('getSeeds')
        this.$store.commit('getCountries')
    },
  
    methods: {
        openCreateSeed() {
            this.addSeed = true
        },

        showSeedList(item){
            this.editMode = true
            this.list_name = item.name
            this.countriesList = item.country
            this.emails = item.emails.map(email => ({ email }));
            this.editSeed = true
        },

        closeCreateModal() {
            this.addSeed = false
        },

        closeEditModal() {
            this.editSeed = false
        },

        showEmails(item){
            // Convertir cada email en un objeto donde la clave `email` contiene el email
            this.emails = item.emails.map(email => ({ email }));
            this.emailsModal = true
        },

        showSeeds(country) {
            ApiService.get("seeds/list/" + country)
            .then((response) => {
                this.loading = false; // the loading stops when the response given from server
                let data = response.data.data;  
                data = data.map(item => {
                    const emails = JSON.parse(item.emails);
                    return {
                    ...item,
                    emails,
                    emailCount: emails.length,
                    };
                });

                // Ordenar por nombre
                data.sort(function (a, b) {
                    if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return 1;
                    }
                    if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return -1;
                    }
                    // a must be equal to b
                    return 0;
                });

                // Usar una mutación Vuex para actualizar el estado `seeds`
                this.$store.commit('setSeeds', data);
            })
            .catch((error) => {
                if (typeof error.response.data === "object") {
                Object.values(error.response.data.errors)
                    .flat()
                    .forEach((error) => {
                    this.$store.dispatch("setSnackbar", {
                        color: "tvf_secondary",
                        text: error,
                        timeout: 3000,
                    });
                    });
                }
            });
        },
    }
  
  };
  </script>
  
  <style scoped>
  p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: bold;
  }
  .options {
    font-family: Poppins;
    font-size: 14px;
    font-weight: bold;
  }
  
  .seed-card{
    margin: auto;
  }
  .spacer2 {
    width: 80%;
  }
  
  .v-card__title {
    flex-wrap: nowrap;
  }
  
  @media (max-width: 600px) {
    .v-application .pl-2{
      padding-left: 0 !important;
    }
  
    .seedsList {
      padding-bottom: 20px;
    }
  }
  
  @media (max-width: 1300px) {
    .container{
      max-width: 1000px !important;
    }
    .card-table {
      min-height: 700px;
    }
  
    .v-data-footer__select {
      margin-right: 0px !important;
    }
  }
  
  </style>