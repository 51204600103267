<template>
  <v-row class="px-6 py-3">
    <v-card class="form-modal">
      <v-row class="d-flex flex-row justify-space-between pr-8">
        <div>
          <h2 class="text-capitalize font-weight-bold pt-8 pl-12">{{ editItem.name_title }}</h2>          
          <v-spacer></v-spacer>
        </div>
        <div
          class="pt-8 pr-2 d-flex flex-row-reverse"
        >
          <v-btn
            type="submit"
            class="addButton white--text caption"
            color="tvf_color"
            @click="saveDatabase()"
          >
            Save
          </v-btn>
          <v-btn
            type="submit"
            class="addButton mr-3 white--text caption"
            color="btn_secondary"
            @click="close()"
          >
            Cancel
          </v-btn>        
          <toggle-button
            v-model="editItem.switch_status"
            class="pr-4 pt-3"
            switchColor="#FFF"
            :color="{unchecked: '#E5E5E5', checked: '#F85182'}"
            @change="disabledItem()"
            :sync="true"
            :width='23'
            :height='13'
          />
        </div>
      </v-row>
      <div>
        <v-form class="pt-10">
          <v-row class="pl-10 py-6">
            <v-col
              cols="1"
              sm="1"
              md="1"
            >
              <label class="font-weight-bold">ID</label><br />
              <span>{{ editItem.id }}</span>
            </v-col>
            <v-col
              cols="2"
              sm="2"
              md="2"
            >
              <label class="font-weight-bold">NAME</label><br />
              <v-text-field
                class="text caption"
                v-model="editItem.name"
                background-color="#F8F9FB"
                dense
                solo
                flat
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="2"
              sm="2"
              md="2"
            >
              <label class="font-weight-bold">PLATFORM</label><br />
              <v-autocomplete
                class="text caption"
                v-model="editItem.platform"
                :items="this.$store.state.platforms"
                item-value="editItem.platform_id"
                item-text="name"
                @change="onChange(editItem.platform)"
                background-color="#F8F9FB"
                dense
                solo
                flat
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="2"
              sm="2"
              md="2"
            >
              <label class="font-weight-bold">COUNTRY</label><br />
              <span>{{ editItem.country }}</span>
            </v-col>
            <v-col
              cols="2"
              sm="2"
              md="2"
            >
              <label class="font-weight-bold">DDBB NAME</label><br />
              <span>{{ editItem.ddbb_name }}</span>
            </v-col>
            <v-col
              cols="3"
              sm="3"
              md="3"
            >
              <label class="font-weight-bold">ACCOUNT MANAGER</label>
              <v-btn
                class="ml-2"
                icon
                small
                @click="editUsers()"
              >
                <v-icon
                  color="tvf_color"
                >
                  mdi-plus
                </v-icon>
              </v-btn><br />
              <div
                v-for="(name, index) in editItem.managers"
                :key="index"
              >
                <span v-if="name.use === 'manager'">{{ name.full_name }}</span>
              </div>            
            </v-col>
          </v-row>
          <v-row class="pl-10 pb-6">
            <v-col
              cols="2"
              sm="2"
              md="2"
            >
              <label class="font-weight-bold">From Name</label><br />
              <v-text-field
                class="text caption"
                v-model="editItem.from_name"
                background-color="#F8F9FB"
                dense
                solo
                flat
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="3"
              sm="3"
              md="3"
            >
              <label class="font-weight-bold">From Email</label><br />
              <v-text-field
                class="text caption"
                v-model="editItem.from_email"
                background-color="#F8F9FB"
                dense
                solo
                flat
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="2"
              sm="2"
              md="2"
            >
              <label class="font-weight-bold">Reply Name</label><br />
              <v-text-field
                class="text caption"
                v-model="editItem.reply_name"
                background-color="#F8F9FB"
                dense
                solo
                flat
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="3"
              sm="3"
              md="3"
            >
              <label class="font-weight-bold">Reply Email</label><br />
              <v-text-field
                class="text caption"
                v-model="editItem.reply_email"
                background-color="#F8F9FB"
                dense
                solo
                flat
              >
              </v-text-field>
            </v-col>                      
          </v-row>
          <v-divider
            class="ml-8"
            width="90%"
          ></v-divider>
          <v-row class="pt-4 pl-10 pr-16">
            <label
              class="caption font-weight-bold pl-3 py-4"
            >{{ editItem.field_title }}</label><br />
          </v-row>
          <v-row class="pb-6 pl-10 pr-16">
            <v-col
              v-for="input, index in editItem.fields"
              :key="index"
              cols="4"
              sm="4"
              md="4"
            >
              <label class="font-weight-bold">{{ input.display_name }}</label><br />
              <v-text-field
                class="text caption"
                v-model="input.value"
                required
                background-color="#F8F9FB"
                dense
                solo
                flat
              ></v-text-field>
            </v-col>
          </v-row>
          <v-divider
            class="ml-8"
            width="90%"
          ></v-divider>
          <div class="d-flex pt-6 pb-2">
            <v-col
              cols="7"
              sm="7"
              md="7"
            >
              <v-row class="pl-6">
                <v-col
                  cols="6"
                  sm="6"
                  md="6"
                >
                  <span class="font-weight-bold pl-1">DOMAINS</span>
                </v-col>
                <v-col
                  cols="3"
                  sm="3"
                  md="3"
                >
                  <span class="font-weight-bold">EXPIRE AT</span>
                </v-col>
                <v-col
                  cols="2"
                  sm="2"
                  md="2"
                >
                  <span class="font-weight-bold">PRIORITY</span>
                </v-col>
                <v-col
                  cols="1"
                  sm="1"
                  md="1"
                >
                  <v-btn 
                    icon 
                    small
                    @click="addDomains()"
                  >
                    <v-icon 
                      color="tvf_color"     
                    >   
                      mdi-plus             
                    </v-icon>
                  </v-btn>
                </v-col> 
              </v-row>
              <v-row     
                v-for="(item, index) in editItem.domains"
                :key="`fieldInput-${index}`"         
                class="pl-6"
              >
                <v-col
                  cols="6"
                  sm="6"
                  md="6"
                >
                  <v-autocomplete
                    class="text pl-1 pr-2 caption"
                    v-model="item.domain"
                    :items="domainsList"
                    item-value="item.id"
                    item-text="domain"
                    background-color="#F8F9FB"
                    dense
                    solo
                    flat
                    @change="ifChange(item, index)"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  class="d-flex"
                  cols="3"
                  sm="3"
                  md="3"
                >
                  <span class="pt-2">{{ item.expire_at }}</span>
                </v-col>
                <v-col
                  class="d-flex"
                  cols="2"
                  sm="2"
                  md="2"
                >
                  <v-select 
                    class="caption"
                    v-model="item.priority"
                    :items="['0','1','2','3','4','5']"
                    background-color="#F8F9FB"
                    dense
                    solo
                    flat
                  ></v-select>
                </v-col>
                <v-col 
                  class="d-flex"
                  cols="1"
                  sm="1"
                  md="1"
              >                    
                <v-btn 
                  type="submit"
                  icon
                  small
                  class="addButton mt-1"
                  @click="removeDomains(index)" 
                >
                  <v-icon      
                    color="tvf_color"
                  >   
                    mdi-delete             
                  </v-icon>
                </v-btn>
              </v-col>
              </v-row>
            </v-col>        
            <v-divider
              class="pl-8"
              width="90%"
              vertical
            ></v-divider>
            <v-col
              class="pl-8"
              cols="6"
              sm="6"
              md="6"
            >
              <v-row>
                <v-col
                  cols="4"
                  sm="4"
                  md="4"
                >
                  <span class="font-weight-bold">OWNERS</span>
                  <v-btn 
                    icon 
                    small
                    @click="addOwners()"
                  >
                    <v-icon 
                      color="tvf_color"     
                    >   
                      mdi-plus             
                    </v-icon>
                  </v-btn>
                </v-col> 
              </v-row>            
              <v-row>
                <v-col
                  cols="8"
                  sm="8"
                  md="8"
                >  
                  <div
                    v-for="(item, index) in editItem.owners"
                    :key="`fieldInput-${index}`"
                    class="d-flex"
                  >              
                    <v-autocomplete
                      class="text pr-2 caption"
                      v-model="item.name"
                      :items="ownersList"
                      item-value="item.id"
                      item-text="name"
                      background-color="#F8F9FB"
                      dense
                      solo
                      flat
                    ></v-autocomplete>             
                    <v-btn 
                      type="submit"
                      icon
                      small
                      class="addButton mt-1"
                      @click="removeOwners(index)" 
                    >
                      <v-icon      
                        color="tvf_color"
                      >   
                        mdi-delete             
                      </v-icon>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </div>
        </v-form>  
      </div>
    </v-card>    
    <v-dialog
      v-model="showUser"
      :value="true"
      persistent
      @click:outside="showUser = false"
      max-width="550px"
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle bold pa-4">Users: {{ editItem.name }}</span>
          <v-spacer></v-spacer>
          <v-icon
            @click="showUser = false"
            class="mr-2"
          >
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider
          class="mx-6"
          width="90%"
        ></v-divider>
        <div class="d-flex py-6">
          <v-col
            class="pl-12"
            cols="12"
            sm="6"
            md="6"
          >
            <v-row class="pl-7">
              <v-col                
                cols="9"
                sm="9"
                md="9"
              >
                <span class="subtitle-2 font-weight-black pl-4">MANAGER</span>
              </v-col>
            </v-row>
            <div
              v-for="item, index in editItem.managers"
              :key="index"
            >
              <v-row
                v-if="item.use === 'manager'"         
                class="pl-7"
              >
                <v-col       
                  cols="9"
                  sm="9"
                  md="9"
                >
                  <v-icon
                    small
                  >
                    mdi-circle-small
                  </v-icon>
                  <span class="text-capitalize">{{ item.full_name }}</span>
                </v-col>
              </v-row>
            </div>
          </v-col>
          <v-col     
            class="pl-6"     
            cols="12"
            sm="6"
            md="6"
          >
            <v-row class="pl-6 pr-9">
              <v-col
                cols="9"
                sm="9"
                md="9"
              >
                <span class="subtitle-2 font-weight-black pl-4">VIEWER</span>
              </v-col>
            </v-row>
            <div
              v-for="item, index in editItem.managers"
              :key="index"
            >
              <v-row   
                v-if="item.use === 'viewer'"             
                class="pl-6 pr-9"
              >
                <v-icon
                  small
                >
                  mdi-circle-small
                </v-icon>
                <v-col
                  class="d-flex justify-space-between"
                  cols="9"
                  sm="9"
                  md="9"
                >
                  <span class="text-capitalize">{{ item.full_name }}</span>
                </v-col>
              </v-row>
            </div>            
          </v-col>
        </div>        
      </v-card>
    </v-dialog>
    <v-dialog
      class="owner_dialog"
      v-model="showOwner"
      :value="true"
      persistent
      @click:outside="showOwner = false"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle bold pa-4">Owners: {{ editItem.name }}</span>
          <v-spacer></v-spacer>
          <v-icon
            @click="showOwner = false"
            class="mr-2"
          >
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider
          class="mx-8"
          width="90%"
        ></v-divider>
        <v-row class="py-6">
          <v-col
            class="pl-16"
            cols="4"
          >
            <v-list>
              <v-toolbar-title class="subtitle-2 font-weight-bold pl-8 pb-2">ID</v-toolbar-title>
              <v-list-item
                v-for="item, index in editItem.owners"
                :key="index"
              >
                <v-list-item-content>
                  <v-list-item-title class="subtitle-2 pl-4">{{ item.id }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col cols="7">
            <v-list>
              <v-toolbar-title class="subtitle-2 font-weight-bold pl-4 pb-2">NAME</v-toolbar-title>
              <v-list-item
                v-for="item, index in editItem.owners"
                :key="index"
              >
                <v-list-item-content>
                  <v-list-item-title class="subtitle-2">{{ item.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="editUser"
      :value="true"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle bold pa-4">ASSIGN USERS</span>
          <v-spacer></v-spacer>
          <v-icon
            class="mr-2"
            @click="editUser = false"
          >
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider class="ml-8" width="90%"></v-divider>
        <v-card-text>
          <br>
          <v-form>
            <v-text-field
              v-model="search"
              class="search-input mx-5 caption"
              label="Search user"
              prepend-inner-icon="mdi-magnify"
              clearable
              background-color="#F8F9FB"
              dense
              solo
              flat
            ></v-text-field>
          </v-form>        
          <v-container>
            <v-col>
              <v-row class="px-2">
                <v-col
                  cols="12"
                >
                  <v-data-table
                    v-model="usersTable"
                    v-if="!loading"
                    :headers="headers"
                    :items="users"
                    :item-key="item.id"
                    :search="search"
                  >
                    <LoadingSpinner v-if="!loading" />
                    <template 
                      v-slot:[`item.viewer`]="{ item }"
                    >
                      <v-sheet id="switch">
                      <v-switch
                        v-model="item.use"
                        class="pl-6"
                        color="tvf_color"
                        value="viewer"
                        :disabled='item.use  === "manager"'
                      ></v-switch>
                      </v-sheet>
                    </template>
                    <template 
                      v-slot:[`item.manager`]="{ item }"
                    >
                      <v-sheet id="switch">
                      <v-switch
                        v-model="item.use"
                        class="pl-6"
                        color="tvf_color"
                        value="manager"
                        :disabled='item.use === "viewer"'
                      ></v-switch>
                      </v-sheet>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-col>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn
            type="submit"
            class="addButton pa-6 mb-6 ml-6 white--text caption"
            color="#BDBDBD"
            @click="editUser = false"
          >
            <v-icon
              dark
              left
            >
              mdi-arrow-left
            </v-icon>
          Back
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            type="submit"
            class="addButton pa-6 mb-6 mr-6 white--text caption"
            color="tvf_color"
            @click="saveUser"
          >
          Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="head_footModal"
      :value="true"
      persistent
      @click:outside="head_footModal = false"
      max-width="900px"
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle text-capitalize pa-4">{{ title }}</span>
          <v-spacer></v-spacer>
          <v-icon
            class="mr-2"
            @click="head_footModal = false"
          >
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider
          class="ml-8"
          width="93%"
        ></v-divider>
        <v-card-text>
          <v-container>
            <v-row class="pl-4 pt-6">
              <v-col
                cols="4"
                sm="4"
                md="4"
              >
                <label class="caption font-weight-bold">NAME</label>
                <v-text-field 
                  class="caption"
                  v-model="hf_modal.name"
                  background-color="#F8F9FB"
                  dense
                  solo
                  flat
                >
                </v-text-field>
              </v-col>
            </v-row>
            <!-- Or manually control the data synchronization -->
            <label class="caption font-weight-bold pl-4">CODE</label>
            <v-textarea
              class="caption px-4"
              v-model="hf_modal.code"
              background-color="#F8F9FB"
              dense
              solo
              flat
              color="deep-purple"
              rows="4"
            ></v-textarea>
            <label class="caption font-weight-bold pl-4">
              PREVIEW
            </label>
            <div v-preview="hf_modal.code" class="caption preview pt-4 text-center"></div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              type="submit"
              class="addButton pa-6 mb-8 mr-8 white--text caption"
              color="tvf_color"
              @click="saveCode(hf_modal)"
          >
              Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <open-preview
      v-if="showPreview"
      @close="showPreview = false"
      :showItem="showItem"
    ></open-preview>
  </v-row>
</template>

<script>
import ApiService from "@/services/api.service";
import LoadingSpinner from "@/components/core/LoadingSpinner";
import OpenPreview from "@/components/database/Preview";

export default {
  props: ["editItem"],
  components: {
    LoadingSpinner,
    OpenPreview
  },
  data() {
    return {
      indice: -1,
      company_id: 1,
      addUser: false,
      finish_date: false,
      loading: false,
      showUser: false,
      showOwner: false,
      showPreview: false,
      editDatabase: false,
      editUser: false,            
      head_footModal: false,
      addBtn: false,
      editBtn: false,
      user: [],
      users: [],
      managers: [],
      usersTable: [],
      fields: [],
      field_value: [],
      platforms: [],
      countries: [],
      domainsList: [],
      ownersList: [],
      domains_final: [],
      owners_final: [],
      head_foot: [],
      hf_modal: {
        database_id: "",
        type: "",
        name: "",
        code: ""        
      },
      item: {},
      showItem: {},
      search: "",
      title: "",
      loading: false,
      isFormValid: false,
      viewer: false,
      manager: false,
      headers: [
        {
          text: "NAME",
          align: "start",
          value: "full_name",
          sorteable: false,
        },
        {
          text: "VIEWER",
          align: "center",
          value: "viewer",
        },
        {
          text: "MANAGER",
          value: "manager",
          align: "center",
        },
      ],
      ownersHeaders: [
        {
          align: "start",
          value: "id",
          sortable: true,
        },
        {
          align: "start",
          value: "name",
          sortable: true,
        },
      ],
    };
  },

  mounted() {
    this.getDomains()
    this.getOwners()
    this.getUsers()
  },

  methods: {

    addHeader() {
      this.title = 'Add header';
      this.databaseID = this.editItem.id
      this.hf_modal = {
        database_id: "",
        type: "header",
        name: "",
        code: ""        
      },
      this.addBtn = true
      this.editBtn = false
      this.head_footModal = true
    },

    addFooter() {
      this.title = 'Add footer';
      this.databaseID = this.editItem.id
      this.hf_modal = {
        database_id: "",
        type: "footer",
        name: "",
        code: ""        
      },
      this.addBtn = false
      this.editBtn = true
      this.head_footModal = true
    },

    editHeadFoot(index, item) {
      this.title = 'Edit ' + this.editItem.head_foot[0].type + ': ' + this.editItem.head_foot[0].name;
      this.indice = index;
      this.hf_modal = {
        type: this.editItem.head_foot[0].type,
        name: this.editItem.head_foot[0].name,
        code: this.editItem.head_foot[0].code,
        database_id: this.databaseID     
      },
      this.addBtn = true
      this.editBtn = false
      this.head_footModal = true
    },

    editUsers() {
     
      this.editUser = true;
      this.users.forEach((value) => {
        this.editItem.managers.forEach((value2) => {
          if(value.id === value2.id && value2.use === 'viewer') {
            this.users[value.id - 1].use = 'viewer'
          }
          if(value.id === value2.id && value2.use === 'manager') {
            this.users[value.id - 1].use = 'manager'
          }
        })
      })
    },

    saveUser() {
      this.usersTable = []
      this.user = this.users.filter(item => item.use)
      this.user.forEach((value) => {
          this.usersTable.push({id: value.id, use: value.use})
      })
      this.editUser = false;
    },

    showUsers() {
      this.managers = this.editItem.managers;
      this.showUser = true;
    },

    showOwners() {
      this.showOwner = true;
    },

    addDomains(){
      this.editItem.domains.push({})
    },

    addOwners(){
      this.editItem.owners.push({})
    },

    removeDomains(index) {
      this.editItem.domains.splice(index, 1)
    },

    removeOwners(index) {
      this.editItem.owners.splice(index, 1)
    },

    removeHeadFoot(index){
      this.editItem.head_foot.splice(index, 1)
    },

    preview(item) {
      this.showItem = {
        id: item.id,
        database_id: item.database_id,
        name: item.name,
        code: item.code,
        type: item.type
      }
      this.showPreview = true;
    },

    close(item) {
      this.$emit('close');    
      this.closeItem = false
      this.$store.dispatch("getDatabases");
      this.$emit('showItem');
    },

    onChange(platform) {
      this.$store.state.platforms.forEach(element => {
        if(platform === element.name) {
          this.editItem.platform_id = element.id
          this.editItem.fields = JSON.parse(element.fields)
        }
      })
    },

    ifChange(item,index) {
      this.editItem.domains[index].domain = item.domain
      this.editItem.domains[index].expire_at = item.expire_at
      this.domainsList.forEach(element => {
        if(element.domain === item.domain){
          item.id = element.id
          item.expire_at = element.expire_at
          item.priority = element.priority
        }
      })    
    },

    getDomains() {
      ApiService.get("domains/list")
      .then((response) => {
        const domainsList = response.data.data;  
        this.domainsList = domainsList.sort(function (a, b) {
            if (a.priority < b.priority) {
              return 1;
            }
            if (a.priority > b.priority) {
              return -1;
            }
           // a must be equal to b
            return 0;
          });   
      })
      .catch((error) => {
        console.log("Ups! Something is wrong");
      });
    },

    getOwners(){
      ApiService.get("owners/list")
      .then((response) => {
        const ownersList = response.data.data;  
        this.ownersList = ownersList.sort(function (a, b) {
            if (a.id < b.id) {
              return 1;
            }
            if (a.id > b.id) {
              return -1;
            }
           // a must be equal to b
            return 0;
          });   
      })
      .catch((error) => {
        console.log("Ups! Something is wrong");
      });
    },

    getUsers() {

      ApiService.get("users/list")
        .then((response) => {
          this.users = response.data.data            
        })
        .catch((error) => {
          console.log("Ups! Something is wrong");
        });
    },

    saveCode() {
      if(this.indice == -1) {
        this.editItem.head_foot.push({
          database_id: this.database_id,
          name: this.hf_modal.name,
          type: this.hf_modal.type,
          code: this.hf_modal.code,
      });
      }else {
        this.editItem.head_foot[this.indice] = {
          database_id: this.database_id,
          name: this.hf_modal.name,
          type: this.hf_modal.type,
          code: this.hf_modal.code,
        }
      }  
      this.head_footModal = false;
    },

    saveDatabase() {
      this.domains_final = []
      this.owners_final = []
      this.editItem.domains.forEach(element => {
        this.domains_final.push({id: element.id, priority: element.priority})
      })

      this.editItem.owners.forEach(element => {
        this.owners_final.push({id: element.id})
      })
      
      ApiService.put("databases/" + this.editItem.id, {
        platform_id: this.editItem.platform_id,
        country_id: this.editItem.country_id,
        name: this.editItem.name,
        name_bdd: this.editItem.ddbb_name,
        company_id: this.editItem.company_id,
        reply_email: this.editItem.reply_email,
        reply_name: this.editItem.reply_name,
        from_email: this.editItem.from_email,
        from_name: this.editItem.from_name,
        platform_fields: JSON.stringify(this.editItem.fields),
        domains: this.domains_final,
        owners: this.owners_final,
        users: this.usersTable
      })
      .then((response) => {
        this.editDatabase = false;
        this.$store.dispatch("getDatabases");
        this.$emit('close');
        this.$emit('showItem');
        this.$store.dispatch("setSnackbar", {
          color: "success",
          text: "Database has been updated!",
          timeout: 3000,
        });
      })
      .catch((error) => {
        if (typeof error.response.data === "object") {
          Object.values(error.response.data.errors)
            .flat()
            .forEach((error) => {
              this.$store.dispatch("setSnackbar", {
                color: "tvf_secondary",
                text: error,
                timeout: 3000,
              });
            });
        }
      });

    /*   this.editItem.head_foot.forEach((element) => {
        ApiService.put("hf/" + element.id,  {
          database_id: this.editItem.id,
          name: element.name,
          code: element.code,
          type: element.type,
        })
        .then((response) => {
          this.$store.dispatch('setSnackbar', {
              color: 'success',
              text: 'Header or footer added to "' + this.name + '"',
              timeout: 3000
          })
        })
        .catch((error) => {
          if (typeof error.response.data === 'object') {
            Object.values(error.response.data.errors).flat().forEach( error => {
            console.log(error)              
            this.$store.dispatch('setSnackbar', {
                color: 'tvf_secondary',
                text: error,
                timeout: 3000
            })       
          })}
        });
      }); */
      this.editDatabase = false;

    },

    disabledItem() {
      ApiService.patch("databases/status/" + this.editItem.id)
        .then((response) => {
          if (response.data.data === true) {
            this.$store.dispatch("setSnackbar", {
              color: "success",
              text: "Database is active!",
              timeout: 3000,
            });
            this.$store.dispatch("getDatabases");
          } else {
            this.$store.dispatch("setSnackbar", {
              color: "success",
              text: "Database has been disabled",
              timeout: 3000,
            });
            this.$store.dispatch("getDatabases");
          }
        })
        .catch((error) => {
          if (typeof error.response.data === "object") {
            Object.values(error.response.data.errors)
              .flat()
              .forEach((error) => {
                this.$store.dispatch("setSnackbar", {
                  color: "tvf_secondary",
                  text: error,
                  timeout: 3000,
                });
              });
          }
        });
    },
  },
};
</script>

<style>
span,
label,
.text {
  font-size: 0.8rem !important;
  font-weight: 400;
}

.form-modal {
  width: 100%;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}

.row + .row {
  margin-top: 0px;
}

.text-subtitle {
  font-size: 1.25rem !important;
}
</style>