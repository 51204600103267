<template>
  <div>
    <v-container>        
      <v-row>
        <v-col
          cols="12"
          md="6"
          sm="6"
          class="paymodel-card pl-2"
        >
          <v-sheet
            class="card-table"
            rounded="lg"
          >
            <LoadingSpinner v-if="loading" />
            <v-btn 
              class="addButton ml-8 my-10 white--text"
              color="tvf_color"  
              @click="openCreatePaymodel()"
            >
              Add new
            </v-btn>
            <h4 class="d-flex justify-center">PAYMODELS</h4>
            <v-data-table
              class="paymodelsList px-10"
              v-if="!loading"
              :headers="paymodelsHeaders"
              :items="paymodels"
              :search="search"
              :page.sync="page2"
              :items-per-page="itemsPerPage"
              @page-count="pageCount2 = $event"
            >
              <template v-slot:[`item.actions`]="{ item }">
                <v-btn
                  icon          
                >
                  <v-icon
                    @click="openEditPaymodel(item)"
                  > 
                    mdi-pencil-outline
                  </v-icon>
                </v-btn>
              </template>
            </v-data-table>
            <div class="pagination py-8 pl-10">
              <v-pagination
                v-model="page2"
                :length="pageCount2"
                color="tvf_color"                
              ></v-pagination>
            </div>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
    <new-paymodel
      v-if="addPaymodel"
      @close="closeCreateModal"
      @updateList="addPaymodels"
    />
    <edit-paymodel
      v-if="editedPaymodel"
      @close="closeCreateModal"
      :editPaymodel="editPaymodel"
      @updateList="addPaymodels"
    />
  </div>
</template>

<script>
import ApiService from "../services/api.service";
import LoadingSpinner from "../components/core/LoadingSpinner";
import NewPaymodel from "../components/tags_paymodels/NewPaymodel";
import EditPaymodel from "../components/tags_paymodels/EditPaymodel";

export default {
  props: ['search'],
  name: 'Tags_Paymodels',
  components: {
    LoadingSpinner,
    NewPaymodel,
    EditPaymodel
  },
  data() {
    return {
      page1: 1,
      page2: 1,
      pageCount1: 0,
      pageCount2: 0,
      itemsPerPage: 10,
      loading: false,
      addPaymodel: false,
      editedPaymodel: false,
      editPaymodel: {},
      paymodels: [],
      errors: [],
      paymodelsHeaders: [
        {
          text: "PAYMODELS",
          align: "start",
          value: "name",
          sortable: true,          
        },
        {
          text: "ACTIONS",
          align: "center",
          value: "actions",
          sortable: false,
        },
      ]
    }
  },

  mounted() {
    this.addPaymodels()
  },

  methods: {

    openCreatePaymodel() {
      this.addPaymodel = true
    },

    openEditPaymodel(item) {
      debugger
      this.editPaymodel = {
        id: item.id,
        name: item.name,
      },
      this.editedPaymodel = true;
    },

    closeCreateModal() {
      this.errors = []
      this.addTag= false
      this.addPaymodel = false
      this.editedTag = false
      this.editedPaymodel = false
    },

    addPaymodels() {
      this.loading = true //the loading begin

      ApiService.get("paymodels/list")
        .then((response) => {
          this.loading = false //the loading stop when the response given from server
          const paymodels = response.data.data;  
          this.paymodels = paymodels.sort(function (a, b) {
            if (a.name > b.name) {
              return 1;
            }
            if (a.name < b.name) {
              return -1;
            }
            // a must be equal to b
            return 0;
          });                   
        })
        .catch((error) => {
          this.loading = false
          console.log("Ups! Something is wrong");
        });
    }
  }

};
</script>

<style scoped>
p {
  font-family: Poppins;
  font-size: 16px;
  font-weight: bold;
}
.options {
  font-family: Poppins;
  font-size: 14px;
  font-weight: bold;
}

.paymodel-card{
  margin: auto;
}

.tagcard {
    max-width: 600px;
}

.v-card__title {
  flex-wrap: nowrap;
}

@media (max-width: 600px) {
  .v-application .pl-2{
    padding-left: 0 !important;
  }
  .paymodel-card {
    padding-top: 6px;
  }
  .paymodelsList, .tagsList {
    padding-bottom: 20px;
  }
}

@media (max-width: 1300px) {
  .container{
    max-width: 1000px !important;
  }
  .card-table {
    min-height: 700px;
  }

  .v-data-footer__select {
    margin-right: 0px !important;
  }
}

</style>