<template>
  <div>
    <v-card class="mt-3">
      <LoadingSpinner v-if="this.$store.state.loading" />
      <v-btn
        class="addButton ml-8 my-10 white--text"
        color="tvf_color"
        @click="openCreateModal"
      >
        Add new
      </v-btn>
      <v-data-table
        class="userList px-10"
        v-if="!this.$store.state.loading"
        :headers="headers"
        :items="this.$store.state.users"
        :search="search"
        :items-per-page="itemsPerPage"
        :page.sync="page"
        @page-count="pageCount = $event"
        show-expand
        single-expand
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon>
            <v-icon
              v-model="item.actions"
              @click="openEditModal(item)"
              color="black"
            >
              mdi-pencil-outline
            </v-icon>
          </v-btn>
          <toggle-button 
            :value="item.status"
            v-model="item.status"
            switchColor='#FFF'
            :color="{unchecked:'#E5E5E5', checked: '#F85182'}"
            @change="openConfirmation(item)"
            :width='23'
            :height='13'
          />
        </template>
        <template
          v-slot:[`item.data-table-expand`]="{ item, isExpanded, expand }"
        >
          <v-icon
            v-if="item.databases.length > 0"
            :class="[
              'v-data-table__expand-icon',
              { 'v-data-table__expand-icon--active': isExpanded },
            ]"
            @click.stop="expand(!isExpanded)"
            >$expand</v-icon
          >
        </template>
        <template
          v-slot:expanded-item="{ headers, item }"
        >
          <td :colspan="headers.length"
            v-if="item.databases.length > 0"
          >
            <h4 class="ml-6 mt-6 mb-2">
              DATABASES
            </h4>
            <v-simple-table class="px-12 py-4">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left font-weight-black text-uppercase">
                      Name
                    </th>
                    <th class="text-left font-weight-black text-uppercase">
                      Viewer
                    </th>
                    <th class="text-left font-weight-black text-uppercase">
                      Manager
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="info in item.databases"
                    :key="info.id"
                  >
                    <td>{{ info.name }}</td>
                    <td>
                      <v-icon v-if="info.use === 'viewer'" class="ml-2" color="green">mdi-check</v-icon>
                    </td>
                    <td><v-icon v-if="info.use === 'manager'" class="ml-2" color="green">mdi-check</v-icon></td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </td>
        </template>
      </v-data-table>
      <v-dialog
        v-model="confirmation"
        :value="true"
        persistent
        @click:outside="confirmation = false"
        max-width="400px"
      >
        <v-card>
          <v-container>
            <v-col>
              <v-row class="pa-4">
                <v-col
                  class="align-center"
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <h4>{{ name }}</h4>
                  <p class="body-2 mt-4">Are you sure you want to disable this user?</p>
                </v-col>
              </v-row>
              <v-row class="px-4 pb-4">
                <v-col
                  class="align-center"
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-btn
                    class="addButton pa-6 mr-8 white--text"
                    block
                    color="tvf_color"
                    @click="disabledItem()"
                  >
                  Disable
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-container>
        </v-card>
      </v-dialog>
      <new-user
        v-if="this.$store.state.addDialog"
        @close="closeCreateModal"
        @updateList="addUsers"
      />
      <edit-user
        v-if="editUser"
        @close="editUser = false"
        :editedItem="editedItem"
        @updateList="addUsers"
      />
    </v-card> 
    <div class="py-4 pl-4">
      <v-pagination
        v-model="page"
        :length="pageCount"
        color="#f5f5f5"
      ></v-pagination>
    </div>
  </div>   
</template>

<script>
import ApiService from "../services/api.service";
import LoadingSpinner from "../components/core/LoadingSpinner";
import NewUser from "../components/user/NewUser.vue";
import EditUser from "../components/user/EditUser.vue";

export default {
  props: ['search'],
  name: "Users",
  components: {
    LoadingSpinner,
    NewUser,
    EditUser
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      editUser: false,
      loading: false,
      confirmation: false,
      users: [],     
      editedIndex: -1,
      editedItem: {},
      role: '',
      name: '',
      itemId: '',
      singleExpand: false,
      headers: [
        {
          text: "NAME",
          align: "start",
          value: "full_name",
          sortable: true,
        },
        {
          text: "USERNAME",
          align: "start",
          value: "username",
          sortable: true,
        },
        {
          text: "ROLE",
          value: "roles[0].name",
          sortable: true,
        },
        {
          text: "EMAIL",
          value: "email",
          sortable: false,
        },
        {
          text: "COMPANY",
          value: "company.name",
          sortable: true,
        },
        {
          text: "ACTIONS",
          align: "center",
          value: "actions",
          sortable: false,
          width: '10%'
        },
      ],
    };
  },

  mounted() {
    this.$store.commit('getUsers')
  },

  methods: {
    filterOnlyCapsText (value, search, item) {
      return value != null &&
        search != null &&
        typeof value === 'string' &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
    },

    openConfirmation(item){
      if(item.status === false) {
        this.confirmation = true
        this.name = item.full_name
        this.itemId = item.id
      }
      else {
        ApiService.patch("users/status/" + item.id)
        .then((response) => {
          this.$store.commit('getUsers')
          this.$store.dispatch('setSnackbar', {
            color: 'success',
            text: 'User is active!',
            timeout: 3000
          })
        })
      }
    },

    openCreateModal() {
      this.$store.state.addDialog = true
    },

    closeCreateModal() {
      this.$store.state.errors = []
      this.$store.state.addDialog = false
    },

    openEditModal(item) {
      this.editedItem = {
        id: item.id,
        username: item.username,
        role: item.roles[0].name,
        first_name: item.first_name,
        last_name: item.last_name,
        email: item.email,
        company_id: item.company_id,
        databases: item.databases,
      },
      this.editUser = true
    },

    addUsers() {
      this.$store.commit('getUsers')
    },

    disabledItem() {
      ApiService.patch("users/status/" + this.itemId)
        .then((response) => {
          this.confirmation = false
          this.$store.commit('getUsers')
          this.$store.dispatch('setSnackbar', {
            color: 'success',
            text: 'User has been disabled',
            timeout: 3000
        })
        .catch((error) => {
          console.log("Ups! Something is wrong");
        });
      })
    }
  },
};
</script>

<style scoped>

.spacer {
  width: 50%;
}

.userList .mdi-chevron-down::before {
  content: "\F419" !important;
  color: #000;
}

#switch > .theme--light.v-sheet{
  background-color: rgba(255, 255, 255, 0) !important;
}
</style>