<template>
  <div>
    <v-container>        
      <v-row>
        <v-col
          class="tag-card"
          cols="12"
          md="6"
          sm="6"
        >
          <v-sheet
            class="card-table"
            rounded="lg"
          >
            <LoadingSpinner v-if="loading" />
            <v-btn 
              class="addButton ml-8 my-10 white--text"
              color="tvf_color"  
              @click="openCreateTag()"
            >
              Add new
            </v-btn>
            <h4 class="d-flex justify-center">TAGS</h4>
            <v-data-table
              class="tagsList px-10"
              v-if="!loading"
              :headers="tagsHeaders"
              :items="tags"
              :search="search"
              :page.sync="page1"
              :items-per-page.sync="itemsPerPage"
              @page-count="pageCount1 = $event"
            >
            </v-data-table>
            <div class="py-8 pl-10">
              <v-pagination
                v-model="page1"
                :length="pageCount1"
                color="tvf_color"
                :total-visible="5"
              ></v-pagination>
            </div>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
    <new-tag
      v-if="addTag"
      @close="closeCreateModal"
      @updateList="addTags"
    />
    <edit-tag
      v-if="editedTag"
      @close="closeCreateModal"
      :editTag="editTag"
      @updateTags="addTags"
    />
  </div>
</template>

<script>
import ApiService from "../services/api.service";
import LoadingSpinner from "../components/core/LoadingSpinner";
import NewTag from "../components/tags_paymodels/NewTag";
import EditTag from "../components/tags_paymodels/EditTag";

export default {
  props: ['search'],
  name: 'Tags',
  components: {
    LoadingSpinner,
    NewTag,
    EditTag
  },
  data() {
    return {
      page1: 1,
      page2: 1,
      pageCount1: 0,
      pageCount2: 0,
      itemsPerPage: 10,
      loading: false,
      editedTag: false,
      addTag: false,
      editTag: {},
      tags: [],
      errors: [],
      tagsHeaders: [
        {
          text: "TAGS",
          align: "start",
          value: "name",
          sortable: true,          
        },
      ],
    }
  },

  mounted() {
    this.addTags()
  },

  methods: {
    openCreateTag() {
      this.addTag = true
    },

    openEditTag(item) {
      this.editTag = {
        id: item.id,
        name: item.name,
      },
      this.editedTag = true;
    },

    closeCreateModal() {
      this.errors = []
      this.addTag= false
      this.editedTag = false
    },

    addTags() {
      this.loading = true //the loading begin

      ApiService.get("tags/list")
        .then((response) => {
          this.loading = false //the loading stop when the response given from server
          const tags = response.data.data;  
          this.tags = tags.sort(function (a, b) {
            if (a.name > b.name) {
              return 1;
            }
            if (a.name < b.name) {
              return -1;
            }
            // a must be equal to b
            return 0;
          });         
        })
        .catch((error) => {
          this.loading = false
          console.log("Ups! Something is wrong");
        });
    },
  }

};
</script>

<style scoped>
p {
  font-family: Poppins;
  font-size: 16px;
  font-weight: bold;
}
.options {
  font-family: Poppins;
  font-size: 14px;
  font-weight: bold;
}

.tag-card{
  margin: auto;
}

.tagcard {
    max-width: 600px;
}

.v-card__title {
  flex-wrap: nowrap;
}

@media (max-width: 600px) {
  .v-application .pl-2{
    padding-left: 0 !important;
  }

  .tagsList {
    padding-bottom: 20px;
  }
}

@media (max-width: 1300px) {
  .container{
    max-width: 1000px !important;
  }
  .card-table {
    min-height: 700px;
  }

  .v-data-footer__select {
    margin-right: 0px !important;
  }
}

</style>