<template>
  <div>
    <v-card>
      <v-container fluid class="align-start px-0 d-flex flex-row">
        <LoadingSpinner v-if="loading" />        
        <!-- LEFT MENU WITH FILTERS, DATATABLE WITH DATABASES AND SEARCH INPUT ----------------------------------------
        ---------------------------------------------------------------------------------
        ---------------------------------------------------------------------------------
        ----------------------------------------------------------------------------------
        ----------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------->
        <v-navigation-drawer
          class="ml-1"
          permanent
        >
          <v-list>
            <v-list-item class="justify-space-between">
              <v-btn 
                class="text-btn px-11 mx-1 my-4 tvf_color--text"
                color="#FFDDE7"  
                depressed
                @click="actives()"
              >
                Actives
              </v-btn>
              <v-btn 
                class="text-btn px-9 mx-1 my-4 tvf_color--text"
                color="#FFDDE7"  
                depressed
                @click="inactives()"
              >
                Inactives
              </v-btn>
            </v-list-item>

            <v-text-field
              v-model="search"
              class="px-5"
              outlined
              prepend-inner-icon="mdi-magnify"
              dense
              small
            ></v-text-field>         
            <v-list-item class="px-4 selects">
              <v-select 
                class="select-input caption px-1"
                placeholder="Country"
                :items="countries.map(({name}) => name)"
                item-value="id"
                dense
                outlined
              >
              </v-select>
              <v-select 
                class="select-input caption"
                placeholder="Platform"
                :items="platforms"
                item-value="id"
                item-text="name"
                dense
                outlined
              >
              </v-select>
              <v-select 
                class="select-input caption px-1"
                placeholder="Account"  
                :items="this.$store.state.users"
                item-value="id"
                item-text="first_name"
                dense
                outlined
              >
              </v-select>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-data-table
            :headers="headers"
            :items="sortedArray"
            :search="search"
            class="database-list caption px-6 py-6"
            height="480px"
            :items-per-page="-1"
            hide-default-header
            hide-default-footer
            @click:row="rowClick"
            :item-class="itemRowColor"
          >
          </v-data-table>
        </v-navigation-drawer>   
        <!-- FINAL LEFT MENU---------------------------------------------------------------------->
        <!-- BUTTON NEW AND SAVE DATABASE-------------------------------------------------------------------------------
        ---------------------------------------------------------------------------------
        ----------------------------------------------------------------------------------
        ----------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------->     
        <v-card flat class="grow" width="70%">
          <v-row class="d-flex flex-row-reverse">      
            <v-btn 
              v-if="!newDatabase && !editDatabase"
              class="addButton mx-10 mt-10 white--text"
              color="tvf_color"  
              @click="addDatabase()"
            >
              Add new
            </v-btn>            
            <v-btn
              v-if="newDatabase"
              type="submit"
              class="addButton mr-10 mt-10 white--text"
              color="tvf_color"
              @click="saveDatabase()"
            >
              Save
            </v-btn>
            <v-btn
              v-if="newDatabase"
              type="submit"
              class="addButton mx-5 mt-10 white--text"
              color="btn_secondary"
              @click="cancelSave()"
            >
              Cancel
            </v-btn>
          </v-row>
          <!-- FINAL BUTTON---------------------------------------------------------------------->
          <!-- TOP INFO-------------------------------------------------------------------------------
        ---------------------------------------------------------------------------------
        ----------------------------------------------------------------------------------
        ----------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------->
          <v-row class="d-flex flex-row justify-space-between pt-4 pr-8">
            <div>
              <h2 v-if="editDatabase === false || newDatabase === false" class="text-capitalize font-weight-bold pt-4 pl-12">{{ name }}</h2>              
              <v-spacer v-if="editDatabase || newDatabase"></v-spacer>
            </div>
            <div>              
              <v-btn 
                class="mr-4"
                icon 
                v-show="pencil && !newDatabase && !editDatabase"
                :disabled="deleted_at !== null" 
              >
                <v-icon          
                  @click="editDB()"
                >   
                  mdi-pencil-outline             
                </v-icon>
              </v-btn>
              <v-btn 
                class="pr-4"
                icon 
                v-show="editDatabase"
              >
                <v-icon            
                  @click="updateDatabase()"
                >   
                  mdi-content-save            
                </v-icon>
              </v-btn>
              <toggle-button 
                v-show="pencil && !newDatabase && !editDatabase"
                v-model="switch_status"                
                class="pr-4"
                :color="{unchecked: '#E5E5E5', checked: '#F85182'}"
                @change="disabledItem()"
                :width='23'
                :height='13'
              />
            </div>
          </v-row>
          <!-- FINAL TOP INFO---------------------------------------------------------------------->
          <!-- SHOW DATABASE INFO --------------------------------------------------------------------------------
        ---------------------------------------------------------------------------------
        ----------------------------------------------------------------------------------
        ----------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------->
        <div v-if="editDatabase">
          <v-row class="py-3 pl-10">
             <v-col
              cols="1"
              sm="1"
              md="1"
            >
              <label v-if="id !== ''" class="font-weight-bold">Id</label><br/>
              <span>{{ id }}</span>
            </v-col>
            <v-col
              cols="2"
              sm="2"
              md="2"
            >
              <label v-if="editDatabase === true" class="font-weight-bold">Name</label>
              <v-text-field 
                v-if="editDatabase"
                class="text"
                placeholder="Name"
                v-model="name"
                dense
                outlined
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="2"
              sm="2"
              md="2"
            >
              <label class="font-weight-bold" v-if="platform_name !== ''">Platform</label><br/>
              <v-autocomplete
                class="text"
                v-model="platform_name"
                :items="platforms"
                item-value="id"              
                item-text="name"
                @change="onChange(platform)"
                dense
                outlined
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="1"
              sm="1"
              md="2"
            >
              <label class="font-weight-bold" v-if="country !== '' || newDatabase">Country</label><br/>
              <v-autocomplete
                class="text"
                v-model="country"
                :items="countries"
                item-value="id"              
                item-text="name"
                dense
                outlined
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="2"
              sm="2"
              md="2"
            >
              <label class="font-weight-bold" v-if="ddbb_name !== '' || newDatabase">Ddbb Name</label><br/>
              <span class="">{{ ddbb_name }}</span>
            </v-col>
            <v-col              
              cols="2"
              sm="2"
              md="2"
            >
              <label class="font-weight-bold" v-if="account_manager.length > 0">Account manager</label>
              <v-btn 
                class="ml-2"
                icon 
                small
                @click="addUsers"  
                v-if="account_manager.length > 0"
              >
                <v-icon>   
                  mdi-plus             
                </v-icon>
              </v-btn>
              <br/>
            </v-col>
          </v-row>
        </div>
        <div v-else-if="newDatabase">
          <v-row class="py-3 pl-10">
             <v-col
              cols="2"
              sm="2"
              md="2"
            >
              <label v-if="newDatabase" class="font-weight-bold">Name</label><br/>
              <v-text-field
                v-model="name"
                class="text"
                required
                outlined
                dense              
              ></v-text-field>
            </v-col>
            <v-col
              cols="2"
              sm="2"
              md="2"
            >
              <label class="font-weight-bold">Platform</label><br/>
              <v-autocomplete
                class="text"
                v-model="platform_name"
                :items="platforms"
                item-value="id"              
                item-text="name"
                @change="onChange(platform)"
                dense
                outlined
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="2"
              sm="2"
              md="2"
            >
              <label class="font-weight-bold">Country</label><br/>
              <v-autocomplete
                class="text"
                v-model="country"
                :items="countries"
                item-value="id"              
                item-text="name"
                dense
                outlined
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="2"
              sm="2"
              md="2"
            >
              <label class="font-weight-bold pl-4">Ddbb Name</label><br/>
              <v-text-field
                v-model="ddbb_name"
                class="text pl-4"
                required
                outlined
                dense              
              ></v-text-field>
            </v-col>
            <v-col              
              cols="3"
              sm="3"
              md="3"
            >
              <label class="font-weight-bold">Account manager</label>
              <v-btn 
                class="ml-2"
                icon 
                small
                @click="showUsers()"  

                v-if="account_manager.length > 0"
              >
                <v-icon>   
                  mdi-eye             
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <div v-else>
          <v-row class="py-3 pl-10">
             <v-col
              cols="1"
              sm="1"
              md="1"
            >
              <label v-if="id !== ''" class="font-weight-bold" >Id</label><br/>
              <span>{{ id }}</span>
            </v-col>
            <v-col
              cols="3"
              sm="3"
              md="3"
            >
              <label class="font-weight-bold" v-if="platform_id !== ''">Platform</label><br/>
              <span>{{ platform_name }}</span>
            </v-col>
            <v-col
              cols="2"
              sm="2"
              md="2"
            >
              <label class="font-weight-bold" v-if="country !== ''">Country</label><br/>
              <span>{{ country }}</span>
            </v-col>
            <v-col
              cols="2"
              sm="2"
              md="2"
            >
              <label class="font-weight-bold pl-4" v-if="ddbb_name !== ''">Ddbb Name</label><br/>
              <span class="pl-4">{{ ddbb_name }}</span>
            </v-col>
            <v-col              
              cols="3"
              sm="3"
              md="3"
            >
              <label class="font-weight-bold" v-if="account_manager.length > 0 ">Account manager</label>
              <v-btn 
                class="ml-2"
                icon 
                small
                @click="showUsers()"  

                v-if="account_manager.length > 0"
              >
                <v-icon>   
                  mdi-eye             
                </v-icon>
              </v-btn>
              <br/>
              <span v-for="name,index in account_manager" :key="index">{{ name.manager }}</span>
            </v-col>
          </v-row>
        </div>
        <!-- FINAL level1---------------------------------------------------------------------->
          <!-- SHOW DATABASE INFO level 2-------------------------------------------------------------------------------
        ---------------------------------------------------------------------------------
        ----------------------------------------------------------------------------------
        ----------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------->
        <v-divider
          v-if="fields.length > 0 || newDatabase"
          class="ml-8"
          width="90%"
        ></v-divider>  
        <v-row class="pt-4 pl-10 pr-16">
          <label class="body-2 font-weight-bold pl-3 py-4" v-if="fields_title !== ''">{{ fields_title }}</label><br/>
        </v-row> 
        <div v-if="editDatabase">
          <v-row class="pb-4 pl-10 pr-16">
            <v-col
              v-for="field, index in fields" :key="index"
              cols="4"
              sm="4"
              md="4"
            >
              <label class="font-weight-bold">{{ field.display_name }}</label><br/>
              <v-text-field
                class="text"
                v-model="field.value"
                required
                outlined
                dense              
              ></v-text-field>                          
            </v-col>
          </v-row> 
        </div>  
        <div v-else-if="newDatabase">
          <v-row class="pb-4 pl-10 pr-16">
            <v-col
              v-for="field, index in fields" :key="index"
              cols="4"
              sm="4"
              md="4"
            >
              <label class="font-weight-bold">{{ field.display_name }}</label><br/>   
              <v-text-field
                class="text"
                v-model="field_value[index]"
                required
                outlined
                dense              
              ></v-text-field>                        
            </v-col>
          </v-row> 
        </div>   
        <div v-else>
          <v-row class="pb-4 pl-10 pr-16">
            <v-col
              v-for="field, index in fields" :key="index"
              cols="4"
              sm="4"
              md="4"
            >
              <label class="font-weight-bold">{{ field.display_name }}</label><br/>   
              <span>{{ field.value }}</span>                      
            </v-col>
          </v-row> 
        </div>        
        <!-- FINAL level2---------------------------------------------------------------------->
        <!-- SHOW DATABASE INFO level 3-------------------------------------------------------------------------------
        ---------------------------------------------------------------------------------
        ----------------------------------------------------------------------------------
        ----------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------->   
        <v-divider
          v-if="owners.length > 0 || newDatabase"
          class="ml-8 mb-3"
          width="90%"
        ></v-divider>   
        <div v-if="editDatabase" class="d-flex">
          <v-col    
            cols="7"
            sm="7"
            md="7"
          > 
            <v-row class="pl-6">
              <v-col
                cols="6"
                sm="6"
                md="6"
              >
                <span class="font-weight-bold pl-1">Domains</span>
              </v-col>                 
              <v-col
                cols="3"
                sm="3"
                md="3"
              >
                <span class="font-weight-bold">Expire at</span>
              </v-col> 
              <v-col
                cols="2"
                sm="2"
                md="2"
              >
                <span class="font-weight-bold">Priority</span>
              </v-col> 
              <v-col
                cols="1"
                sm="1"
                md="1"
              >
                <v-btn 
                  icon 
                  small
                >
                  <v-icon 
                    color="tvf_color"     
                  >   
                    mdi-plus             
                  </v-icon>
                </v-btn>
              </v-col>              
            </v-row>                
            <v-row            
              v-for="item, index in domains" :key="index"
              class="pl-6"
            >
              <v-col
                cols="6"
                sm="6"
                md="6"
              >
                <v-autocomplete
                  class="text pr-8 pl-1"
                  v-model="item.domain"
                  :items="domainsList"
                  item-value="id"              
                  item-text="domain"
                  dense
                  outlined
                  @change="ifChange(item, index)"
                ></v-autocomplete>
              </v-col>
              <v-col    
                class="d-flex"
                cols="3"
                sm="3"
                md="3"
              >
                <span class="pt-2">{{ item.expire_at }}</span>
              </v-col>
              <v-col    
                class="d-flex"
                cols="3"
                sm="3"
                md="3"
              >
                <v-text-field 
                  class="text pr-4"
                  type="number"
                  v-model="item.priority"
                  dense
                  outlined
                >
                </v-text-field>
                <v-btn 
                  class="pl-2"
                  icon 
                >
                  <v-icon      
                    color="tvf_color"
                  >   
                    mdi-delete             
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>   
          </v-col> 
          <v-divider
            class="pl-8"
            width="90%"
            vertical
          ></v-divider>             
          <v-col    
            class="pl-8"
            cols="6"
            sm="6"
            md="6"
          >
            <v-row>
              <v-col
                cols="3"
                sm="3"
                md="3"
              >
                <span class="font-weight-bold">Owners</span>
                <v-btn 
                  class="ml-2"
                  icon 
                  small
                  @click="showOwners()"  
                >
                  <v-icon>   
                    mdi-eye             
                  </v-icon>
                </v-btn>
              </v-col>             
            </v-row>             
            <v-row
              v-for="item, index in owners" :key="index"
            >
              <v-col    
                cols="7"
                sm="7"
                md="7"
              >
                <v-autocomplete
                  class="text pr-2"
                  v-model="item.name"
                  :items="ownersList"
                  item-value="id"              
                  item-text="name"
                  dense
                  outlined
                ></v-autocomplete>              
              </v-col>
            </v-row>           
          </v-col>
        </div>
        <div v-else-if="newDatabase" class="d-flex">
          <v-col    
            cols="7"
            sm="7"
            md="7"
          > 
            <v-row class="pl-6">
              <v-col
                cols="6"
                sm="6"
                md="6"
              >
                <span class="font-weight-bold pl-1">Domains</span>
              </v-col>                 
              <v-col
                cols="3"
                sm="3"
                md="3"
              >
                <span class="font-weight-bold">Expire at</span>
              </v-col> 
              <v-col
                cols="2"
                sm="2"
                md="2"
              >
                <span class="font-weight-bold">Priority</span>
              </v-col> 
              <v-col
                cols="1"
                sm="1"
                md="1"
              >
                <v-btn 
                  icon 
                  small
                >
                  <v-icon 
                    color="tvf_color"     
                  >   
                    mdi-plus             
                  </v-icon>
                </v-btn>
              </v-col>              
            </v-row> 
            <v-row class="pl-6">
              <v-col
                cols="6"
                sm="6"
                md="6"
              >
                <v-select 
                  class="text pr-6 pl-2"
                  v-model="domain"
                  :items="domainsList"
                  item-value="id"
                  item-text="domain"
                  dense
                  outlined
                >
                </v-select>
              </v-col>
              <v-col    
                class="d-flex"
                cols="6"
                sm="6"
                md="6"
              >
                <v-menu
                  v-model="finish_date"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="expire_at"
                      class="text edit-input"
                      readonly
                      outlined
                      dense
                      single-line
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="expire_at"
                    color="tvf_color"
                    @input="finish_date = false"
                  ></v-date-picker>
                </v-menu>
                <v-btn 
                  class="ml-2"
                  icon 
                >
                  <v-icon      
                    color="tvf_color"
                  >   
                    mdi-delete             
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>                 
            <v-row            
              v-for="item in domains" :key="item.id"
              class="pl-6"
            >
              <v-col
                cols="6"
                sm="6"
                md="6"
              >
                <v-autocomplete
                  class="text pr-8 pl-1"
                  v-model="item.domain"
                  :items="domainsList"
                  item-value="id"              
                  item-text="domain"
                  dense
                  outlined
                  @change="ifChange(item, index)"
                ></v-autocomplete>
              </v-col>
              <v-col    
                class="d-flex"
                cols="3"
                sm="3"
                md="3"
              >
                <span class="pt-2">{{ item.expire_at }}</span>
              </v-col>
              <v-col    
                class="d-flex"
                cols="3"
                sm="3"
                md="3"
              >
                <v-text-field 
                  class="text pr-4"
                  type="number"
                  v-model="item.priority"
                  dense
                  outlined
                >
                </v-text-field>
                <v-btn 
                  class="pl-2"
                  icon 
                >
                  <v-icon      
                    color="tvf_color"
                  >   
                    mdi-delete             
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>   
          </v-col> 
          <v-divider
            class="pl-8"
            width="90%"
            vertical
          ></v-divider>             
          <v-col    
            class="pl-8"
            cols="6"
            sm="6"
            md="6"
          >
            <v-row>
              <v-col
                cols="3"
                sm="3"
                md="3"
              >
                <span class="font-weight-bold">Owners</span>
                <v-btn 
                  class="ml-2"
                  icon 
                  small
                  @click="showOwners()"  
                >
                  <v-icon>   
                    mdi-eye             
                  </v-icon>
                </v-btn>
              </v-col>             
            </v-row>             
            <v-row>
              <v-col    
                cols="7"
                sm="7"
                md="7"
              >
                <v-autocomplete
                  class="text pr-2"
                  v-model="item.name"
                  :items="ownersList"
                  item-value="id"              
                  item-text="name"
                  dense
                  outlined
                ></v-autocomplete>   
              </v-col>
            </v-row>           
          </v-col>
        </div>
        <div v-if="owners.length > 0 && !editDatabase"  class="d-flex">
          <v-col    
            cols="7"
            sm="7"
            md="7"
          > 
            <v-row class="pl-6">
              <v-col
                cols="6"
                sm="6"
                md="6"
              >
                <span class="font-weight-bold pl-1">Domains</span>
              </v-col>                 
              <v-col
                cols="3"
                sm="3"
                md="3"
              >
                <span class="font-weight-bold">Expire at</span>
              </v-col> 
              <v-col
                cols="2"
                sm="2"
                md="2"
              >
                <span class="font-weight-bold">Priority</span>
              </v-col>             
            </v-row>                
            <v-row            
              v-for="item in domains" :key="item.id"
              class="pl-6"
            >
              <v-col
                cols="6"
                sm="6"
                md="6"
              >
                <span class="pl-1">{{ item.domain }}</span>
              </v-col>
              <v-col    
                class="d-flex"
                cols="3"
                sm="3"
                md="3"
              >
                <span class="pt-2">{{ item.expire_at }}</span>
              </v-col>
              <v-col    
                class="d-flex"
                cols="3"
                sm="3"
                md="3"
              >
                <span class="pt-1">{{ item.priority }}</span>
              </v-col>
            </v-row>                        
          </v-col> 
          <v-divider
            class="pl-8"
            width="90%"
            vertical
          ></v-divider>
          <v-col    
              class="pl-8"
              cols="6"
              sm="6"
              md="6"
            >
              <v-row>
                <v-col
                  cols="3"
                  sm="3"
                  md="3"
                >
                  <span class="font-weight-bold">Owners</span>
                  <v-btn 
                    class="ml-2"
                    icon 
                    small
                    @click="showOwners()"  
                  >
                    <v-icon>   
                      mdi-eye             
                    </v-icon>
                  </v-btn>
                </v-col>             
              </v-row>   
              <v-row>
                <v-col                  
                  cols="8"
                  sm="8"
                  md="8"
                >
                  <v-data-table
                    :headers="headers"
                    :items="owners"
                    :search="search"
                    class="caption"
                    height="90px"
                    :items-per-page="-1"
                    hide-default-header
                    hide-default-footer
                  >
                  </v-data-table>
                </v-col>                  
              </v-row>                        
            </v-col>
        </div>        
        <!-- FINAL level 3---------------------------------------------------------------------->
        <!-- SHOW HEADERS Y FOOTERS-------------------------------------------------------------------------------
        ---------------------------------------------------------------------------------
        ----------------------------------------------------------------------------------
        ----------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------->
          <v-divider
            v-if="head_foot.length > 0"
            class="ml-8 my-3"
            width="90%"
          ></v-divider>  
          <div class="d-flex pb-6" v-if="head_foot.length > 0">
            <v-col    
              cols="6"
              sm="6"
              md="6"
            > 
              <v-row class="pl-6 pr-9">
                <v-col
                  cols="9"
                  sm="9"
                  md="9"
                >
                  <span class="font-weight-bold">Header</span>
                </v-col>                  
                <v-col
                  v-if="editDatabase"
                  class="pl-6"
                  cols="1"
                  sm="1"
                  md="1"
                >
                  <v-btn 
                    icon 
                    small
                    @click="addHeader()"
                  >
                    <v-icon 
                      color="tvf_color"     
                    >   
                      mdi-plus             
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>                
              <v-row            
                v-for="item in head_foot" :key="item.id"
                class="pl-6 pr-9"
              >
                <v-col    
                  v-if="item.type === 'header'"
                  class="d-flex justify-space-between" 
                  cols="9"
                  sm="9"
                  md="9"
                >
                  <span class="text-capitalize">{{ item.name }}</span>
                </v-col>
                <v-col
                  v-if="item.type === 'header'"
                  class="pl-6 d-flex"
                  cols="1"
                  sm="1"
                  md="1"
                >
                  <v-btn 
                    v-if="editDatabase"  
                    icon 
                    small
                  >
                    <v-icon                         
                      @click="editHeadfoot(item)"
                    >   
                      mdi-pencil             
                    </v-icon>
                  </v-btn>
                  <v-btn 
                    icon 
                    small
                  >
                    <v-icon    
                      @click="preview(item)"  
                    >   
                      mdi-eye             
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>                
            </v-col>              
            <v-col    
              cols="6"
              sm="6"
              md="6"
            >
              <v-row class="pr-12">
                <v-col
                  cols="9"
                  sm="9"
                  md="9"
                >
                  <span class="font-weight-bold">Footer</span>
                </v-col>                  
                <v-col
                  v-if="editDatabase"
                  class="pl-6"
                  cols="1"
                  sm="1"
                  md="1"
                >
                  <v-btn 
                    icon 
                    small
                  >
                    <v-icon 
                      color="tvf_color"     
                    >   
                      mdi-plus             
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>                
              <v-row
                class="pr-12"
                v-for="item in head_foot" :key="item.id"
              >
                <v-col    
                  class="d-flex justify-space-between" 
                  v-if="item.type === 'footer'"
                  cols="9"
                  sm="9"
                  md="9"
                >
                  <span class="text-capitalize">{{ item.name }}</span>
                </v-col>
                <v-col
                  v-if="item.type === 'footer'"
                  class="pl-6 d-flex"
                  cols="1"
                  sm="1"
                  md="1"
                >
                  <v-btn 
                    v-if="editDatabase"  
                    icon 
                    small
                  >
                    <v-icon                         
                      @click="editHeadfoot(item)"
                    >   
                      mdi-pencil             
                    </v-icon>
                  </v-btn>
                  <v-btn 
                    icon 
                    small
                  >
                    <v-icon    
                      @click="preview(item)"  
                    >   
                      mdi-eye             
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>            
            </v-col>
          </div>  
          <!-- FINAL HEADER & FOOTER---------------------------------------------------------------------->
          <!-- OPEN PREVIEW MODAL--------------------------------------------------------------------
          --------------------------------------------------------------------
          --------------------------------------------------------------------
          ---------------------------------------------------------------------->
          <v-dialog
            v-model="showPreview"
            :value="true"
            persistent
            @click:outside="showPreview = false"
            max-width="600px"   
          >
            <v-card>
              <v-card-title>
                <span class="text-subtitle text-capitalize pa-4">{{ showItem.type}} preview: {{ showItem.name}}</span>
                <v-spacer></v-spacer>
                <v-icon
                  class="mr-2"
                  @click="showPreview = false"
                >
                  mdi-close
                </v-icon>
              </v-card-title>
              <v-divider
                class="ml-8"
                width="90%"
              ></v-divider>
              <v-card-text>
                <span v-html="showItem.code" class="px-16 my-10 subtitle-1"></span>
              </v-card-text>
            </v-card>
          </v-dialog> 
          <!-- FINAL HEADER & FOOTER---------------------------------------------------------------------->
          <!-- OPEN NEW HEADER/FOOTER MODAL--------------------------------------------------------------------
          --------------------------------------------------------------------
          --------------------------------------------------------------------
          ---------------------------------------------------------------------->
          <v-dialog
            v-model="add_hf"
            :value="true"
            persistent
            @click:outside="add_hf = false"
            max-width="900px"
          >
            <v-card>
              <v-card-title>
                <span class="text-subtitle text-capitalize pa-4">Add {{ editItem.type }}: {{ editItem.name }}</span>
                <v-spacer></v-spacer>
                <v-icon
                  class="mr-2"
                  @click="add_hf = false"
                >
                  mdi-close
                </v-icon>
              </v-card-title>
              <v-divider
                class="ml-8"
                width="93%"
              ></v-divider>
              <v-card-text>
                <v-container>
                  <!-- Or manually control the data synchronization -->
                  <quill-editor
                    class="pa-4"
                    :content="editItem.code"
                    :options="editorOption"
                    spellcheck=true
                    @change="onEditorChange($event)"
                  />
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  type="submit"
                  class="addButton pa-6 mb-6 mr-6 white--text"
                  color="tvf_color"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>   
          <!-- FINAL NEW HEADER/FOOTER MODAL---------------------------------------------------------------------->
          <!-- OPEN EDIT HEADER/FOOTER MODAL--------------------------------------------------------------------
          --------------------------------------------------------------------
          --------------------------------------------------------------------
          ---------------------------------------------------------------------->
          <v-dialog
            v-model="edit_hf"
            :value="true"
            persistent
            @click:outside="edit_hf = false"
            max-width="900px"
          >
            <v-card>
              <v-card-title>
                <span class="text-subtitle text-capitalize pa-4">Edit {{ editItem.type }}: {{ editItem.name }}</span>
                <v-spacer></v-spacer>
                <v-icon
                  class="mr-2"
                  @click="edit_hf = false"
                >
                  mdi-close
                </v-icon>
              </v-card-title>
              <v-divider
                class="ml-8"
                width="93%"
              ></v-divider>
              <v-card-text>
                <v-container>
                  <!-- Or manually control the data synchronization -->
                  <quill-editor
                    class="pa-4"
                    :content="editItem.code"
                    :options="editorOption"
                    spellcheck=true
                    @change="onEditorChange($event)"
                  />
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  type="submit"
                  class="addButton pa-6 mb-6 mr-6 white--text"
                  color="tvf_color"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>    
          <!-- FINAL EDIT HEADER/FOOTER MODAL---------------------------------------------------------------------->
          <!-- OPEN USERS MODAL--------------------------------------------------------------------
          --------------------------------------------------------------------
          --------------------------------------------------------------------
          ---------------------------------------------------------------------->
          <v-dialog
            v-model="showUser"
            :value="true"
            persistent
            @click:outside="showUser = false"
            max-width="550px"
          >
            <v-card>
              <v-card-title>
                <span class="text-subtitle bold pa-4">Users: {{ name }}</span>
                <v-spacer></v-spacer>
                <v-icon
                  @click="showUser = false"
                  class="mr-2"
                >
                  mdi-close
                </v-icon>
              </v-card-title>
              <v-divider
                class="mx-8"
                width="90%"
              ></v-divider>
              <v-row class="py-6">
                <v-col class="pl-8">
                  <v-list>
                    <v-toolbar-title class="subtitle-2 font-weight-bold pl-8 pb-2">Manager</v-toolbar-title>
                    <v-list-item
                      v-for="item, index in managers"
                      :key="index"
                    >
                      <v-list-item-icon>
                        <v-icon
                          class="pl-6"
                          small
                        >
                          mdi-circle-small
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title class="subtitle-2">{{ item.manager }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col>
                  <v-list>
                    <v-toolbar-title class="subtitle-2 font-weight-bold pl-8 pb-2">Viewer</v-toolbar-title>
                    <v-list-item
                      v-for="item, index in managers"
                      :key="index"
                    >
                      <v-list-item-icon>
                        <v-icon
                          class="pl-8"
                          small
                        >
                          mdi-circle-small
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title class="subtitle-2">{{ item.viewer }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-card>
          </v-dialog>
          <!-- FINAL USERS MODAL---------------------------------------------------------------------->
          <!-- OPEN OWNERS MODAL--------------------------------------------------------------------
          --------------------------------------------------------------------
          --------------------------------------------------------------------
          ---------------------------------------------------------------------->
          <v-dialog
            class="owner_dialog"
            v-model="showOwner"
            :value="true"
            persistent
            @click:outside="showOwner = false"
            max-width="500px"
          >
            <v-card>
              <v-card-title>
                <span class="text-subtitle bold pa-4">Owners: {{ name }}</span>
                <v-spacer></v-spacer>
                <v-icon
                  @click="showOwner = false"
                  class="mr-2"
                >
                  mdi-close
                </v-icon>
              </v-card-title>
              <v-divider
                class="mx-8"
                width="90%"
              ></v-divider>
              <v-row class="py-6">
                <v-col class="pl-16" cols="4">
                  <v-list>
                    <v-toolbar-title class="subtitle-2 font-weight-bold pl-8 pb-2">Id</v-toolbar-title>
                    <v-list-item
                      v-for="item, index in owners"
                      :key="index"
                    >
                      <v-list-item-content>
                        <v-list-item-title class="subtitle-2 pl-4">{{ item.id }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="7">
                  <v-list>
                    <v-toolbar-title class="subtitle-2 font-weight-bold pl-4 pb-2">Name</v-toolbar-title>
                    <v-list-item
                      v-for="item, index in owners"
                      :key="index"
                    >
                      <v-list-item-content>
                        <v-list-item-title class="subtitle-2">{{ item.name }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-card>
          </v-dialog>
          <!-- FINAL USERS MODAL---------------------------------------------------------------------->
        </v-card>        
      </v-container>
      <assign-users
        v-if="assignUser"
        @close="assignUser = false"
      />
    </v-card>      
  </div>      
</template>

<script>
import ApiService from "@/services/api.service";
import LoadingSpinner from "../components/core/LoadingSpinner";
import AssignUsers from "../components/database/AssignUsers.vue";

export default {
  name: 'Databases',
  components: {
    LoadingSpinner,  
    AssignUsers  
  },
  
  data() {
    return {
      id: '',
      name: '',
      platform_id: '',
      platform_name: '',
      country: '',
      account_manager: '',
      ddbb_name: '',
      search: '',
      expire_at: '',
      domain: '',
      owner_name: [],
      fields_title: '',
      searchTerm: '',
      status: '',
      switch_status: '',
      deleted_at: null,
      field_value: [],
      loading: false,
      active: false,
      editDatabase: false,
      add_hf: false,
      edit_hf: false,
      pencil: false,
      showPreview: false,
      showUser: false,
      showOwner: false,
      finish_date: false,
      newDatabase: false,
      isFormValid: false,
      assignUser: false,
      company_id: 1,
      fields: [],
      countries: [],
      platforms: [],
      databases: [],
      acronyms: [],
      accounts: [],
      domains: [],
      owners: [],
      priority: [],
      managers: [],
      head_foot: [],
      showItem: {},
      addItem: {},
      editItem: {},
      domainsList: [],
      ownersList: [],
      searchCopy: [],
      headers: [
        {
          align: "start",
          value: "id",
          sortable: true
        },
        {
          align: "start",
          value: "name",
          sortable: true,
        },
        {
          align: "center",
          value: "country.acronym",
          sortable: false,
        },
      ],
      editorOption: {
        // Some Quill options...
      }
    }
  },

  computed: {
    isComplete() {
      return (
        this.name &&
        this.platform &&
        this.country &&
        this.account_manager &&
        this.ddbb_name &&
        this.fields &&
        this.domains &&
        this.owners &&
        this.head_foot 
      )
    },
  },

  mounted() {
    this.getCountries()    
    this.getDatabases()
    this.getDomains()
    this.getOwners()
    this.getPlatforms()
    this.$store.commit('getUsers')
  },

  computed: {
    editor() {
      return this.$refs.myQuillEditor.quill
    },

    sortedArray: function() {
      function orderDate(a, b) {
          if (a.deleted_at !== null) return 1;
          if (a.deleted_at === null) return -1;
          return 0;
      }
      return [...this.databases].sort(orderDate); // shallow clone + sort
    },
  },

  methods: {
    ifChange(item,index) {      
      this.domains[index].domain = item.domain
      this.domains[index].expire_at = item.expire_at
      this.domainsList.forEach(element => {
        if(element.id === item.domain){
          item.expire_at = element.expire_at
          item.priority = element.priority
        }
      })    
    },

    itemRowColor(item) {
      return item.deleted_at !== null ? 'disabled_item--text' : 'primary--text'
    },

    onChange(platform) {
      this.platforms.forEach(element => {
        if(platform === element.id) {
          this.fields = JSON.parse(element.fields)
        }
      })
    },    

    onEditorChange({ quill, html, text }) {
      this.content = html
    },

    filterOnlyCapsText (value, search, item) {
      return value != null &&
        search != null &&
        typeof value === 'string' &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
    },

    addUsers() {
      this.assignUser = true
    },

    preview(item) {
      this.showItem = {
        id: item.id,
        database_id: item.database_id,
        name: item.name,
        code: item.code,
        type: item.type
      }
      this.showPreview = true;
    },

    addHeader(item) {
      this.addItem = {
        id: item.id,
        database_id: item.database_id,
        name: item.name,
        code: item.code,
        type: item.type
      }
      this.add_hf = true
    },

    editHeadfoot(item) {
      this.editItem = {
        id: item.id,
        database_id: item.database_id,
        name: item.name,
        code: item.code,
        type: item.type
      }
      this.edit_hf = true
    },

    getCountries() {
      ApiService.get("countries/list")
      .then((response) => {
        this.countries = response.data.data;
        this.countries.forEach( country => {
          this.acronyms.push(country.acronym)      
        })        
      })
      .catch((error) => {
        console.log("Ups! Something is wrong");
      });
    },

    getDomains() {
      ApiService.get("domains/list")
      .then((response) => {
        const domainsList = response.data.data;  
        this.domainsList = domainsList.sort(function (a, b) {
            if (a.priority < b.priority) {
              return 1;
            }
            if (a.priority > b.priority) {
              return -1;
            }
           // a must be equal to b
            return 0;
          });   
      })
      .catch((error) => {
        console.log("Ups! Something is wrong");
      });
    },

    getOwners(){
      ApiService.get("owners/list")
      .then((response) => {
        const ownersList = response.data.data;  
        this.ownersList = ownersList.sort(function (a, b) {
            if (a.id < b.id) {
              return 1;
            }
            if (a.id > b.id) {
              return -1;
            }
           // a must be equal to b
            return 0;
          });   
      })
      .catch((error) => {
        console.log("Ups! Something is wrong");
      });
    },

    getPlatforms() {
      ApiService.get("platforms/list")
      .then((response) => {
        this.platforms = response.data.data;
      })
      .catch((error) => {
        console.log("Ups! Something is wrong");
      });
    },

    getDatabases() {
      ApiService.get("databases/list")
      .then((response) => {
        const databases = response.data.data;  
        this.databases = databases.sort(function (a, b) {
          if (a.id < b.id) {
            return 1;
          }
          if (a.id > b.id) {
            return -1;
          }
          return 0
          // a must be equal to b
        });      
        this.databases.forEach((element, index) => {
          if(element.deleted_at !== null) {
            this.databases[index].switch_status = false
          } else {
            this.databases[index].switch_status = true
          }
        })  
      })  
      .catch((error) => {
        console.log("Ups! Something is wrong");
      });
    }, 

    actives(){
      ApiService.get("databases/list/actives")
      .then((response) => {
        this.active = true
        this.databases = response.data.data;
      })
      .catch((error) => {
        console.log("Ups! Something is wrong");
      });
    },

    inactives(){
      ApiService.get("databases/list/inactives")
      .then((response) => {
        this.databases = response.data.data;
      })
      .catch((error) => {
        console.log("Ups! Something is wrong");
      });
    },

    rowClick(item, row){
      row.select(true);
      this.id = item.id
      this.name = item.name
      this.platform_id = item.platform.id
      this.platform_name = item.platform.name
      this.country = item.country.name
      this.account_manager = item.users
      this.ddbb_name = item.name_bdd
      this.fields = JSON.parse(item.platform_fields)
      this.domains = item.domains.sort(function (a, b) {
          if (a.priority < b.priority) {
            return -1;
          }
          if (a.priority > b.priority) {
            return 1;
          }
          // a must be equal to b
          return 0;
        });
      this.owners = item.owners
      this.head_foot = item.headers_footers
      this.fields_title = 'Fields'
      this.status = item.status
      this.deleted_at = item.deleted_at
      this.switch_status = item.switch_status
      this.editDatabase = false;
      this.pencil = true;
      this.newDatabase = false;
    },

    showUsers() {
      this.name = this.name
      this.managers = this.account_manager
      this.showUser = true
    },

    showOwners() {
      this.name = this.name
      this.owners
      this.showOwner = true
    },

    addDatabase() {
      this.id = ''
      this.name = ''
      this.platform = ''
      this.country = ''
      this.account_manager = ''
      this.ddbb_name = ''
      this.fields = ''
      this.domains = ''
      this.owners = ''
      this.head_foot = ''
      this.newDatabase = true;
      this.fields_title = 'Fields'
    },

    editDB() {      
      this.editDatabase = true
    },

    saveDatabase() {
      this.field_value.forEach((element, index) => {
        this.fields[index].value = element
      })      
      this.owners = []
      this.owner_name.forEach( element => {
        this.owners.push(element)
      })
      ApiService.post("databases", {
        platform_id: this.platform_,
        country_id: this.country,
        name: this.name,
        name_bdd: this.ddbb_name,
        company_id: this.company_id,
        platform_fields: JSON.stringify(this.fields),
        management: this.account_manager
      })
      .then((response) => {
        this.getDatabases()
        this.$store.dispatch('setSnackbar', {
          color: 'success',
          text: 'New database has been created!',
          timeout: 3000
        })        
      })
      .catch((error) => {
        if (typeof error.response.data === 'object') {
            Object.values(error.response.data.errors).flat().forEach( error => {
              this.$store.dispatch('setSnackbar', {
                color: 'tvf_secondary',
                text: error,
                timeout: 3000
              })       
            })
          }
      });
      this.newDatabase = false;
    },

    cancelSave() {
      this.newDatabase = false;
      this.pencil = false;
      this.fields_title = ''
    },

    updateDatabase() {
      this.field_value.forEach((element, index) => {
        this.fields[index].value = element
      }) 
      ApiService.put("databases/" + this.id, {
        platform_id: this.platform,
        country_id: this.country,
        name: this.name,
        name_bdd: this.ddbb_name,
        company_id: this.company_id,
        platform_fields: JSON.stringify(this.fields),        
      })
      .then((response) => {
        this.getDatabases()
        this.$store.dispatch('setSnackbar', {
          color: 'success',
          text: 'Database has been updated!',
          timeout: 3000
        })        
      })
      .catch((error) => {
        if (typeof error.response.data === 'object') {
          Object.values(error.response.data.errors).flat().forEach( error => {
            this.$store.dispatch('setSnackbar', {
              color: 'tvf_secondary',
              text: error,
              timeout: 3000
            })       
          })
        }
      });
      this.editDatabase = false;
    },

    disabledItem() {
      ApiService.patch("databases/status/" + this.id)
      .then((response) => {
        if( response.data.data === true) {
          this.$store.dispatch('setSnackbar', {
            color: 'success',
            text: 'Database has been enabled!',
            timeout: 3000
          })
          this.getDatabases();
        } else {
          this.$store.dispatch('setSnackbar', {
            color: 'success',
            text: 'Database has been disabled',
            timeout: 3000
          })
          this.getDatabases();
        } 
      })
      .catch((error) => {
        if (typeof error.response.data === 'object') {
          Object.values(error.response.data.errors).flat().forEach( error => {
            this.$store.dispatch('setSnackbar', {
              color: 'tvf_secondary',
              text: error,
              timeout: 3000
            })       
          })
        }
      });
    }
  }
};
</script>

<style scoped>

.text-btn{
  font-family: Poppins, sans-serif;
  font-size: 14px;
  line-height: 24px !important;
  text-transform: capitalize !important;
}
span, label, .text{
  font-size: 0.8rem !important;
  font-weight: 400;
}

.v-navigation-drawer {
  width: 350px !important;
}

.text-subtitle {
  font-size: 1.25rem !important;
}


.select-input  {
  width: 60px;
}

.database-list {
  cursor: pointer;
}

</style>