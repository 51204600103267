<template>
    <v-dialog
      :value="true"
      persistent
      @click:outside="$emit('close')"
      max-width="550px"
    >
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-icon
            class="mr-2 mt-2"
            @click="$emit('close')"
            small
          >
            mdi-close
          </v-icon>
        </v-card-title>
        <v-form @submit.prevent="updateSeedList">
          <v-card-text>
            <v-container>
              <v-col>
                <v-row class="px-2">
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <label class="caption font-weight-bold">List Name</label>
                    <v-text-field
                        v-model="list_name"
                        class="caption"
                        background-color="#F8F9FB"
                        dense
                        solo
                        flat
                    ></v-text-field>
                  </v-col>    
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <label class="caption font-weight-bold">Country</label>
                    <v-select
                        class="caption"
                        v-model="country"
                        :items="countriesList"
                        item-value="id"
                        item-text="name"
                        background-color="#F8F9FB"
                        dense
                        solo
                        flat
                    >
                    </v-select>
                  </v-col>    
                  <v-col
                    cols="12"
                    sm="10"
                    md="10"
                  >                    
                    <v-row class="d-flex mb-4 justify-space-between"> 
                        <label class="caption font-weight-bold ml-3 mb-1">Emails</label>                           
                        <v-btn
                          class="caption tvf_color--text pl-6"
                          color="#ffdde7"
                          depressed
                          small
                          center
                          @click="addEmails()"

                        >
                          Add Emails
                        </v-btn>
                    </v-row>
                    <div v-for="input, index in emails" :key="index" class="d-flex">
                        <v-text-field
                            v-if="editMode"
                            class="caption"
                            v-model="input.email"
                            background-color="#F8F9FB"
                            placeholder="mail@example.es"
                            dense
                            solo
                            flat
                        ></v-text-field>
                        <v-text-field
                            v-else
                            class="caption"
                            v-model="input.email"
                            background-color="#F8F9FB"
                            placeholder="mail@example.es, mail2@example.es"
                            dense
                            solo
                            flat
                        ></v-text-field>
                        <v-btn
                            type="submit"
                            elevation="0"
                            icon
                            tile
                            class="addButton ml-2 rounded"
                            @click="removeEmail(index)"
                        >
                            <v-icon>
                            mdi-delete-empty
                            </v-icon>
                        </v-btn>
                    </div>                                                                                
                  </v-col>   
                </v-row>
              </v-col>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              type="submit"
              class="addButton px-6 mb-6 mr-6 white--text"
              color="tvf_color"
              :disabled='!isComplete'
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>    
    </v-dialog>
</template>

<script>
import ApiService from "@/services/api.service";
import { mapState } from 'vuex';

export default ({
    props: ['emails', 'list_name', 'countriesList', 'editMode'],
    data () {
        return {
            email: '',
    }
  },

  mounted(){
    this.$store.commit('getCountries')
  },

  computed: {
    isComplete() {
      return (
        this.list_name &&
        this.country &&
        this.emails
      );
    },

    ...mapState([
        'countries'
    ]),
  },

  methods: {

    addEmails(){
      this.emails.push({})
    },

    removeEmail(index) {
      this.emails.splice(index, 1)
    },

    async updateSeedList() {        

        await ApiService.put("seeds", {
            name: this.list_name,
            country_id: this.country,
            emails: JSON.stringify(this.emails)
        })
        .then((response) => {
            this.$emit('close');
            this.$store.dispatch('getSeeds')
            this.$store.dispatch('setSnackbar', {
                color: 'success',
                text: 'Seed list ' + this.list_name + ' has been updated!',
                timeout: 3000
            })
        })
        .catch((error) => {
            if (typeof error.response.data === 'object') {
            Object.values(error.response.data.errors).flat().forEach( error => {
                this.$store.dispatch('setSnackbar', {
                color: 'tvf_secondary',
                text: error,
                timeout: 3000
                })       
            })
            }
        });
        }   
    }
})
</script>
