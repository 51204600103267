<template>
  <div class="pb-3">
    <v-bottom-navigation
      id="spacing"
      class="justify-sm-start"
      height="80"
      :value="value"
    >
      <v-icon
        class="mt-1 mr-2 font-weight-black"
        dark
        color="black"
      >
        mdi-database
      </v-icon>
      <div class="title pt-2">
        Management
      </div>
      <v-divider
        class="my-7 ml-6"
        width="5%"
        vertical
      ></v-divider>
      <div class="ml-6 my-8 d-flex">
        <router-link
          class="ml-5" 
          style="text-decoration: none; color: inherit;"
          v-for="link in links"
          :key="link.name"
          :to="link.to"
          @mouseover="hovered = true" @mouseleave="hovered = false"
        >
          <span id="idName" class="link black--text">{{ link.name }}</span>
        </router-link>
        <v-menu
          class="font-weight-black"
          bottom
          :offset-x="offset"
          min-width="150"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              class="others my-3 ml-2"
              v-bind="attrs"
              v-on="on"
            >
              Others
            </v-btn>
          </template>

          <v-list             
            class="subtitle-2 font-weight-black primary--text"
          >
            <v-list-item
              v-for="item in more"
              :key="item.name"
              :to="item.to"
            >
              {{ item.name }}
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <div class="my-6">
        <v-row class="ml-2">
          <v-col
            class="d-flex align-end"
            cols="12"
            sm="8"
            md="8"
          >
            <v-text-field
              @input="$emit('manageSearch', $event)"
              class="link"
              id="search"
              append-icon="mdi-magnify"
              placeholder="Search"
              dense
              solo
              flat
            ></v-text-field>
          </v-col>      
        </v-row>
      </div>
    </v-bottom-navigation>
  </div>
</template>

<script>

export default {
  name: 'NavManage',
  data: () => ({
    create_users: '',
    create_roles: '',
    offset: true,
    value: 1,
    links: [
      {
        name: "Roles & Permissions",
        to: "/management/roles_permissions",
        show: "view_permissions"
      },
      {
        name: "Users",
        to: '/management/users',
        show: "view_users"
      },
      {
        name: "Companies",
        to: "/management/companies",
        show: "view_companies"
      },      
      {
        name: "Agencies",
        to: "/management/agencies",
        show: "view_agencies"
      },
      {
        name: "Owners",
        to: "/management/owners",
        show: "view_owners"
      },      
      {
        name: "Databases",
        to: "/management/databases",
        show: "view_databases"
      },
      {
        name: "Domains",
        to: "/management/domains",
        show: "view_domains"
      },
      {
        name: "Platforms",
        to: "/management/platforms",
        show: "view_platforms"
      },
      {
        name: "Countries",
        to: "/management/countries",
        show: "view_countries"
      }
    ],
    more: [
      {
        name: "Seeds",
        to: "/management/seeds",
        show: "view_seeds"
      },
      {
        name: "Currencies",
        to: "/management/currencies",
        show: "create_users"
      },
      {
        name: "Paymodels",
        to: "/management/paymodels",
        show: "view_paymodels"
      },
      {
        name: "Tags",
        to: "/management/tags",
        show: "view_tags"
      },
      {
        name: "TVF Prefills",
        to: "/management/tvf_prefills",
        show: "create_users"
      },
    ],
  }),

};
</script>

<style scoped>

.title {
  font-weight: 800 !important;
  font-size: 24px !important;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em !important;
}

.router-link-exact-active {
  font-style: black !important;
  margin-top: auto;
  border-bottom-style: solid;
  border-bottom-width: 3px;
  border-bottom-color: #000000;
  line-height: 15pt;
  border-radius: 2px;
}

.link {
  font-weight: 600 !important;
  text-transform: capitalize !important;
  font-size: 0.85rem !important;
}

.v-divider--vertical{
  border-width: 2px !important;
  border-color: #000000 !important;
  min-height: 40%;
  height: auto;
}

#idName {
  font-size: 0.9rem !important;
}

#spacing {
  padding-left: 2%;
}

.others {
  background-color: white !important; 
  font-weight: 800 !important;
}

.theme--light .v-bottom-navigation {
  color: #000000 !important;
  background-color: #F5F5F5 !important;    
}

.link .v-bottom-navigation .v-btn:not(.v-btn--active) {
  color: #000;
}

.v-item-group.v-bottom-navigation{
  box-shadow: none;
}

</style>