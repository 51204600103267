<template>
    <div>
      <v-row justify="center">
        <v-dialog
          :value="true"
          persistent
          @click:outside="$emit('close')"
          max-width="500px"
        >
          <v-card>  
            <v-form 
              @submit.prevent="saveCurrency"
              v-model="isFormValid"
            >         
                <v-card-text>
                    <v-container>
                    <v-col class="pt-4">
                        <v-row class="px-4 py-8">
                        <v-col
                            cols="12"
                            sm="12"
                            md="12"
                        >
                            <label class="caption font-weight-bold">Name</label>
                            <v-text-field
                            class="caption"
                            v-model="name"
                            background-color="#F8F9FB"
                            dense
                            solo
                            flat
                            ></v-text-field>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="12"
                            md="12"
                        >
                            <label class="caption font-weight-bold">Code</label>
                            <v-text-field
                            class="caption"
                            v-model="code"
                            background-color="#F8F9FB"
                            dense
                            solo
                            flat
                            ></v-text-field>
                        </v-col>       
                        <v-col
                            cols="12"
                            sm="12"
                            md="12"
                        >
                            <label class="caption font-weight-bold">Symbol</label>
                            <v-text-field
                            class="caption"
                            v-model="symbol"
                            background-color="#F8F9FB"
                            dense
                            solo
                            flat
                            ></v-text-field>
                        </v-col>               
                        <v-col
                            cols="12"
                            sm="12"
                            md="12"
                        >
                            <label class="caption font-weight-bold">Value</label>
                            <v-text-field
                            class="caption"
                            v-model="value"
                            type="number"
                            background-color="#F8F9FB"
                            dense
                            solo
                            flat
                            ></v-text-field>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="12"
                            md="12"
                        >
                            <v-btn 
                            class="caption mt-3 white--text"
                            color="tvf_color" 
                            block
                            large 
                            :loading="loading"
                            :disabled="loading"
                            type="submit"
                            >
                            Save
                            </v-btn> 
                        </v-col>                                          
                        </v-row>
                    </v-col>
                    </v-container>
                </v-card-text>
            </v-form>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
  </template>
  
  <script>
  import ApiService from "@/services/api.service";
  
  export default {
    data() {
      return {
        errors: [],
        name: '',
        symbol: '',
        code: '',
        value: '',
        loading: false
      };
    },
  
    computed: {
      isEmpty() {
        return (
          this.tag == '' &&
          this.tags.length == 0
        );
      },
    },
  
    methods: {
      addTag(event){
        if (event.code == "Comma" || event.code == 'Enter') {
          event.preventDefault()
          var val = this.tag
  
          if (val.length > 0) {
            this.tags.push(val)
            this.tag = ''
          }
        }
      },
  
      removeTag (index) {
        this.tags.splice(index, 1)
      },
  
      saveCurrency() {
        this.loading = true 
        ApiService.post("currencies", {
            name: this.name,
            cod: this.code,
            symbol: this.symbol,
            value: this.value,
            date_from: '2023-05-05'
        }).then((response) => {
            this.loading = false 
            this.$emit('close');
            this.$emit('updateList');
            this.$store.dispatch('setSnackbar', {
                color: 'success',
                text: 'Currency "'+ this.name +'" has been created!',
                timeout: 3000
            })
        }).catch((error) => {
            this.loading = true 
            if (typeof error.response.data === 'object') {
                this.$store.dispatch("setSnackbar", {
                  color: "tvf_secondary",
                  text: error.message,
                  timeout: 3000,
                });
            }
        });
      },
    }
  };
  </script>
  
  <style scoped>
  .col-sm-4,
  .col-md-4,
  .col-12,
  .col-sm-8,
  .col-md-8 {
    padding: 0px;
  }
  
  
  </style>