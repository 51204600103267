<template>
  <div>
    <v-card>
      <LoadingSpinner v-if="loading" />
      <v-btn 
        v-can="create_companies"
        class="addButton ml-8 my-10 white--text"
        color="tvf_color"  
        @click="newCompany()"
      >
        Add new
      </v-btn>
      <v-data-table
        class="companyList px-10"
        v-if="!loading"
        :headers="headers"
        :items="companies"
        :search="search"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        show-expand
        single-expand
        @page-count="pageCount = $event"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn 
            icon
            small
            :disabled="item.deleted_at !== null"
          >
            <v-icon
              v-model="item.actions"
              color="black"
              @click="newContact(item)"
            >
              mdi-account-plus
            </v-icon>
          </v-btn>
          <v-btn 
            icon
            small
            :disabled="item.deleted_at !== null"
          >
            <v-icon
              v-model="item.actions"
              color="black"
              @click="openEditModal(item)"
            >
              mdi-pencil-outline
            </v-icon>
          </v-btn>
          <toggle-button 
            v-model="item.status"
            :color="{unchecked: '#E5E5E5', checked: '#F85182'}"
            @change="openConfirmation(item)"
            :width='23'
            :height='13'
          />
        </template>
        <template
          v-slot:[`item.data-table-expand`]="{ item, isExpanded, expand }"
        >
          <v-icon
            v-if="item.contacts.length > 0"
            :class="[
              'v-data-table__expand-icon',
              { 'v-data-table__expand-icon--active': isExpanded },
            ]"
            @click.stop="expand(!isExpanded)"
            >$expand</v-icon
          >
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-simple-table class="px-12 py-4">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left font-weight-black text-uppercase">
                      Name
                    </th>
                    <th class="text-left font-weight-black text-uppercase">
                      Type
                    </th>
                    <th class="text-left font-weight-black text-uppercase">
                      Email
                    </th>
                    <th class="text-left font-weight-black text-uppercase">
                      Language
                    </th>
                    <th class="text-left font-weight-black text-uppercase">
                      Skype
                    </th>
                    <th class="text-left font-weight-black text-uppercase">
                      Phone
                    </th>
                    <th class="text-left font-weight-black text-uppercase">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="contact, index in item.contacts"
                  :key="index"
                  >
                    <td>{{ contact.name }}</td>
                    <td>{{ contact.type }}</td>
                    <td>{{ contact.email }}</td>
                    <td>{{ contact.language }}</td>
                    <td>{{ contact.skype }}</td>
                    <td>{{ contact.phone }}</td>
                    <td>
                      <v-btn icon>
                        <v-icon
                          v-model="item.actions"
                          color="black"
                          @click="editContact(contact)"
                        >
                          mdi-pencil-outline
                        </v-icon>
                      </v-btn>
                      <v-btn icon>
                        <v-icon
                          v-model="item.actions"
                          color="black"
                          @click="deleteContact(contact, index)"
                        >
                          mdi-delete-outline
                        </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </td>
        </template>
      </v-data-table>
      <v-dialog
        v-model="contactsModal"
        :value="true"
        persistent
        @click:outside="contactsModal = false"
        max-width="550px"
      >
        <v-card>
          <v-card-title>
            <span class="text-subtitle bold pa-4">{{ title }}</span>
            <v-spacer></v-spacer>
            <v-icon
              class="mr-2"
              @click="contactsModal = false"
            >
              mdi-close
            </v-icon>
          </v-card-title>
          <v-divider
            class="ml-8"
            width="90%"
          ></v-divider>
          <v-form 
            ref="contactForm"
            v-model="isFormValid" 
            @submit.prevent="addContacts()">
            <v-card-text>
              <v-container>
                <v-col>
                  <v-row class="pa-4">
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <label>Name</label>
                      <v-text-field
                        v-model="contact.name"
                        :rules="nameRules"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <label>Type</label>
                      <v-text-field
                        v-model="contact.type"
                        :rules="typeRules"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <label>Email</label>
                      <v-text-field
                        v-model="contact.email"
                        type="email"
                        :rules="emailRules"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <label right>Phone</label>
                      <v-text-field
                        v-model="contact.phone"
                        :rules="phoneRules"
                        type="phone"
                        @keypress="isNumber($event)"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <label>Skype</label>
                      <v-text-field
                        v-model="contact.skype"
                        :rules="skypeRules"
                        required
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <label>Language</label>
                      <v-text-field
                        v-model="contact.language"
                        :rules="languageRules"
                        required
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                v-if="addContactBtn"
                type="submit"
                class="addButton pa-6 mb-8 mr-8 white--text"
                color="tvf_color"
                :disabled='!isFormValid && !this.contacts.length > 0'
              >
                Save
              </v-btn>
              <v-btn
                v-if="editContactBtn"
                type="submit"
                class="addButton pa-6 mb-8 mr-8 white--text"
                color="tvf_color"
                :disabled='!isFormValid && !this.contacts.length > 0'
                @click="updateContact(contact)"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="confirmation"
        :value="true"
        persistent
        @click:outside="confirmation = false"
        max-width="400px"
      >
        <v-card>
          <v-container>
            <v-col>
              <v-row class="pa-4">
                <v-col
                  class="align-center"
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <h4>{{ name }}</h4>
                  <p class="body-2 mt-4">Are you sure you want to disable this company?</p>
                </v-col>
              </v-row>
              <v-row class="px-4 pb-4">
                <v-col
                  class="align-center"
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-btn
                    class="addButton pa-6 mr-8 white--text"
                    block
                    color="tvf_color"
                    @click="disabledItem()"
                  >
                  Disable
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-container>
        </v-card>
      </v-dialog>
      <new-company
        v-if="addCompany"
        @close="addCompany = false"
        @updateList="addCompanies"
      />
      <edit-company
        v-if="editCompany"
        @close="editCompany = false"
        :editedItem="editedItem"
        @updateList="addCompanies"
      />
    </v-card>
    <div class="pt-4 pl-4">
      <v-pagination
        v-model="page"
        :length="pageCount"
        color="#f5f5f5"
        class="primary--text"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import ApiService from "../services/api.service";
import LoadingSpinner from "../components/core/LoadingSpinner"
import NewCompany from '../components/company/NewCompany.vue';
import EditCompany from '../components/company/EditCompany.vue';

export default {
  props: ['search'],
  name: 'Companies',
  components: {
    LoadingSpinner,
    NewCompany,
    EditCompany
  },
  data() {
    return {
      create_companies: '',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      contactsModal: false,
      addContactBtn: false,
      editContactBtn: false,
      addCompany: false,
      editCompany: false,
      loading: false,
      isFormValid: false,
      confirmation: false,
      companies: [],
      contacts: [],
      name: '',
      itemId: '',
      contact: {
        name: "",
        type: "",
        email: "",
        language: "",
        skype: "",
        phone: "",
      },
      companyID: '',
      editedItem: {},
      title: '',
      headers: [     
        {
          text: "NAME",
          align: "start",
          value: "name",
          sortable: true,  
          width: '15%'
        },
        {
          text: "FISCAL NAME",
          align: "start",
          value: "fiscal_name",
          sortable: true, 
          width: '20%'
        },
        {
          text: "ADDRESS",
          align: "start",
          value: "address",
          sortable: false, 
          width: '20%'
        },
        {
          text: "CIF",
          align: "start",
          value: "cif",
          sortable: false,          
        },
        {
          text: "EMAIL",
          align: "start",
          value: "email",
          sortable: false,  
        },
        {
          text: "PHONE",
          align: "start",
          value: "phone",
          sortable: false,  
        },
        { 
          text: "ACTIONS", 
          align: "center",
          value: "actions", 
          sortable: false,  
          width: '20%'
        },
      ],     
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "Invalid Email Address",
      ],
      phoneRules: [
        (v) => !!v || "Phone is required",
        (v) =>
          /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
            v
          ) || "Invalid Phone Number",
      ],
      nameRules: [(v) => !!v || "Name is required"],
      fiscalNameRules: [(v) => !!v || "Fiscal Name is required"],
      addressRules: [(v) => !!v || "Address is required"],
      cifRules: [(v) => !!v || "Cif is required"],
      typeRules: [(v) => !!v || "Type is required"],
      skypeRules: [(v) => !!v || "Skype is required"],
      languageRules: [(v) => !!v || "Language is required"], 
    };
  },

  mounted() {
    this.addCompanies()
  },

  methods: {

    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();;
      } else {
        return true;
      }
    },

    filterOnlyCapsText (value, search) {
      return value != null &&
        search != null &&
        typeof value === 'string' &&
        value.toString().toLocaleLowerCase().indexOf(search) !== -1
    },

    newContact(item) {
      this.title = 'Add new contact';
      this.companyID = item.id
      this.contact = {
        name: "",
        type: "",
        email: "",
        language: "",
        skype: "",
        phone: "",
      }
      this.addContactBtn = true
      this.editContactBtn = false
      this.contactsModal = true
    },

    editContact(contact) {
      this.title = 'Edit contact';
      this.contact = {
        name: contact.name,
        type: contact.type,
        email: contact.email,
        language: contact.language,
        skype: contact.skype,
        phone: contact.phone,
      }
      this.addContactBtn = false
      this.editContactBtn = true
      this.contactsModal = true
      this.contactID = contact.id
    },

    openConfirmation(item){
      if(item.status === false) {
        this.confirmation = true
        this.name = item.name
        this.itemId = item.id
      }
      else {
        ApiService.patch("companies/status/" + item.id)
        .then((response) => {
          this.addCompanies();
          this.$store.dispatch('setSnackbar', {
            color: 'success',
            text: 'Company is active!',
            timeout: 3000
          })
        })
      }
    },

    newCompany() {
      this.addCompany = true
    },

    openEditModal(item) {
      this.editedItem = {
        id: item.id,
        name: item.name,
        fiscal_name: item.fiscal_name,
        address: item.address,
        cif: item.cif,
        email: item.email,
        phone: item.phone,
      },
      this.editCompany = true
    },

    addCompanies() {
      this.loading = true //the loading begin

      ApiService.get("companies/list")
        .then((response) => {
          this.loading = false //the loading stop when the response given from server
          const companies = response.data.data
          this.companies = companies.sort(function (a, b) {
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }
            // a must be equal to b
            return 0;
          });     
          this.companies.forEach((element, index) => {
            if(element.deleted_at !== null) {
              this.companies[index].status = false
            } else {
              this.companies[index].status  = true
            }
          });    
        })
        .catch((error) => {
          this.loading = false
          console.log("Ups! Something is wrong");
        });
    },

    addContacts() {       
      ApiService.post("contacts/company/" + this.companyID, this.contact)
      .then((response) => {
        this.contactsModal = false
        this.addCompanies();
        this.$store.dispatch("setSnackbar", {
          color: "success",
          text: "Contact has been created!",
          timeout: 3000,
        });          
      })
      .catch((error) => {
        if (typeof error.response.data === "object") {
          Object.values(error.response.data.errors)
            .flat()
            .forEach((error) => {
              this.$store.dispatch("setSnackbar", {
                color: "tvf_secondary",
                text: error,
                timeout: 3000,
              });
            });
          }
        });
    },

    updateContact() {
      ApiService.put("contacts/" + this.contactID, {
        name: this.contact.name,
        type: this.contact.type,
        email: this.contact.email,
        skype: this.contact.skype,
        language: this.contact.language,
        phone: this.contact.phone,
      } )
      .then((response) => {
        this.contactsModal = false
        this.addCompanies();
        this.$store.dispatch("setSnackbar", {
          color: "success",
          text: "Contact '" + this.contact.name + "' has been update successfully!",
          timeout: 3000,
        });
      })
      .catch((error) => {
        if (typeof error.response.data === "object") {
          Object.values(error.response.data.errors)
          .flat()
          .forEach((error) => {
            this.$store.dispatch("setSnackbar", {
              color: "tvf_secondary",
              text: error,
              timeout: 3000,
            });
          });
        }
      });
    },

    deleteContact(contact) {
      ApiService.delete("contacts/" + contact.id)
      .then((response) => {
        this.addCompanies();
        this.$store.dispatch("setSnackbar", {
          color: "success",
          text: "Contact '" + contact.name + "' has been deleted",
          timeout: 3000,
        }); 
        this.addCompanies();
      })
      .catch((error) => {
        console.log("Ups! Something is wrong");
      });
    },

    disabledItem() {
      ApiService.patch("companies/status/" + this.itemId)
        .then((response) => {
          this.confirmation = false
          this.addCompanies();
          this.$store.dispatch('setSnackbar', {
            color: 'success',
            text: 'Company has been disabled',
            timeout: 3000
        })
        .catch((error) => {
          console.log("Ups! Something is wrong");
        });
      })
    }
  }
};

</script>

<style scoped>

.companyList .mdi-chevron-down::before {
  content: "\F419" !important;
  color: #000;
}

</style>