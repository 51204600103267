<template>
  <div>
    <v-card>
      <LoadingSpinner v-if="loading" />
      <v-btn
        class="addButton ml-8 my-10 white--text"
        color="tvf_color"
        @click="newPlatform()"
      >
        Add new
      </v-btn>
      <v-data-table
        class="platformList px-10"
        v-if="!loading"
        :headers="headers"
        :items="platforms"
        :search="search"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        show-expand
        single-expand
        @page-count="pageCount = $event"
      >
        <template
          v-for="header in headers.filter((header) =>
            header.hasOwnProperty('formatter')
          )"
          v-slot:[`item.${header.value}`]="{ value }"
        >
          {{ header.formatter(value) }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            icon
            small
          >
            <v-icon
              v-model="item.actions"
              color="black"
              @click="showInfo(item)"
            >
              mdi-information-outline
            </v-icon>
          </v-btn>
          <v-btn
            icon
            small
          >
            <v-icon
              v-model="item.actions"
              color="black"
              @click="showCost(item)"
            >
              mdi-currency-eur
            </v-icon>
          </v-btn>
          <v-btn
            icon
            small            
            :disabled="item.deleted_at !== null"
          >
            <v-icon
              v-model="item.actions"
              color="black"
              @click="newContact(item)"
            >
              mdi-account-plus
            </v-icon>
          </v-btn>
          <v-btn
            icon
            small
            :disabled="item.deleted_at !== null"
          >
            <v-icon
              v-model="item.actions"
              color="black"
              @click="openEditModal(item)"
            >
              mdi-pencil-outline
            </v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <toggle-button
            v-model="item.status"
            :color="{unchecked: '#E5E5E5', checked: '#F85182'}"
            @change="openConfirmation(item)"
            :width='23'
            :height='13'
          />
        </template>
        <template v-slot:[`item.data-table-expand`]="{ item, isExpanded, expand }">
          <v-icon
            v-if="item.contacts.length > 0"
            :class="[
              'v-data-table__expand-icon',
              { 'v-data-table__expand-icon--active': isExpanded },
            ]"
            @click.stop="expand(!isExpanded)"
          >$expand</v-icon>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-simple-table class="px-12 py-4">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left font-weight-black text-uppercase">
                      Name
                    </th>
                    <th class="text-left font-weight-black text-uppercase">
                      Type
                    </th>
                    <th class="text-left font-weight-black text-uppercase">
                      Email
                    </th>
                    <th class="text-left font-weight-black text-uppercase">
                      Language
                    </th>
                    <th class="text-left font-weight-black text-uppercase">
                      Skype
                    </th>
                    <th class="text-left font-weight-black text-uppercase">
                      Phone
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="contact in item.contacts"
                    :key="contact.name"
                  >
                    <td>{{ contact.name }}</td>
                    <td>{{ contact.type }}</td>
                    <td>{{ contact.email }}</td>
                    <td>{{ contact.language }}</td>
                    <td>{{ contact.skype }}</td>
                    <td>{{ contact.phone }}</td>
                    <td class="text-right">
                      <v-btn icon>
                        <v-icon
                          v-model="item.actions"
                          @click="editContact(contact)"
                        >
                          mdi-pencil-outline
                        </v-icon>
                      </v-btn>
                      <v-btn icon>
                        <v-icon
                          v-model="item.actions"
                          @click="deleteContact(contact, index)"
                        >
                          mdi-delete-outline
                        </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </td>
        </template>
      </v-data-table>
      <v-dialog
        v-model="platformInfo"
        :value="true"
        persistent
        @click:outside="platformInfo = false"
        max-width="650px"
      >
        <v-card>
          <v-card-title>
            <span class="text-subtitle bold py-2 pl-4 pr-16">Platform Fields {{ platform_name }}</span>
            <v-icon
              @click="platformInfo = false"
              class="ml-16"
            >
              mdi-close
            </v-icon>
          </v-card-title>
          <v-divider
            class="mx-8"
            width="90%"
          ></v-divider>
          <v-row class="field-list py-2">
            <v-col class="pl-8">
              <v-list>
                <v-toolbar-title class="subtitle-2 font-weight-bold pl-8 pb-2">Field Name</v-toolbar-title>
                <v-list-item
                  v-for="field in fields"
                  :key="field.field_name"
                >
                  <v-list-item-icon>
                    <v-icon
                      class="pl-6"
                      small
                    >
                      mdi-circle-small
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="subtitle-2">{{ field.field_name }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col>
              <v-list>
                <v-toolbar-title class="subtitle-2 font-weight-black pl-8 pb-2">Display Name</v-toolbar-title>
                <v-list-item
                  v-for="field in fields"
                  :key="field.field_name"
                >
                  <v-list-item-icon>
                    <v-icon
                      class="pl-8"
                      small
                    >
                      mdi-circle-small
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="subtitle-2">{{ field.display_name }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
          <v-row
            v-if="customs.length > 0"
            class="field-list pb-6"
          >
            <v-col class="pl-8">
              <v-list>
                <v-toolbar-title class="subtitle-2 font-weight-bold pl-8 pb-2">Custom Name</v-toolbar-title>
                <v-list-item
                  v-for="custom in customs"
                  :key="custom.id"
                >
                  <v-list-item-icon>
                    <v-icon
                      class="pl-6"
                      small
                    >
                      mdi-circle-small
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="subtitle-2">{{ custom.name }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col>
              <v-list>
                <v-toolbar-title class="subtitle-2 font-weight-black pl-8 pb-2">Custom TVF</v-toolbar-title>
                <v-list-item
                  v-for="custom in customs"
                  :key="custom.id"
                >
                  <v-list-item-icon>
                    <v-icon
                      class="pl-8"
                      small
                    >
                      mdi-circle-small
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="subtitle-2">{{ custom.tvf_prefill.name }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="contactsModal"
        :value="true"
        persistent
        @click:outside="contactsModal = false"
        max-width="550px"
      >
        <v-card>
          <v-card-title>
            <span class="text-subtitle bold pa-4">{{ title }}</span>
            <v-spacer></v-spacer>
            <v-icon
              class="mr-2"
              @click="contactsModal = false"
            >
              mdi-close
            </v-icon>
          </v-card-title>
          <v-divider
            class="ml-8"
            width="90%"
          ></v-divider>
          <v-form
            ref="contactForm"
            v-model="isFormValid"
            @submit.prevent="contactForm"
          >
            <v-card-text>
              <v-container>
                <v-col>
                  <v-row class="pa-4">
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <label>Name</label>
                      <v-text-field
                        v-model="contact.name"
                        :rules="nameRules"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <label>Type</label>
                      <v-text-field
                        v-model="contact.type"
                        :rules="typeRules"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <label>Email</label>
                      <v-text-field
                        v-model="contact.email"
                        type="email"
                        :rules="emailRules"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <label right>Phone</label>
                      <v-text-field
                        v-model="contact.phone"
                        :rules="phoneRules"
                        type="phone"
                        @keypress="isNumber($event)"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <label>Skype</label>
                      <v-text-field
                        v-model="contact.skype"
                        :rules="skypeRules"
                        required
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <label>Language</label>
                      <v-text-field
                        v-model="contact.language"
                        :rules="languageRules"
                        required
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                v-if="addContactBtn"
                type="submit"
                class="addButton pa-6 mb-8 mr-8 white--text"
                color="tvf_color"
                :disabled='!isFormValid && !this.contacts.length > 0'
                @click="addContacts()"
              >
                Save
              </v-btn>
              <v-btn
                v-if="editContactBtn"
                type="submit"
                class="addButton pa-6 mb-8 mr-8 white--text"
                color="tvf_color"
                :disabled='!isFormValid && !this.contacts.length > 0'
                @click="updateContact(contact)"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="costsModal"
        :value="true"
        persistent
        @click:outside="costsModal = false"
        max-width="600px"
      >
        <v-card>
          <v-card-title>
            <span class="text-subtitle bold pa-4">Platform Costs</span>
            <v-spacer></v-spacer>
            <v-icon
              class="mr-2"
              @click="costsModal = false"
            >
              mdi-close
            </v-icon>
          </v-card-title>
          <v-divider
            class="ml-8"
            width="90%"
          ></v-divider>
          <v-btn
            class="addButton ml-8 mt-10 white--text"
            color="tvf_color"
            @click="addItem"
          >
            Add new
          </v-btn>
          <v-data-table
            :headers="costHeader"
            :items="costs"
            :search="search"
            class="pa-8"
          >
            <template v-slot:item.cost="{ item }">
              <v-text-field
                v-if="editingItem === item"
                v-model="item.cost"
                class="caption mt-5"
                type="number"
                step=".01"
                background-color="#F8F9FB"
                dense
                solo
                flat
              ></v-text-field>
              <span v-else>{{ formatCost(item.cost) }}</span>
            </template>

            <template v-slot:item.date_since="{ item }">
              <v-menu
                v-if="editingItem === item"
                v-model="item.menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="item.date_since"
                    class="caption mt-5"
                    label="yyyy-mm-dd"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    background-color="#F8F9FB"
                    dense
                    solo
                    flat
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="item.date_since"
                  @input="item.menu = false"
                ></v-date-picker>
              </v-menu>
              <span v-else>{{ item.date_since }}</span>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-btn
                v-if="editingItem === item"
                icon
                @click="cancelEdit(item)"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-btn
                v-else
                icon
                @click="editItem(item)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                v-if="editingItem === item"
                icon
                @click="saveEdit(item)"
              >
                <v-icon>mdi-content-save</v-icon>
              </v-btn>
              <v-btn
                v-else
                icon
                @click="deleteItem(item)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="confirmation"
        :value="true"
        persistent
        @click:outside="confirmation = false"
        max-width="400px"
      >
        <v-card>
          <v-container>
            <v-col>
              <v-row class="pa-4">
                <v-col
                  class="align-center"
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <h4>{{ name }}</h4>
                  <p class="body-2 mt-4">Are you sure you want to disable this platform?</p>
                </v-col>
              </v-row>
              <v-row class="px-4 pb-4">
                <v-col
                  class="align-center"
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-btn
                    class="addButton pa-6 mr-8 white--text"
                    block
                    color="tvf_color"
                    @click="disabledItem()"
                  >
                  Disable
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-container>
        </v-card>
      </v-dialog>
      <new-platform
        v-if="addPlatform"
        @close="addPlatform = false"
        @updateList="addPlatforms"
      />
      <edit-platform
        v-if="editPlatform"
        @close="editPlatform = false"
        :editedItem="editedItem"
        @updateList="addPlatforms"
      />
    </v-card>
    <div class="pt-4 pl-4">
      <v-pagination
        v-model="page"
        :length="pageCount"
        color="#f5f5f5"
        class="primary--text"
      ></v-pagination>
    </div>
  </div>
</template>

<script>

import ApiService from "../services/api.service";
import LoadingSpinner from "../components/core/LoadingSpinner";
import NewPlatform from "../components/platform/NewPlatform.vue";
import EditPlatform from "../components/platform/EditPlatform.vue";

export default {
  props: ['search'],
  name: "Platforms",
  components: {
    LoadingSpinner,
    NewPlatform,
    EditPlatform,
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      contactsModal: false,
      costsModal: false,
      addContactBtn: false,
      editContactBtn: false,
      addPlatform: false,
      editPlatform: false,
      platformInfo: false,
      loading: false,
      isFormValid: false,
      addCost: false,
      confirmation: false,
      start_date: false,
      editingItem: null,
      platform_name: '',
      platforms: [],
      contacts: [],
      contact: {
        name: "",
        type: "",
        email: "",
        language: "",
        skype: "",
        phone: "",
      },
      customs: [],
      costs: [],
      cost: '',
      date_since: '',
      platformID: "",
      platform_name: '',
      title: "",
      name: '',
      editedItem: {},
      platformCost: {},
      fields: {},
      editedIndex: -1,
      defaultItem: {
        id: 0,
        platform_id: "",
        cost: "",
        date_since: ""
      },
      costHeader: [
        {
          text: "COST x1000",
          align: "start",
          value: "cost",
          sortable: true        },
        {
          text: "DATE SINCE",
          align: "start",
          value: "date_since",
          sortable: true,
        },
        {
          text: "ACTIONS",
          align: "center",
          value: "actions",
          sortable: false,
          width: '10%'
        },
      ],
      headers: [
        {
          text: "NAME",
          align: "start",
          value: "name",
          sortable: true,
          width: "20%",
        },
        {
          text: "COST x1000",
          align: "start",
          value: "newest_platform_cost.cost",
          sortable: true,
          formatter: this.formatExample,
          width: "5%",
        },
        {
          text: "FISCAL NAME",
          align: "start",
          value: "fiscal_name",
          sortable: true,
          width: "20%",
        },
        {
          text: "ADDRESS",
          align: "start",
          value: "address",
          sortable: false,
          width: "25%",
        },
        {
          text: "CIF",
          align: "start",
          value: "cif",
          sortable: false,
        },
        {
          text: "EMAIL",
          align: "start",
          value: "email",
          sortable: false,
        },
        {
          text: "PHONE",
          align: "start",
          value: "phone",
          sortable: false,
        },
        {
          text: "ACTIONS",
          align: "center",
          value: "actions",
          sortable: false,
          width: "30%",
        },
        {
          text: "STATUS",
          align: "center",
          value: "status",
          sortable: false,
        },
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "Invalid Email Address",
      ],
      phoneRules: [
        (v) => !!v || "Phone is required",
        (v) =>
          /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
            v
          ) || "Invalid Phone Number",
      ],
      nameRules: [(v) => !!v || "Name is required"],
      fiscalNameRules: [(v) => !!v || "Fiscal Name is required"],
      addressRules: [(v) => !!v || "Address is required"],
      cifRules: [(v) => !!v || "Cif is required"],
      typeRules: [(v) => !!v || "Type is required"],
      skypeRules: [(v) => !!v || "Skype is required"],
      languageRules: [(v) => !!v || "Language is required"],
      costRules: [(v) => !!v || "Cost is required"],
      dateRules: [(v) => !!v || "Start date is required"]
    };
    
  },

  mounted() {
    this.addPlatforms();
  },

  computed: {
    isComplete() {
      return (
        this.cost &&
        this.date_since
      );
    },
  },

  methods: {
    formatExample(value) {
      // Just an simple example, you can do anything here
      if(value !== undefined) {
        const cost = value.toString().replace('.', ',') + "€"
        return cost;
      }      
    },

    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleLowerCase().indexOf(search) !== -1
      );
    },

    formatCost(cost) {
      const costNumber = Number(cost);
      return costNumber.toFixed(2).replace(".", ",");
    },

    editItem(item) {
      this.editingItem = item;
      item.menu = false;
      item.original = { ...item };
    },
    saveEdit(item) {
      this.editingItem = null;

      if(!item.id) {
        ApiService.post("platformcosts", {
          platform_id: this.platformID,
          cost: item.cost,
          date_since: item.date_since,
        })
        .then((response) => {
          this.addPlatforms();
          this.$store.dispatch('setSnackbar', {
            color: 'success',
            text: 'Added cost!',
            timeout: 3000
          })
        })
        .catch((error) => {
          if (typeof error.response.data === 'object') {
            Object.values(error.response.data.errors).flat().forEach( error => {
              console.log(error)              
              this.$store.dispatch('setSnackbar', {
                color: 'tvf_secondary',
                text: error,
                timeout: 3000
              })       
            })
          }
        });
      } else {
        ApiService.put("platformcosts/" + item.id, {
          platform_id: this.platformID,
          cost: item.cost,
          date_since: item.date_since,
        })
        .then((response) => {
          this.addPlatforms();
          this.$store.dispatch('setSnackbar', {
            color: 'success',
            text: 'Cost has been updated succesfully!',
            timeout: 3000
          })
        })
        .catch((error) => {
          if (typeof error.response.data === 'object') {
            Object.values(error.response.data.errors).flat().forEach( error => {
              console.log(error)              
              this.$store.dispatch('setSnackbar', {
                color: 'tvf_secondary',
                text: error,
                timeout: 3000
              })       
            })
          }
        });
      }      
    },
    cancelEdit(item) {
      if (item.original) {
        Object.assign(item, item.original);
      } else {
        this.deleteItem(item);
      }
      this.editingItem = null;
    },
    deleteItem(item) {
      this.costs.splice(this.costs.indexOf(item), 1);
    },
    addItem() {
      this.costs.push({
        cost: "",
        date_since: "",
        editing: true,
        menu: false,
      });
      this.editingItem = this.costs[this.costs.length - 1];
    },

    newContact(item) {
      this.title = "Add new contact";
      this.platformID = item.id;
      this.contact = {
        name: "",
        type: "",
        email: "",
        language: "",
        skype: "",
        phone: "",
      };
      this.addContactBtn = true;
      this.editContactBtn = false;
      this.contactsModal = true;
    },

    editContact(contact) {
      this.title = "Edit contact";
      this.contact = {
        name: contact.name,
        type: contact.type,
        email: contact.email,
        language: contact.language,
        skype: contact.skype,
        phone: contact.phone,
      };
      this.addContactBtn = false;
      this.editContactBtn = true;
      this.contactsModal = true;
      this.contactID = contact.id;
    },

    newPlatform() {
      this.addPlatform = true;
    },

    openEditModal(item) {
      this.costs = item.platform_costs.sort(function (a, b) {
        if (a.date_since > b.date_since) {
          return -1;
        }
        if (a.date_since < b.date_since) {
          return 1;
        }
        // a must be equal to b
        return 0;
      });
      this.editedItem = {
        id: item.id,
        name: item.name,
        email: item.email,
        cost: this.costs,
        fiscal_name: item.fiscal_name,
        address: item.address,
        cif: item.cif,
        phone: item.phone,
        fields: JSON.parse(item.fields),
        customs: item.custom_prefills,
      };
      this.editPlatform = true;
    },

    showInfo(item) {
      this.fields = JSON.parse(item.fields);
      this.customs = item.custom_prefills;
      this.platform_name = item.name
      this.platformInfo = true;
    },

    showCost(item) {
      this.platformID = item.id
      this.costs = item.platform_costs.sort(function (a, b) {
        if (a.date_since > b.date_since) {
          return -1;
        }
        if (a.date_since < b.date_since) {
          return 1;
        }
        // a must be equal to b
        return 0;
      });
      this.costsModal = true;
    },

    addPlatforms() {
      this.loading = true; //the loading begin

      ApiService.get("platforms/list")
        .then((response) => {
          this.loading = false; //the loading stop when the response given from server
          const platforms = response.data.data;
          this.platforms = platforms.sort(function (a, b) {
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }
            // a must be equal to b
            return 0;
          });
          this.platforms.forEach((element, index) => {
            if (element.deleted_at !== null) {
              this.platforms[index].status = false;
            } else {
              this.platforms[index].status = true;
            }
          });
        })
        .catch((error) => {
          this.loading = false;
          console.log("Ups! Something is wrong");
        });
    },

    addContacts() {
      ApiService.post("contacts/platform/" + this.platformID, this.contact)
        .then((response) => {
          this.contactsModal = false;
          this.addPlatforms();
          this.$store.dispatch("setSnackbar", {
            color: "success",
            text: "Contact has been created!",
            timeout: 3000,
          });
        })
        .catch((error) => {
          if (typeof error.response.data === "object") {
            Object.values(error.response.data.errors)
              .flat()
              .forEach((error) => {
                this.$store.dispatch("setSnackbar", {
                  color: "tvf_secondary",
                  text: error,
                  timeout: 3000,
                });
              });
          }
        });
    },

    updateContact() {
      ApiService.put("contacts/" + this.contactID, {
        name: this.contact.name,
        type: this.contact.type,
        email: this.contact.email,
        skype: this.contact.skype,
        language: this.contact.language,
        phone: this.contact.phone,
      })
        .then((response) => {
          this.contactsModal = false;
          this.addPlatforms();
          this.$store.dispatch("setSnackbar", {
            color: "success",
            text:
              "Contact '" +
              this.contact.name +
              "' has been update successfully!",
            timeout: 3000,
          });
        })
        .catch((error) => {
          if (typeof error.response.data === "object") {
            Object.values(error.response.data.errors)
              .flat()
              .forEach((error) => {
                this.$store.dispatch("setSnackbar", {
                  color: "tvf_secondary",
                  text: error,
                  timeout: 3000,
                });
              });
          }
        });
    },

    deleteContact(contact) {
      ApiService.delete("contacts/" + contact.id)
        .then((response) => {
          this.addPlatforms();
          this.$store.dispatch("setSnackbar", {
            color: "success",
            text: "Contact '" + contact.name + "' has been deleted",
            timeout: 3000,
          });
        })
        .catch((error) => {
          console.log("Ups! Something is wrong");
        });
    },

    openConfirmation(item){
      if(item.status === false) {
        this.confirmation = true
        this.name = item.name
        this.itemId = item.id
      }
      else {
        ApiService.patch("platforms/status/" + item.id)
        .then((response) => {
          this.addPlatforms();
          this.$store.dispatch('setSnackbar', {
            color: 'success',
            text: 'Platform is active!',
            timeout: 3000
          })
        })
      }
    },

    disabledItem() {
      ApiService.patch("platforms/status/" + this.itemId)
        .then((response) => {
          this.confirmation = false
          this.addPlatforms();
          this.$store.dispatch('setSnackbar', {
            color: 'success',
            text: 'Platform has been disabled',
            timeout: 3000
        })
        .catch((error) => {
          console.log("Ups! Something is wrong");
        });
      })
    },
  },
};
</script>

<style scoped>
.switch {
  width: 20px !important;
}

.field-list {
  margin: 0px;
}

.v-list-item__icon {
  margin: 4px 0;
}

.v-list-item__content {
  padding: 0;
}

.edit-input {
  font-size: 0.875rem !important;
}

.v-list-item {
  min-height: 0;
}

.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 0;
}

.platformList .mdi-chevron-down::before {
  content: "\F419" !important;
}
</style>