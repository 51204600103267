<template>
  <div>
    <v-card>
      <LoadingSpinner v-if="loading" />  
      <div class="d-flex">
        <v-btn 
          class="addButton ml-8 my-10 white--text"
          color="tvf_color"  
          @click="newOwner()"
        >
            Add new
        </v-btn>
        <v-select
          class="body-2 mt-10 mx-6"
          v-model="source"
          :items="sourceList"
          item-value="id"
          item-text="name"
          placeholder="Filter by source"
          background-color="#F8F9FB"
          dense
          solo
          flat
          @change="showOwners(source)"
        >
        </v-select>
        <v-spacer class="spacer2" />
      </div>        
      <v-data-table
        class="ownerList px-10"
        v-if="!loading"
        :headers="headers"
        :items="owners"
        :search="search"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        show-expand
        single-expand
        @page-count="pageCount = $event"
      >
        <template v-slot:[`item.percentage_revenue`]="{ item }">
          <span>{{item.percentage_revenue}} %</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn 
            icon
            small
            :disabled="item.deleted_at !== null"            
          >
            <v-icon
              v-model="item.actions"
              @click="manageSource(item)"
              color="black"
            >
              mdi-alpha-s-box-outline
            </v-icon>
          </v-btn>
          <v-btn 
            icon
            small
            :disabled="item.deleted_at !== null"
          >
            <v-icon
              v-model="item.actions"
              @click="newContact(item)"
              color="black"
            >
              mdi-account-plus
            </v-icon>
          </v-btn>
          <v-btn 
            icon
            small
            :disabled="item.deleted_at !== null"
          >
            <v-icon
              v-model="item.actions"
              @click="openEditModal(item)"
              color="black"
            >
              mdi-pencil-outline
            </v-icon>
          </v-btn>
          <toggle-button 
            v-model="item.status"
            :color="{unchecked: '#E5E5E5', checked: '#F85182'}"
            @change="openConfirmation(item)"
            :width='23'
            :height='13'
          />
        </template>
        <template
          v-slot:[`item.data-table-expand`]="{ item, isExpanded, expand }"
        >
          <v-icon
            v-if="item.contacts.length > 0"
            :class="[
              'v-data-table__expand-icon',
              { 'v-data-table__expand-icon--active': isExpanded },
            ]"
            @click.stop="expand(!isExpanded)"
            >$expand</v-icon
          >
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-simple-table class="px-12 py-4">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left font-weight-black text-uppercase">
                      Name
                    </th>
                    <th class="text-left font-weight-black text-uppercase">
                      Type
                    </th>
                    <th class="text-left font-weight-black text-uppercase">
                      Email
                    </th>
                    <th class="text-left font-weight-black text-uppercase">
                      Language
                    </th>
                    <th class="text-left font-weight-black text-uppercase">
                      Skype
                    </th>
                    <th class="text-left font-weight-black text-uppercase">
                      Phone
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="contact, index in item.contacts"
                  :key="index"
                  >
                    <td>{{ contact.name }}</td>
                    <td>{{ contact.type }}</td>
                    <td>{{ contact.email }}</td>
                    <td>{{ contact.language }}</td>
                    <td>{{ contact.skype }}</td>
                    <td>{{ contact.phone }}</td>
                    <td class="text-right">
                      <v-btn icon>
                        <v-icon
                          v-model="item.actions"
                          color="black"
                          @click="editContact(contact)"
                        >
                          mdi-pencil-outline
                        </v-icon>
                      </v-btn>
                      <v-btn icon>
                        <v-icon
                          v-model="item.actions"
                          color="black"
                          @click="deleteContact(contact)"
                        >
                          mdi-delete-outline
                        </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </td>
        </template>
      </v-data-table>
      <v-dialog
        v-model="contactsModal"
        :value="true"
        persistent
        @click:outside="contactsModal = false"
        max-width="550px"
      >
        <v-card>
          <v-card-title>
            <span class="text-subtitle bold pa-4">{{ title }}</span>
            <v-spacer></v-spacer>
            <v-icon
              class="mr-2"
              @click="contactsModal = false"
            >
              mdi-close
            </v-icon>
          </v-card-title>
          <v-divider
            class="ml-8"
            width="90%"
          ></v-divider>
          <v-form 
            ref="contactForm"
            v-model="isFormValid" 
            @submit.prevent="">
            <v-card-text>
              <v-container>
                <v-col>
                  <v-row class="pa-4">
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <label>Name</label>
                      <v-text-field
                        v-model="contact.name"
                        :rules="nameRules"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <label>Type</label>
                      <v-text-field
                        v-model="contact.type"
                        :rules="typeRules"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <label>Email</label>
                      <v-text-field
                        v-model="contact.email"
                        type="email"
                        :rules="emailRules"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <label right>Phone</label>
                      <v-text-field
                        v-model="contact.phone"
                        :rules="phoneRules"
                        type="phone"
                        @keypress="isNumber($event)"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <label>Skype</label>
                      <v-text-field
                        v-model="contact.skype"
                        :rules="skypeRules"
                        required
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <label>Language</label>
                      <v-text-field
                        v-model="contact.language"
                        :rules="languageRules"
                        required
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                v-if="addContactBtn"
                type="submit"
                class="addButton pa-6 mb-8 mr-8 white--text"
                color="tvf_color"
                :disabled='!isFormValid && !this.contacts.length > 0'
                @click="addContacts()"
              >
                Save
              </v-btn>
              <v-btn
                v-if="editContactBtn"
                type="submit"
                class="addButton pa-6 mb-8 mr-8 white--text"
                color="tvf_color"
                :disabled='!isFormValid && !this.contacts.length > 0'
                @click="updateContact(contact)"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="sourceModal"
        :value="true"
        persistent
        @click:outside="sourceModal = false"
        max-width="600px"
      >
        <v-card>
          <v-card-title>
            <span class="text-subtitle bold pa-4">Sources</span>
            <v-spacer></v-spacer>
            <v-icon
              class="mr-2"
              @click="sourceModal = false"
            >
              mdi-close
            </v-icon>
          </v-card-title>
          <v-divider
            class="ml-8"
            width="90%"
          ></v-divider>
          <v-btn
            class="addButton ml-8 mt-10 white--text"
            color="tvf_color"
            @click="addNew"
            :disabled="true"
          >
            Add new
          </v-btn>
          <v-data-table
            :headers="sourceHeader"
            :items="sources"
            :search="search"
            class="elevation-1 pa-10"
            fixed-header
            hide-default-footer
          >
            <template v-slot:[`item.name`]="{ item }" >
<!--                 <v-text-field
                  v-if="item.id === editItem.id"
                  
                  v-model="editItem.name"
                  dense
                  outlined
                  single-line
                ></v-text-field> -->
              <v-select
                v-if="item.source_id === editItem.source_id"
                v-model="item.name"
                class="select_source body-2 pt-6"
                :items="sourceList"
                item-value="id"
                item-text="name"
                dense
                outlined
                single-line
              ></v-select>
              <span v-else>{{item.name}}</span>
            </template>
            <template v-slot:[`item.privacy`]="{ item }">
              <v-text-field
                v-if="item.source_id === editItem.source_id"
                v-model="editItem.privacy"
                class="body-2 edit-input pt-6"
                outlined
                dense
                single-line
              ></v-text-field>
              <span v-else>{{item.privacy}}</span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <div v-if="item.id === editItem.id">
                <v-icon
                  class="mr-3"
                  @click="close"
                >
                  mdi-window-close
                </v-icon>
                <v-icon
                  color="tvf_color"
                  @click="save"
                >
                  mdi-content-save
                </v-icon>
              </div>
              <div v-else>
                <v-icon
                  class="mr-3"
                  @click="updateItem(item)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                  @click="deleteItem(item)"
                >
                  mdi-delete
                </v-icon>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="confirmation"
        :value="true"
        persistent
        @click:outside="confirmation = false"
        max-width="400px"
      >
        <v-card>
          <v-container>
            <v-col>
              <v-row class="pa-4">
                <v-col
                  class="align-center"
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <h4>{{ name }}</h4>
                  <p class="body-2 mt-4">Are you sure you want to disable this owner?</p>
                </v-col>
              </v-row>
              <v-row class="px-4 pb-4">
                <v-col
                  class="align-center"
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-btn
                    class="addButton pa-6 mr-8 white--text"
                    block
                    color="tvf_color"
                    @click="disabledItem()"
                  >
                  Disable
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-container>
        </v-card>
      </v-dialog>
      <new-owner
        v-if="addOwner"
        @close="addOwner = false"
        @updateList="addOwners"
      />
      <edit-owner
        v-if="editOwner"
        @close="editOwner = false"
        :editedItem="editedItem"
        @updateList="addOwners"
      />
    </v-card>
    <div class="pt-4 pl-4">
      <v-pagination
        v-model="page"
        :length="pageCount"
        color="#f5f5f5"
        class="primary--text"
      ></v-pagination>
    </div>
  </div>      
</template>

<script>

import ApiService from "../services/api.service";
import LoadingSpinner from "../components/core/LoadingSpinner"
import NewOwner from "../components/owner/NewOwner"
import EditOwner from '../components/owner/EditOwner.vue';

export default {
  props: ['search'],
  name: 'Owners',
  components: {
    LoadingSpinner,
    NewOwner,
    EditOwner
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      sourceModal: false,
      contactsModal: false,
      addContactBtn: false,
      editContactBtn: false,
      addOwner: false,
      editOwner: false,
      loading: false,
      isFormValid: false,
      confirmation: false,
      owners: [],
      sourceList: [],
      itemSource: [],
      sources: [],
      source: '',
      name: '',
      itemId: '',
      contacts: [],
      contact: {
        name: "",
        type: "",
        email: "",
        language: "",
        skype: "",
        phone: "",
      },
      ownerID: '',
      editedItem: {},
      title: '',
      editedIndex: -1,
      editItem: {
        id: 0,
        name: "",
        owner_id: '',
        privacy: '',
        shorter: '',
        source: {
          id: '',
          name: '',
        },
        source_id: ''
      },
      defaultItem: {
        id: 0,
        name: "",
        owner_id: '',
        privacy: '',
        shorter: '',
        source: {
          id: '',
          name: '',
        },
        source_id: ''
      },
      sourceHeader: [
        {
          text: "SOURCE (TAG)",
          align: "start",
          value: "source.name",
          sortable: true
        },
        {
          text: "PRIVACY",
          align: "start",
          value: "privacy",
          sortable: true,
          width: '50%'
        },
        {
          text: "ACTIONS",
          align: "center",
          value: "actions",
          sortable: false,
          width: '20%'
        },
      ],
      headers: [     
        {
          text: "NAME",
          align: "start",
          value: "name",
          sortable: true, 
          width: '20%'
        },
        {
          text: "FISCAL NAME",
          align: "start",
          value: "fiscal_name",
          sortable: true, 
          width: '20%'
        },
        {
          text: "ADDRESS",
          align: "start",
          value: "address",
          sortable: false, 
          width: '20%'
        },
        {
          text: "CIF",
          align: "start",
          value: "cif",
          sortable: false          
        },
        {
          text: "EMAIL",
          align: "start",
          value: "email",
          sortable: false          
        },
        {
          text: "PHONE",
          align: "start",
          value: "phone",
          sortable: false,
          width: '15%'
        },
        {
          text: "% REVENUE",
          align: "start",
          value: "percentage_revenue",
          sortable: false,
          width: '5%'
        },
        { 
          text: "ACTIONS", 
          align: "center",
          value: "actions", 
          sortable: false, 
          width: '25%' 
        },
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "Invalid Email Address",
      ],
      phoneRules: [
        (v) => !!v || "Phone is required",
        (v) =>
          /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
            v
          ) || "Invalid Phone Number",
      ],
      nameRules: [(v) => !!v || "Name is required"],
      fiscalNameRules: [(v) => !!v || "Fiscal Name is required"],
      addressRules: [(v) => !!v || "Address is required"],
      cifRules: [(v) => !!v || "Cif is required"],
      typeRules: [(v) => !!v || "Type is required"],
      skypeRules: [(v) => !!v || "Skype is required"],
      languageRules: [(v) => !!v || "Language is required"], 
    };
  },

  mounted() {
    this.getSources()
    this.addOwners()
  },

  methods: {

    addNew () {
      const addObj = Object.assign({}, this.defaultItem);
      addObj.id = this.sources.length + 1;
      this.sources.unshift(addObj);
      this.updateItem(addObj);
    },

    updateItem (item) {
      this.editedIndex = this.sources.indexOf(item);
      this.editItem = Object.assign({}, item);
    },

    deleteItem (item) {
      const index = this.sources.indexOf(item);
      this.sources.splice(index, 1);
      ApiService.delete("sources/" + item.source_id)
        .then((response) => {
          this.addPlatforms();
          this.$store.dispatch("setSnackbar", {
            color: "success",
            text: "Source has been deleted",
            timeout: 3000,
          });
        })
        .catch((error) => {
          if (typeof error.response.data === 'object') {
            Object.values(error.response.data.errors).flat().forEach( error => {
              console.log(error)              
              this.$store.dispatch('setSnackbar', {
                color: 'tvf_secondary',
                text: error,
                timeout: 3000
              })       
            })
          }
        });
    },

    close () {
      setTimeout(() => {
        this.editItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300)
      
    },

    save () {
      if (this.editedIndex > -1) {
        Object.assign(this.sources[this.editedIndex], this.editItem)
      }
      this.close()
      ApiService.put("sources/" + this.editItem.source_id, {
        name: this.editItem.source.name,
        url: this.editItem.privacy
      })
      .then((response) => {
        this.addOwners();
        this.$store.dispatch('setSnackbar', {
          color: 'success',
          text: 'Source has been update succesfully!',
          timeout: 3000
        })
      })
      .catch((error) => {
          if (typeof error.response.data === 'object') {
            Object.values(error.response.data.errors).flat().forEach( error => {
              console.log(error)              
              this.$store.dispatch('setSnackbar', {
                color: 'tvf_secondary',
                text: error,
                timeout: 3000
              })       
            })
          }
        });
          
    },

    manageSource(item) {
      this.sources = item.sources_shorter
      this.sourceModal = true; 
    },

    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();;
      } else {
        return true;
      }
    },

    filterOnlyCapsText (value, search) {
      return value != null &&
        search != null &&
        typeof value === 'string' &&
        value.toString().toLocaleLowerCase().indexOf(search) !== -1
    },

    newContact(item) {
      this.title = 'Add new contact';
      this.ownerID = item.id
      this.contact = {
        name: "",
        type: "",
        email: "",
        language: "",
        skype: "",
        phone: "",
      }
      this.addContactBtn = true
      this.editContactBtn = false
      this.contactsModal = true
    },

    editContact(contact) {
      this.title = 'Edit contact';
      this.contact = {
        name: contact.name,
        type: contact.type,
        email: contact.email,
        language: contact.language,
        skype: contact.skype,
        phone: contact.phone,
      }
      this.addContactBtn = false
      this.editContactBtn = true
      this.contactsModal = true
      this.contactID = contact.id
    },

    newOwner() {
      this.addOwner = true
    },

    openEditModal(item) {
      this.editedItem = {
        id: item.id,
        name: item.name,
        fiscal_name: item.fiscal_name,
        address: item.address,
        cif: item.cif,
        email: item.email,
        phone: item.phone,
        percentage_revenue: item.percentage_revenue
      },
      this.editOwner = true
    },

    showOwners(source) {
      this.owners = []

       ApiService.get("sources/" + source)
        .then((response) => {
          this.loading = false //the loading stop when the response given from server
          const data = response.data.data;  
          this.owners = data.owners.sort(function (a, b) {
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }
            // a must be equal to b
            return 0;
          });
          this.owners.forEach((element, index) => {
            if(element.deleted_at !== null) {
              this.owners[index].status = false
            } else {
              this.owners[index].status  = true
            }
          }); 
        })
        .catch((error) => {
          if (typeof error.response.data === "object") {
          Object.values(error.response.data.errors)
          .flat()
          .forEach((error) => {
            this.$store.dispatch("setSnackbar", {
              color: "tvf_secondary",
              text: error,
              timeout: 3000,
            });
          });
        }
      });     
    },

    getSources() {
      ApiService.get("sources/list")
      .then((response) => {
        const sources = response.data.data;  
        this.sourceList = sources.sort(function (a, b) {
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
      })
      .catch((error) => {
        if (typeof error.response.data === "object") {
        Object.values(error.response.data.errors)
          .flat()
          .forEach((error) => {
            this.$store.dispatch("setSnackbar", {
              color: "tvf_secondary",
              text: error,
              timeout: 3000,
            });
          });
        }
      });
    },

    addOwners() {      
      this.loading = true //the loading begin

      ApiService.get("owners/list")
        .then((response) => {
          this.loading = false //the loading stop when the response given from server
          const owners = response.data.data;  
          this.owners = owners.sort(function (a, b) {
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }
            // a must be equal to b
            return 0;
          });
          this.owners.forEach((element, index) => {
            if(element.deleted_at !== null) {
              this.owners[index].status = false
            } else {
              this.owners[index].status  = true
            }
          }); 
        })
        .catch((error) => {
          if (typeof error.response.data === "object") {
          Object.values(error.response.data.errors)
          .flat()
          .forEach((error) => {
            this.$store.dispatch("setSnackbar", {
              color: "tvf_secondary",
              text: error,
              timeout: 3000,
            });
          });
        }
      });
    },

    addContacts() {       
      ApiService.post("contacts/owner/" + this.ownerID, this.contact)
      .then((response) => {
        this.contactsModal = false
        this.addOwners();
        this.$store.dispatch("setSnackbar", {
          color: "success",
          text: "Contact has been created!",
          timeout: 3000,
        });          
      })
      .catch((error) => {
        if (typeof error.response.data === "object") {
          Object.values(error.response.data.errors)
          .flat()
          .forEach((error) => {
            this.$store.dispatch("setSnackbar", {
              color: "tvf_secondary",
              text: error,
              timeout: 3000,
            });
          });
        }
      });
    },

    updateContact() {
      ApiService.put("contacts/" + this.contactID, {
        name: this.contact.name,
        type: this.contact.type,
        email: this.contact.email,
        skype: this.contact.skype,
        language: this.contact.language,
        phone: this.contact.phone,
      } )
      .then((response) => {
        this.contactsModal = false
        this.addOwners();
        this.$store.dispatch("setSnackbar", {
          color: "success",
          text: "Contact '" + this.contact.name + "' has been update successfully!",
          timeout: 3000,
        });
      })
      .catch((error) => {
        if (typeof error.response.data === "object") {
          Object.values(error.response.data.errors)
          .flat()
          .forEach((error) => {
            this.$store.dispatch("setSnackbar", {
              color: "tvf_secondary",
              text: error,
              timeout: 3000,
            });
          });
        }
      });
    },

    deleteContact(contact) {
      ApiService.delete("contacts/" + contact.id)
      .then((response) => {
        this.addOwners();
        this.$store.dispatch("setSnackbar", {
          color: "success",
          text: "Contact '" + contact.name + "' has been deleted",
          timeout: 3000,
        }); 
      })
      .catch((error) => {
        console.log("Ups! Something is wrong");
      });
    },

    openConfirmation(item){
      if(item.status === false) {
        this.confirmation = true
        this.name = item.name
        this.itemId = item.id
      }
      else {
        ApiService.patch("owners/status/" + item.id)
        .then((response) => {
          this.addOwners();
          this.$store.dispatch('setSnackbar', {
            color: 'success',
            text: 'Owner is active!',
            timeout: 3000
          })
        })
      }
    },

    disabledItem(item) {
      ApiService.patch("owners/status/" + this.itemId)
        .then((response) => {
          this.confirmation = false
          this.addOwners();
          this.$store.dispatch('setSnackbar', {
            color: 'success',
            text: 'Owner has been disabled',
            timeout: 3000
        })
        .catch((error) => {
          console.log("Ups! Something is wrong");
        });
      })
    },
  }
}

</script>

<style scoped>

.ownerList .mdi-chevron-down::before {
  content: "\F419" !important;
  color: #000;
}

.select_source {
  width: 180px;
}

.spacer2 {
  width: 80%;
}

</style>