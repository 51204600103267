<template>
  <div>
    <v-row justify="center">
        <v-dialog
            :value="true"
            persistent
            @click:outside="$emit('close')"
            max-width="600px"   
          >
            <v-card>
              <v-card-title>
                <span class="text-subtitle text-capitalize pa-4">{{ showItem.type}} preview: {{ showItem.name}}</span>
                <v-spacer></v-spacer>
                <v-icon
                  class="mr-2"
                  @click="$emit('close')"
                >
                  mdi-close
                </v-icon>
              </v-card-title>
              <v-divider
                class="ml-8"
                width="90%"
              ></v-divider>
              <v-card-text>
                <span v-html="showItem.code" class="px-16 my-10 subtitle-1"></span>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-row>
    </div>
</template>

<script>
import ApiService from "@/services/api.service";

export default {
    props: ['showItem'],
    name: "showPreview",

    data() {
        return {
            search: "",
            loading: false,    
        }
    },
}
</script>

<style scoped>
.text-subtitle {
  font-size: 1.25rem !important;
}
</style>

