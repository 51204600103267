<template>
  <div>
    <v-row justify="center">
      <v-dialog
        :value="true"
        persistent
        @click:outside="$emit('close')"
        max-width="500px"
      >
        <v-card>
          <v-card-title>
            <span class="text-subtitle bold pa-4">New Owner</span>
            <v-spacer></v-spacer>
            <v-icon
              class="mr-2"
              @click="$emit('close')"
            >
              mdi-close
            </v-icon>
          </v-card-title>
          <v-divider
            class="ml-8"
            width="90%"
          ></v-divider>
          <v-form 
            @submit.prevent="addOwner"
            v-model="isFormValid"
          >
            <v-card-text>
              <v-container>
                <v-col>
                  <v-row class="px-8 py-4">
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <label>Name</label>
                      <v-text-field
                        v-model="name"
                        :rules="nameRules"
                        class="body-2"
                        background-color="#F8F9FB"
                        dense
                        solo
                        flat
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <label>Fiscal Name</label>
                      <v-text-field
                        v-model="fiscal_name"
                        :rules="fiscalNameRules"
                        class="body-2"
                        background-color="#F8F9FB"
                        dense
                        solo
                        flat
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <label>Address</label>
                      <v-text-field
                        v-model="address"
                        :rules="addressRules"
                        class="body-2"
                        background-color="#F8F9FB"
                        dense
                        solo
                        flat
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <label>CIF</label>
                      <v-text-field
                        v-model="cif"
                        :rules="cifRules"
                        class="body-2"
                        background-color="#F8F9FB"
                        dense
                        solo
                        flat
                      ></v-text-field>
                    </v-col>
                    <v-col
                      class="inputNumber"
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <label>Email</label>
                      <v-text-field
                        v-model="email"
                        :rules="emailRules"
                        class="body-2"
                        background-color="#F8F9FB"
                        dense
                        solo
                        flat
                      ></v-text-field>
                    </v-col>
                    <v-col
                      class="inputNumber"
                      cols="6"
                      sm="6"
                      md="6"
                    >
                      <label>Phone</label>
                      <v-text-field
                        v-model="phone"
                        type="phone"
                        :rules="phoneRules"
                        prefix="+"
                        class="body-2"
                        background-color="#F8F9FB"
                        dense
                        solo
                        flat
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="6"
                      sm="6"
                      md="6"
                    >
                      <label>Percentage Revenue</label>
                      <v-text-field
                        v-model="percentage_revenue"
                        type="number"
                        class="body-2"
                        background-color="#F8F9FB"
                        dense
                        solo
                        flat
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                type="submit"
                class="addButton pa-6 mb-6 mr-8 white--text caption"
                color="tvf_color"
                :disabled='!isFormValid'
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import ApiService from "@/services/api.service";

export default {
  data() {
    return {
      name: '',
      fiscal_name: '',
      address: '',
      cif: '',
      email: '',
      phone: '',
      percentage_revenue: '',
      errors: [],
      isFormValid: false,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Invalid Email Address',
      ],
      phoneRules: [
        (v) => !!v || "Phone is required",
        (v) => /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(v) || 'Invalid Phone Number'
      ],
      nameRules: [
        v => !!v || 'Name is required',
      ],
      fiscalNameRules: [
        v => !!v || 'Fiscal Name is required',
      ],
      addressRules: [
        v => !!v || 'Address is required',
      ],
      cifRules: [
        v => !!v || 'Last name is required',
      ],
      typeRules: [(v) => !!v || "Type is required"],
      skypeRules: [(v) => !!v || "Skype is required"],
      languageRules: [(v) => !!v || "Language is required"],
    };
  },

  methods: { 

    async addOwner() {

      await ApiService.post("owners", {
        name: this.name,
        fiscal_name: this.fiscal_name,
        address: this.address,
        cif: this.cif,
        email: this.email,
        phone: '+' + this.phone,
        percentage_revenue: this.percentage_revenue
      })
        .then((response) => {
          this.$emit('close');
          this.$emit('updateList');
          this.$store.dispatch('setSnackbar', {
            color: 'success',
            text: 'New owner has been created!',
            timeout: 3000
          })
        })
        .catch((error) => {
          if (typeof error.response.data === 'object') {
            Object.values(error.response.data.errors).flat().forEach( error => {
              this.$store.dispatch('setSnackbar', {
                color: 'tvf_secondary',
                text: error,
                timeout: 3000
              })       
            })
          }
        });
    },
  }
};
</script>

<style scoped>
.col-sm-4,
.col-md-4,
.col-12,
.col-sm-8, .col-md-8{
  padding: 0px;
}

.col-sm-6,
.col-md-6 {
  padding: 1px;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

</style>