<template>
  <div>
    <Management @manageSearch="search = $event" />
    <users v-if="['users', 'management'].includes($route.name)" :search="search"/>
    <companies v-if="['companies'].includes($route.name)" :search="search"/>
    <platforms v-if="['platforms'].includes($route.name)" :search="search"/>
    <databases v-if="['databases'].includes($route.name)" />
    <owners v-if="['owners'].includes($route.name)" :search="search"/>
    <domains v-if="['domains'].includes($route.name)" :search="search"/>
    <agencies v-if="['agencies'].includes($route.name)" :search="search"/>
    <countries v-if="['countries'].includes($route.name)" :search="search"/>
    <test v-if="['test'].includes($route.name)" />
    <roles-permissions v-if="['roles_permissions'].includes($route.name)" />
    <currencies v-if="['currencies'].includes($route.name)" :search="search"/>
    <tags v-if="['tags'].includes($route.name)" :search="search"/>
    <paymodels v-if="['paymodels'].includes($route.name)" :search="search"/>
    <tvf_prefills v-if="['tvf_prefills'].includes($route.name)" :search="search"/>
    <seeds v-if="['seeds'].includes($route.name)" :search="search"/>
  </div>
</template>

<script>
import Users from "./Users.vue";
import Companies from "./Companies.vue";
import Platforms from "./Platforms.vue";
import Databases from "./Databases.vue";
import Owners from "./Owners.vue";
import Domains from "./Domains.vue";
import Agencies from "./Agencies.vue";
import Countries from "./Countries.vue";
import Test from "./test.vue";
import RolesPermissions from './Roles&Permissions.vue';
import Tags from './Tags.vue';
import Paymodels from './Paymodels.vue';
import Tvf_prefills from './Tvf_prefills.vue';
import Seeds from './Seeds.vue';
import Currencies from './Currencies.vue'
import Management from "@/components/core/ManagementNav";

export default {
  components: { 
    Users, 
    Companies, 
    Domains, 
    Agencies, 
    Platforms,
    Databases,
    Owners,
    Countries,
    Test,
    RolesPermissions,
    Tags,
    Currencies,
    Paymodels,
    Tvf_prefills,
    Seeds,
    Management
  },
  name: "Manage",
  data: () => ({
    search: '',
  }),
};
</script>
<style scoped>

</style>