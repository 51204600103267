<template>
  <v-row class="px-6 pb-3">
    <v-card class="form-modal">
      <v-row class="d-flex flex-row-reverse pt-3">
        <v-btn
          type="submit"
          class="addButton mr-10 mt-10 white--text"
          color="tvf_color"
          @click="saveDatabase()"
        >
          Save
        </v-btn>
        <v-btn
          type="submit"
          class="addButton mx-5 mt-10 white--text"
          color="btn_secondary"
          @click="$emit('close')"
        >
          Cancel
        </v-btn>
      </v-row>
      <v-form class="pt-10">
        <v-row class="pl-8">
          <v-col
            cols="2"
            sm="2"
            md="2"
          >
            <label class="font-weight-bold">Name</label>
            <v-text-field
              class="text caption"
              v-model="name"
              background-color="#F8F9FB"
              dense
              solo
              flat
            >
            </v-text-field>
          </v-col>
          <v-col
            cols="2"
            sm="2"
            md="2s"
          >
            <label class="font-weight-bold">Platform</label><br />
            <v-autocomplete
              class="text caption"
              v-model="platform"
              :items="this.$store.state.platforms"
              item-value="id"
              item-text="name"
              @change="onChange(platform)"
              background-color="#F8F9FB"
              dense
              solo
              flat
            ></v-autocomplete>
          </v-col>
          <v-col
            cols="2"
            sm="2"
            md="2"
          >
            <label class="font-weight-bold">Country</label><br />
            <v-autocomplete
              class="text caption"
              v-model="country"
              :items="this.$store.state.countries"
              item-value="id"
              item-text="name"
              background-color="#F8F9FB"
              dense
              solo
              flat
            ></v-autocomplete>
          </v-col>
          <v-col
            cols="2"
            sm="2"
            md="2"
          >
            <label class="font-weight-bold">Ddbb Name</label><br />
            <v-text-field
              class="text caption"
              v-model="ddbb_name"
              background-color="#F8F9FB"
              dense
              solo
              flat
            >
            </v-text-field>
          </v-col>
          <v-col
            cols="3"
            sm="3"
            md="3"
          >
            <label class="font-weight-bold">Account manager</label>
            <v-btn
              class="ml-2"
              icon
              small
              @click="addUsers()"
            >
              <v-icon color="tvf_color">
                mdi-plus
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="pl-10 pb-6">
          <v-col
            cols="2"
            sm="2"
            md="2"
          >
            <label class="font-weight-bold">From Name</label><br />
            <v-text-field
              class="text caption"
              v-model="from_name"
              background-color="#F8F9FB"
              dense
              solo
              flat
            >
            </v-text-field>
          </v-col>
          <v-col
            cols="3"
            sm="3"
            md="3"
          >
            <label class="font-weight-bold">From Email</label><br />
            <v-text-field
              class="text caption"
              v-model="from_email"
              background-color="#F8F9FB"
              dense
              solo
              flat
            >
            </v-text-field>
          </v-col>
          <v-col
            cols="2"
            sm="2"
            md="2"
          >
            <label class="font-weight-bold">Reply Name</label><br />
            <v-text-field
              class="text caption"
              v-model="reply_name"
              background-color="#F8F9FB"
              dense
              solo
              flat
            >
            </v-text-field>
          </v-col>
          <v-col
            cols="3"
            sm="3"
            md="3"
          >
            <label class="font-weight-bold">Reply Email</label><br />
            <v-text-field
              class="text caption"
              v-model="reply_email"
              background-color="#F8F9FB"
              dense
              solo
              flat
            >
            </v-text-field>
          </v-col>                      
        </v-row>
        <v-divider
          v-if="fields.length > 0"
          class="ml-8"
          width="90%"
        ></v-divider>
        <v-row class="py-4 pl-8 pr-12">
          <v-col
            v-for="field, index in fields"
            :key="index"
            cols="4"
            sm="4"
            md="4"
          >
            <label class="font-weight-bold">{{ field.display_name }}</label><br />
            <v-text-field
              class="text caption"
              v-model="field_value[index]"
              required
              background-color="#F8F9FB"
              dense
              solo
              flat
            ></v-text-field>
          </v-col>
        </v-row>
        <v-divider
          v-if="fields.length > 0"
          class="ml-8"
          width="90%"
        ></v-divider>
        <v-row>
          <div class="d-flex py-4">
            <v-col
              cols="10"
              sm="8"
              md="10"
            >
              <v-row class="pl-8">
                <v-col
                  cols="5"
                  sm="5"
                  md="5"
                >
                  <span class="font-weight-bold pl-1">Domains</span>
                </v-col>
                <v-col
                  cols="3"
                  sm="3"
                  md="3"
                >
                  <span class="font-weight-bold">Expire at</span>
                </v-col>
                <v-col
                  cols="2"
                  sm="2"
                  md="2"
                >
                  <span class="font-weight-bold">Priority</span>
                </v-col>
                <v-col
                  cols="2"
                  sm="2"
                  md="2"
                >
                  <v-btn
                    icon
                    small
                    @click="addDomains()"
                  >
                    <v-icon color="tvf_color">
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <div
                v-for="(input, index) in domains"
                :key="`fieldInput-${index}`"
              >
                <v-row class="pl-8">
                  <v-col
                    cols="5"
                    sm="5"
                    md="5"
                  >
                    <v-autocomplete
                      class="text pl-1 pr-2 caption"
                      v-model="input.domain"
                      :items="domainsList"
                      item-value="id"
                      item-text="domain"
                      background-color="#F8F9FB"
                      dense
                      solo
                      flat
                      @change="ifChange(input, index)"
                    ></v-autocomplete>
                  </v-col>
                  <v-col
                    class="d-flex"
                    cols="3"
                    sm="3"
                    md="3"
                  >
                    <span class="pt-3">{{ input.expire_at }}</span>
                  </v-col>
                  <v-col
                    class="d-flex"
                    cols="2"
                    sm="2"
                    md="2"
                  >
                    <v-select
                      class="caption"
                      v-model="input.priority"
                      :items="['0','1','2','3','4','5']"
                      background-color="#F8F9FB"
                      dense
                      solo
                      flat
                    ></v-select>
                  </v-col>
                  <v-col
                    class="d-flex"
                    cols="1"
                    sm="1"
                    md="1"
                  >
                    <v-btn
                      type="submit"
                      icon
                      small
                      class="addButton mt-1"
                      @click="removeDomains(index, domains)"
                    >
                      <v-icon color="tvf_color">
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-col>
            <v-divider
              width="90%"
              vertical
            ></v-divider>
            <v-col
              class="pl-8"
              cols="6"
              sm="6"
              md="6"
            >
              <v-row>
                <v-col
                  cols="5"
                  sm="5"
                  md="5"
                >
                  <span class="font-weight-bold">Owners</span>
                  <v-btn
                    class="ml-2"
                    icon
                    small
                    @click="showOwners()"
                  >
                    <v-icon>
                      mdi-eye
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col
                  cols="1"
                  sm="1"
                  md="1"
                >
                  <v-btn
                    icon
                    small
                    @click="addOwners()"
                  >
                    <v-icon color="tvf_color">
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <div
                v-for="(input, index) in owners"
                :key="`fieldInput-${index}`"
              >
                <v-row>
                  <v-col
                    cols="6"
                    sm="6"
                    md="6"
                  >
                    <v-autocomplete
                      class="text caption"
                      v-model="input.id"
                      :items="ownersList"
                      item-value="id"
                      item-text="name"
                      background-color="#F8F9FB"
                      dense
                      solo
                      flat
                    ></v-autocomplete>
                  </v-col>
                  <v-col
                    class="d-flex"
                    cols="1"
                    sm="1"
                    md="1"
                  >
                    <v-btn
                      type="submit"
                      icon
                      small
                      class="addButton mt-1"
                      @click="removeOwners(index, domains)"
                    >
                      <v-icon color="tvf_color">
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </div>
        </v-row>
        <v-divider
          class="ml-8 my-3"
          width="90%"
        ></v-divider>
        <div class="d-flex pb-6">
          <v-col
            cols="6"
            sm="6"
            md="6"
          >
            <v-row class="pl-7 pr-9">
              <v-col
                cols="9"
                sm="9"
                md="9"
              >
                <span class="font-weight-bold">Header</span>
                <v-btn
                  class="ml-2"
                  icon
                  small
                  @click="addHeader()"
                >
                  <v-icon color="tvf_color">
                    mdi-plus
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <div
              v-for="(item, index) in head_foot"
              :key="item.id"
            >
              <v-row
                v-if="item.type === 'header'"
                class="pl-6 pr-9"
              >
                <v-col
                  class="d-flex justify-space-between"
                  cols="9"
                  sm="9"
                  md="9"
                >
                  <span class="text-capitalize">{{ item.name }}</span>
                </v-col>
                <v-col
                  v-if="item.type === 'header'"
                  class="pl-6 d-flex"
                  cols="1"
                  sm="1"
                  md="1"
                >
                  <v-btn
                    icon
                    small
                  >
                    <v-icon @click="editHeadFoot(index, item)">
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    small
                  >
                    <v-icon @click="preview(item)">
                      mdi-eye
                    </v-icon>
                  </v-btn>
                  <v-btn
                    type="submit"
                    icon
                    small
                    class="addButton"
                    @click="removeHeadFoot(index)"
                  >
                    <v-icon color="tvf_color">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-col>
          <v-col
            cols="6"
            sm="6"
            md="6"
          >
            <v-row class="pl-6 pr-9">
              <v-col
                cols="9"
                sm="9"
                md="9"
              >
                <span class="font-weight-bold">Footer</span>
                <v-btn
                  class="ml-2"
                  icon
                  small
                  @click="addFooter()"
                >
                  <v-icon color="tvf_color">
                    mdi-plus
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <div
              v-for="(item, index) in head_foot"
              :key="item.id"
            >
              <v-row
                v-if="item.type === 'footer'"
                class="pl-6 pr-9"
              >
                <v-col
                  class="d-flex justify-space-between"
                  cols="9"
                  sm="9"
                  md="9"
                >
                  <span class="text-capitalize">{{ item.name }}</span>
                </v-col>
                <v-col
                  v-if="item.type === 'footer'"
                  class="pl-6 d-flex"
                  cols="1"
                  sm="1"
                  md="1"
                >
                  <v-btn
                    icon
                    small
                  >
                    <v-icon @click="editHeadFoot(index, item)">
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    small
                  >
                    <v-icon @click="preview(item)">
                      mdi-eye
                    </v-icon>
                  </v-btn>
                  <v-btn
                    type="submit"
                    icon
                    small
                    class="addButton"
                    @click="removeHeadFoot(index)"
                  >
                    <v-icon color="tvf_color">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </div>
      </v-form>
    </v-card>
    <v-dialog
      v-model="addUser"
      :value="true"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle bold pa-4">Assign Users</span>
          <v-spacer></v-spacer>
          <v-icon
            class="mr-2"
            @click="addUser = false"
          >
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider
          class="ml-8"
          width="90%"
        ></v-divider>
        <v-card-text>
          <br>
          <v-form>
            <v-text-field
              v-model="search"
              class="search-input mx-5 caption"
              label="Search user"
              prepend-inner-icon="mdi-magnify"
              clearable
              background-color="#F8F9FB"
              dense
              solo
              flat
            ></v-text-field>
          </v-form>
          <v-container>
            <v-col>
              <v-row class="px-2">
                <v-col cols="12">
                  <v-data-table
                    v-model="usersTable"
                    v-if="!loading"
                    :headers="headers"
                    :items="users"
                    :item-key="item.id"
                    :search="search"
                  >
                    <LoadingSpinner v-if="!loading" />
                    <template v-slot:[`item.viewer`]="{ item }">
                      <v-sheet id="switch">
                        <v-switch
                          v-model="item.use"
                          class="pl-6"
                          color="tvf_color"
                          value="viewer"
                          :disabled='item.use  === "manager"'
                        ></v-switch>
                      </v-sheet>
                    </template>
                    <template v-slot:[`item.manager`]="{ item }">
                      <v-sheet id="switch">
                        <v-switch
                          v-model="item.use"
                          class="pl-6"
                          color="tvf_color"
                          value="manager"
                          :disabled='item.use === "viewer"'
                        ></v-switch>
                      </v-sheet>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-col>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn
            type="submit"
            class="addButton pa-6 mb-6 ml-6 white--text"
            color="#BDBDBD"
            @click="addUser = false"
          >
            <v-icon
              dark
              left
            >
              mdi-arrow-left
            </v-icon>
            Back
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            type="submit"
            class="addButton pa-6 mb-6 mr-6 white--text"
            color="tvf_color"
            @click="saveUser"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="head_footModal"
      :value="true"
      persistent
      @click:outside="head_footModal = false"
      max-width="900px"
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle text-capitalize pa-4">{{ title }}</span>
          <v-spacer></v-spacer>
          <v-icon
            class="mr-2"
            @click="head_footModal = false"
          >
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider
          class="ml-8"
          width="93%"
        ></v-divider>
        <v-card-text>
          <v-container>
            <v-row class="pl-4 pt-6">
              <v-col
                cols="4"
                sm="4"
                md="4"
              >
                <label class="caption font-weight-bold">Name</label>
                <v-text-field
                  class="caption"
                  v-model="hf_modal.name"
                  background-color="#F8F9FB"
                  dense
                  solo
                  flat
                >
                </v-text-field>
              </v-col>
            </v-row>
            <!-- Or manually control the data synchronization -->
            <label class="caption font-weight-bold pl-4">Code</label>
            <v-textarea
              class="captionpx-4"
              v-model="hf_modal.code"
              background-color="#F8F9FB"
              dense
              solo
              flat
              color="deep-purple"
              rows="4"
            ></v-textarea>
            <label class="caption font-weight-bold pl-4">
              Preview
            </label>
            <div
              v-preview="hf_modal.code"
              class="caption preview pt-4 text-center"
            ></div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            type="submit"
            class="addButton pa-6 mb-8 mr-8 white--text"
            color="tvf_color"
            @click="saveCode"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <open-preview
      v-if="showPreview"
      @close="showPreview = false"
      :showItem="showItem"
    ></open-preview>
  </v-row>
</template>

<script>
import ApiService from "@/services/api.service";
import AssignUsers from "@/components/database/AssignUsers";
import LoadingSpinner from "@/components/core/LoadingSpinner";
import OpenPreview from "@/components/database/Preview";

export default {
  components: {
    AssignUsers,
    LoadingSpinner,
    OpenPreview
  },

  data() {
    return {
      name: "",
      platform: "",
      country: "",
      ddbb_name: "",
      owner_name: "",
      databaseID: "",
      from_email: "",
      from_name: "",
      reply_email: "",
      reply_name: "",
      company_id: 1,
      indice: -1,
      addUser: false,
      finish_date: false,
      loading: false,
      showPreview: false,
      user: [],
      users: [],
      usersTable: [],
      fields: [],
      field_value: [],
      platforms: [],
      countries: [],
      domainsList: [],
      ownersList: [],
      newHeaders: [],
      newFooters: [],
      item: {},
      domains: [
        {
          domain: "",
          priority: "",
        },
      ],
      domains_final: [],
      owners: [
        {
          id: "",
        },
      ],
      head_foot: [],
      hf_modal: {
        database_id: "",
        type: "",
        name: "",
        code: "",
      },
      title: "",
      search: "",
      loading: false,
      isFormValid: false,
      head_footModal: false,
      viewer: false,
      manager: false,
      headers: [
        {
          text: "NAME",
          align: "start",
          value: "full_name",
          sorteable: false,
        },
        {
          text: "VIEWER",
          align: "center",
          value: "viewer",
        },
        {
          text: "MANAGER",
          value: "manager",
          align: "center",
        },
      ],
    };
  },

  mounted() {
    this.$store.commit("getCountries");
    this.$store.commit("getPlatforms");
    this.getUsers();
    this.getDomains();
    this.getOwners();
  },

  methods: {
    addUsers() {
      this.addUser = true;
    },

    addDomains() {
      this.domains.push({});
    },

    addOwners() {
      this.owners.push({});
    },

    removeDomains(index, domains) {
      this.domains.splice(index, 1);
    },

    removeOwners(index, owners) {
      this.owners.splice(index, 1);
    },

    saveUser() {
      this.usersTable = [];
      this.user = this.users.filter((item) => item.use);
      this.user.forEach((value) => {
        this.usersTable.push({ id: value.id, use: value.use });
      });
      this.addUser = false;
    },

    preview(item) {
      this.showItem = {
        id: item.id,
        database_id: item.database_id,
        name: item.name,
        code: item.code,
        type: item.type
      }
      this.showPreview = true;
    },

    addHeader() {
      this.title = "Add header";
      this.hf_modal = {
        database_id: "",
        type: "header",
        name: "",
        code: "",
      },
      this.head_footModal = true;
    },

    addFooter() {
      this.title = "Add footer";
      this.hf_modal = {
        database_id: "",
        type: "footer",
        name: "",
        code: "",
      },
      this.head_footModal = true;
    },

    editHeadFoot(index, item) {
      this.title = 'Edit ' + item.type + ': ' + item.name
      this.indice = index;
      this.hf_modal = {
        type: item.type,
        name: item.name,
        code: item.code,
        database_id: this.databaseID  
      },
      this.addBtn = true
      this.editBtn = false
      this.head_footModal = true
    },

    saveCode() {
        if(this.indice == -1) {
            this.head_foot.push({
                database_id: this.database_id,
                name: this.hf_modal.name,
                type: this.hf_modal.type,
                code: this.hf_modal.code,
            });
        }else {
            this.head_foot[this.indice] = {
                database_id: this.database_id,
                name: this.hf_modal.name,
                type: this.hf_modal.type,
                code: this.hf_modal.code,
            }
        }  
      this.head_footModal = false;
    },

    removeHeadFoot(index){
      this.head_foot.splice(index, 1)
    },

    async saveDatabase() {
      this.field_value.forEach((element, index) => {
        this.fields[index].value = element;
      });

      this.domains.forEach((element) => {
        this.domains_final.push({
          id: element.domain,
          priority: element.priority,
        });
      });      

      await ApiService.post("databases", {
        platform_id: this.platform,
        country_id: this.country,
        name: this.name,
        name_bdd: this.ddbb_name,
        company_id: this.company_id,
        reply_email: this.reply_email,
        reply_name: this.reply_name,
        from_email: this.from_email,
        from_name: this.from_name,
        platform_fields: JSON.stringify(this.fields),
        domains: JSON.stringify(this.domains_final),
        owners: JSON.stringify(this.owners),
        management: JSON.stringify(this.usersTable),
      })
        .then((response) => {          
          this.databaseID = response.data.data.id;  
          this.$store.dispatch("getDatabases");
          this.$emit("close");
          this.$store.dispatch("setSnackbar", {
            color: "success",
            text: "New database has been created!",
            timeout: 3000,
          });
        })
        .catch((error) => {
          if (typeof error.response.data === "object") {
            Object.values(error.response.data.errors)
              .flat()
              .forEach((error) => {
                this.$store.dispatch("setSnackbar", {
                  color: "tvf_secondary",
                  text: error,
                  timeout: 3000,
                });
              });
          }
        });
      
        this.head_foot.forEach(element => {

            ApiService.post("hf", {
                database_id: this.databaseID,
                name: element.name,
                code: element.code,
                type: element.type,
            })
            .then((response) => {
              this.$emit("updateList");
              this.$store.dispatch('setSnackbar', {
                  color: 'success',
                  text: 'Header or footer added to "' + this.name + '"',
                  timeout: 3000
              })
            })
            .catch((error) => {
                if (typeof error.response.data === 'object') {
                    Object.values(error.response.data.errors).flat().forEach( error => {
                    this.$store.dispatch('setSnackbar', {
                        color: 'tvf_secondary',
                        text: error,
                        timeout: 3000
                    })       
                })}
            });
        });
    },

    ifChange(item, index) {
      this.domains[index].domain = item.domain;
      this.domains[index].expire_at = item.expire_at;
      this.domainsList.forEach((element) => {
        if (element.id === item.domain) {
          item.expire_at = element.expire_at;
          item.priority = element.priority;
        }
      });
    },

    onChange(platform) {
      this.$store.state.platforms.forEach((element) => {
        if (platform === element.id) {
          this.fields = JSON.parse(element.fields);
        }
      });
    },

    getDomains() {
      ApiService.get("domains/list")
        .then((response) => {
          const domains = response.data.data;
          this.domainsList = domains.sort(function (a, b) {
            if (a.domain.toLowerCase() > b.domain.toLowerCase()) {
              return 1;
            }
            if (a.domain.toLowerCase() < b.domain.toLowerCase()) {
              return -1;
            }
            // a must be equal to b
            return 0;
          });
        })
        .catch((error) => {
        if (typeof error.response.data === 'object') {
          Object.values(error.response.data.errors).flat().forEach( error => {
          this.$store.dispatch('setSnackbar', {
              color: 'tvf_secondary',
              text: error,
              timeout: 3000
          })       
        })}
      });
    },

    getOwners() {
      ApiService.get("owners/list")
        .then((response) => {
          const owners = response.data.data;
          this.ownersList = owners.sort(function (a, b) {
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }
            // a must be equal to b
            return 0;
          });
        })
        .catch((error) => {
        if (typeof error.response.data === 'object') {
          Object.values(error.response.data.errors).flat().forEach( error => {
          this.$store.dispatch('setSnackbar', {
              color: 'tvf_secondary',
              text: error,
              timeout: 3000
          })       
        })}
      });
    },

    getUsers() {
      this.loading = true;

      ApiService.get("users/list")
        .then((response) => {
          this.loading = false;
          const users = response.data.data;
          this.users = users.sort(function (a, b) {
            if (a.first_name.toLowerCase() > b.first_name.toLowerCase()) {
              return 1;
            }
            if (a.first_name.toLowerCase() < b.first_name.toLowerCase()) {
              return -1;
            }
            // a must be equal to b
            return 0;
          });
        })
        .catch((error) => {
        this.loading = false
        if (typeof error.response.data === 'object') {
          Object.values(error.response.data.errors).flat().forEach( error => {
          this.$store.dispatch('setSnackbar', {
              color: 'tvf_secondary',
              text: error,
              timeout: 3000
          })       
        })}
      });
    },
  },
};
</script>

<style>
span,
label,
.text {
  font-size: 0.8rem !important;
  font-weight: 400;
}

.form-modal {
  width: 100%;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}

.row + .row {
  margin-top: 0px;
}

.theme--light.v-sheet #switch {
  background-color: rgba(255, 255, 255, 0) !important;
}
</style>