<template>
  <div>
    <v-row justify="center">
      <v-dialog
        :value="true"
        persistent
        @click:outside="$emit('close')"
        max-width="400px"
      >
        <v-card>
          <v-card-title>
            <span class="text-subtitle bold pa-4">New Permission</span>
            <v-spacer></v-spacer>
            <v-icon
              class="mr-2"
              @click="$emit('close')"
            >
              mdi-close
            </v-icon>
          </v-card-title>
          <v-divider
            class="ml-8"
            width="90%"
          ></v-divider>
          <v-form @submit.prevent="addPermission">
            <v-card-text>
              <v-container>
                <v-col>
                  <v-row class="px-8 pt-2">
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <label>Name</label>
                      <v-text-field
                        v-model="name"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                type="submit"
                class="addButton pa-6 mb-6 mr-6 white--text"
                color="tvf_color"
                :disabled='!isComplete'
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import ApiService from "@/services/api.service";

export default {
  data() {
    return {
      name: '',
      errors: [],
      nameRules: [
        (v) => !!v || "Role name is required",
      ],
    };
  },

  computed: {
    isComplete() {
      return (
        this.name
      );
    },
  },

  methods: {

    async addPermission() {
      this.errors = [];

      await ApiService.post("permission/create", {
        name: this.name,
      }).then((response) => {
        console.log("Submit Success");
        this.$emit('close');
        this.$emit('updateList');
        this.$store.dispatch('setSnackbar', {
            text: 'New permission has been created!'
        })
      }).catch((error) => {
          if (typeof error.response.data === 'object') {
            this.$store.dispatch('setSnackbar', {
            color: 'tvf_secondary',
            text: 'This permission is alredy exist'//error.response.data.message
            })
          }
        });
    },
  }
};
</script>

<style scoped>
.col-sm-4,
.col-md-4,
.col-12,
.col-sm-8,
.col-md-8 {
  padding: 0px;
}


</style>