<template>
    <v-dialog
      :value="true"
      persistent
      @click:outside="$emit('close')"
      max-width="550px"
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle bold pa-4">Edit Prefill</span>
          <v-spacer></v-spacer>
          <v-icon
            class="mr-2"
            @click="$emit('close')"
          >
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider
          class="ml-8"
          width="90%"
        ></v-divider>

        <v-form @submit.prevent="addPrefill">
          <v-card-text>
            <v-container>
              <v-col>
                <v-row class="px-2">
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <label>Name</label>
                    <v-text-field
                      v-model="editPrefill.prefill_name"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <label class="ml-2">Prefill</label>
                    <v-text-field
                      v-model="editPrefill.prefill"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              type="submit"
              class="addButton pa-6 mb-6 mr-6 white--text"
              color="tvf_color"
              :disabled='!isComplete'
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
</template>

<script>
import ApiService from "@/services/api.service";

export default ({
    props: ['editPrefill'],
    data () {
        return {
            errors: [],
        };
  },

  computed: {
    isComplete() {
      return (
        this.editPrefill.prefill_name &&
        this.editPrefill.prefill
      );
    },
  },

  methods: {
    async addPrefill() {
      await ApiService.put("tvfprefills/" + this.editPrefill.id, {
        name: this.editPrefill.prefill_name,
        prefill: this.editPrefill.prefill
      })
      .then((response) => {
        this.$emit('close');
        this.$emit('updateList');
        this.$store.dispatch('setSnackbar', {
          color: 'success',
          text: 'Prefill has been updated succesfully!',
          timeout: 3000
        })
      })
      .catch((error) => {
        if (typeof error.response.data === 'object') {
          Object.values(error.response.data.errors).flat().forEach( error => {
            this.$store.dispatch('setSnackbar', {
              color: 'tvf_secondary',
              text: error,
              timeout: 3000
            })       
          })
        }
      });
    }
  }
})
</script>
