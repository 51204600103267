<template>
    <v-dialog
      :value="true"
      persistent
      @click:outside="$emit('close')"
      max-width="550px"
    >
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-icon
            class="mr-2 mt-2"
            @click="$emit('close')"
            small
          >
            mdi-close
          </v-icon>
        </v-card-title>
        <v-form @submit.prevent="addSeedList">
          <v-card-text>
            <v-container>
              <v-col>
                <v-row class="px-2">
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <label class="caption font-weight-bold">List Name</label>
                    <v-text-field
                        v-model="list_name"
                        class="caption"
                        background-color="#F8F9FB"
                        dense
                        solo
                        flat
                    ></v-text-field>
                  </v-col>    
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <label class="caption font-weight-bold">Country</label>
                    <v-select
                        class="caption"
                        v-model="country"
                        :items="countries"
                        item-value="id"
                        item-text="name"
                        background-color="#F8F9FB"
                        dense
                        solo
                        flat
                    >
                    </v-select>
                  </v-col>    
                  <v-col
                    cols="12"
                    sm="10"
                    md="10"
                  >
                    <label class="caption font-weight-bold">Emails</label>
                    <v-text-field
                        class="caption"
                        v-model="emails"
                        background-color="#F8F9FB"
                        placeholder="mail@example.es, mail2@example.es"
                        dense
                        solo
                        flat
                    ></v-text-field>                                        
                  </v-col>   
                 <!--  <v-col
                    cols="12"
                    sm="2"
                    md="2"
                    >
                    <v-btn
                        class="addMore my-6 white--text rounded mr-2"
                        @click="addPays()"
                        elevation="0"
                    >
                        <v-icon>
                        mdi-plus
                        </v-icon>
                        add payout
                    </v-btn>
                    <v-btn
                        v-if="paysLocal.length > 1"
                        class="addMore my-6 white--text rounded"
                        @click="removePays(index)"
                        elevation="0"
                    >
                        <v-icon>
                        mdi-minus
                        </v-icon>
                    </v-btn> 
                  </v-col>      -->
                </v-row>
              </v-col>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              type="submit"
              class="addButton px-6 mb-6 mr-6 white--text"
              color="tvf_color"
              :disabled='!isComplete'
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>    
    </v-dialog>
</template>

<script>
import ApiService from "@/services/api.service";
import { mapState } from 'vuex';

export default ({
    data () {
        return {
            list_name: '',
            country: '',
            emails: '',
    }
  },

  mounted(){
    this.$store.commit('getCountries')
  },

  computed: {
    isComplete() {
      return (
        this.list_name &&
        this.country &&
        this.emails
      );
    },

    ...mapState([
        'countries'
    ]),
  },

  methods: {
    async addSeedList() {
        const emailArray = this.emails.split(",");
        const trimmedEmailArray = emailArray.map(email => email.trim());

        this.emailArray = trimmedEmailArray;

        var emails_filtered = this.emailArray.filter(function (el) {
        return el != '';
        });

        await ApiService.post("seeds", {
            name: this.list_name,
            country_id: this.country,
            emails: JSON.stringify(emails_filtered)
        })
        .then((response) => {
            this.$emit('close');
            this.$store.dispatch('getSeeds')
            this.$store.dispatch('setSnackbar', {
            color: 'success',
            text: 'New seed list has been created!',
            timeout: 3000
            })
        })
        .catch((error) => {
            if (typeof error.response.data === 'object') {
            Object.values(error.response.data.errors).flat().forEach( error => {
                this.$store.dispatch('setSnackbar', {
                color: 'tvf_secondary',
                text: error,
                timeout: 3000
                })       
            })
            }
        });
        }   
    }
})
</script>
